import React from "react";
import JiraTooltipHeader from "./JiraTooltipHeader";
import { Divider, Typography } from "antd";
const { Text } = Typography;
const JiraTooltipView = ({ item }) => {
  return (
    <>
      <JiraTooltipHeader item={item} />
      <Divider />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Text type="secondary">Title</Text>
        <Text strong>{item.summary}</Text>
      </div>
      {/* <JiraTooltipBody commentsData={commentsData} /> */}
    </>
  );
};

export default JiraTooltipView;
