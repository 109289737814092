// checkBoxSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isETASelected: true,
  isCurrentSelected: true,
  isUpcomingSelected: true,
  isAssignedSelected: true,
  isCompletedSelected: false,
  isOpenSelected: true,
  currentSelectionView: "weekly",
  currentSelectionViewBy: "component"
};

const checkBoxSlice = createSlice({
  name: "checkBox",
  initialState,
  reducers: {
    toggleETASelection: (state, action) => {
      state.isETASelected = action.payload;
    },
    toggleCurrentSelection: (state, action) => {
      state.isCurrentSelected = action.payload;
    },
    toggleUpcomingSelection: (state, action) => {
      state.isUpcomingSelected = action.payload;
    },
    toggleAssignedSelection: (state, action) => {
      state.isAssignedSelected = action.payload;
    },
    toggleCompletedSelection: (state, action) => {
      state.isCompletedSelected = action.payload;
    },
    toggleOpenSelection: (state, action) => {
      state.isOpenSelected = action.payload;
    },
    setCurrentSelectionView: (state, action) => {
      state.currentSelectionView = action.payload;
    },
    setCurrentSelectionViewBy: (state, action) => {
      state.currentSelectionViewBy = action.payload;
    },
  },
});

export const {
  toggleETASelection,
  toggleCurrentSelection,
  toggleUpcomingSelection,
  toggleAssignedSelection,
  toggleCompletedSelection,
  toggleOpenSelection,
  setCurrentSelectionView,
  setCurrentSelectionViewBy
} = checkBoxSlice.actions;

export default checkBoxSlice.reducer;
