import React, { useState, useContext, useEffect } from "react";
import { useDrop } from "react-dnd";

import "./styles.css";

import { ETLCanvasContext, ETLCanvasContextDispatcher } from "../../config/contexts";
import FormModal from "./FormModal";
import { Button, Modal, FormItemTextArea, NotificationTrigger } from "@nutanix-ui/prism-reactjs";

export default function SchemaSelection() {
  return (
    <div className="schemaSelectionContainer">
      <RenderSourceSchema />
      <RenderTransformQuery />
      <RenderTargetSchema />
    </div>
  );
}

function RenderSourceSchema(props) {

  const [modalVisible, setModalVisible] = useState(false);
  const [basket, setBasket] = useState([]);// eslint-disable-next-line 
  const [{ isOver }, dropRef] = useDrop({
    accept: "schemaBox",
    drop: (item) => {
      if(['S','ST']?.includes(item?.dropArea)){
        setBasket((basket) => {
          if (!basket?.includes(item)) {
            item.key = Math.floor(Math.random() * 101);
            item.id = Date.now().toString(36) + Math.random().toString(12);
            return [item];
          } else {
            return basket;
          }
        });
      }else{
        NotificationTrigger.add({
          id: "source drop info",
          className: "successAlert",
          message: "Operation not allowed",
          type: "warning",
          autoDismissDelaySecs: 25,
        });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleOnClose = () => {
    setBasket([]);
    setModalVisible(false);
  };

  useEffect(() => {
    if (basket.length > 0) {
      setModalVisible(true);
    }
  }, [basket]);

  // const [schemaTitle, setSchemaTitle] = useState({
  //   key: "0",
  //   label: `Drop Source here`,
  //   value: "default",
  //   imgID: "null",
  // });

  return (
    <div
      className="selectSchemaContainer"
      data-testid="selectSchemaContainer"
      ref={dropRef}
    >
      <div
        className="selectSchemaContentContainer"
        data-testid="selectSchemaContentContainer"
      >
        <div className="selectSchemaContent" data-testid="selectSchemaContent">
          <div
            className="pipeLineSchemaSource"
            data-testid="connectorSourceDropBox"
          >
            <p>Drop Source here</p>
          </div>
        </div>
      </div>
      <FormModal
        origin="Source"
        visible={modalVisible}
        handleOnClose={handleOnClose}
        schema={basket[0]}
      />
    </div>
  );
}

function RenderTargetSchema(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [basket, setBasket] = useState([]);// eslint-disable-next-line 
  const [{ isOver }, dropRef] = useDrop({
    accept: "schemaBox",
    drop: (item) =>{
      if (["T", "ST"]?.includes(item?.dropArea)) {
        setBasket((basket) => {
          if (!basket.includes(item)) {
            item.id = Date.now().toString(36) + Math.random().toString(12);
            return [item];
          } else {
            return basket;
          }
        });
      } else {
        NotificationTrigger.add({
          id: "source drop info",
          className: "successAlert",
          message: "Operation not allowed",
          type: "warning",
          autoDismissDelaySecs: 25,
        });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleOnClose = () => {
    setBasket([]);
    setModalVisible(false);
  };

  useEffect(() => {
    if (basket.length > 0) {
      setModalVisible(true);
    }
  }, [basket]);

  // const [schemaTitle, setSchemaTitle] = useState({
  //   key: "0",
  //   label: `Drop Target here`,
  //   value: "default",
  //   imgID: "null",
  // });

  return (
    <div
      className="selectSchemaContainer"
      data-testid="selectSchemaContainer"
      ref={dropRef}
    >
      <div
        className="selectSchemaContentContainer"
        data-testid="selectSchemaContentContainer"
      >
        <div className="selectSchemaContent" data-testid="selectSchemaContent">
          <div
            className="pipeLineSchemaSource"
            data-testid="connectorTargetDropBox"
          >
            <p>Drop Target here</p>
          </div>
        </div>
      </div>
      <FormModal
        origin="Target"
        visible={modalVisible}
        handleOnClose={handleOnClose}
        schema={basket[0]}
      />
    </div>
  );
}

function RenderTransformQuery() {
  const [isVisible, setIsVisible] = useState(false);
  const [query, setQuery] = useState("");

  const pipelineData = useContext(ETLCanvasContext);
  const setPipelineData = useContext(ETLCanvasContextDispatcher);

  const handleQueryModal = () => {
    setIsVisible((prevState) => !prevState);
  };
useEffect(()=>{
  if(pipelineData.transfromQuery?.globalQuery?.queryString?.query){

    setQuery( atob(pipelineData.transfromQuery?.globalQuery?.queryString?.query) )
  } 
},[pipelineData?.transfromQuery])
  const addQuery = () => {
    pipelineData.transfromQuery = {
      pipelineID: pipelineData?.pipelineID,
      globalQuery: {
        queryString: {
          query: btoa(query),
        },
      } 
    };
    setPipelineData({ ...pipelineData });
    handleQueryModal();
  };

  return (
    <div className="pipelineSchedularContainer">
      <div className="schedulingOptions" data-testid="schedulingOptions">
        <div style={{ margin: "1rem" }}>
          <Button onClick={handleQueryModal} data-testid="paas-etlCanvas-addTransformQuery-btn">Add Transform Query</Button>
          <Modal
            visible={isVisible}
            title={"Transform Query"}
            onClose={handleQueryModal}
            closeIconProps={{ "data-test": "process-pipeline-data-test" }}
            footer={false}
          >
            <div style={{ margin: "1rem" }}>
              <FormItemTextArea
                data-testid="paas-etlCanvas-transformQuery-box"
                id="query"
                label="Query"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Please Type your query here"
                style={{ height: "10rem" }}
              />
              <Button style={{ marginTop: "1rem" }} onClick={addQuery} data-testid="paas-etlCanvas-transformQuery-save-btn">
                Save
              </Button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}
