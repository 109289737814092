import React from "react";
import VelocityFilter from "../Filters/VelocityFilter";
import Widget from "../WidgetContainer/Widget";
import { useSelector } from "react-redux";
import TeamVelocity from "../Graphs/TeamVelocity";
import AverageTeamVelocity from "../Graphs/AverageTeamVelocity";
import DeveloperVelocity from "../Graphs/DeveloperVelocity";
// import DeveloperFooter from "../WidgetContainer/DeveloperFooter";
import { Loader } from "@nutanix-ui/prism-reactjs";
// import AvgDeveloperFooter from "../WidgetContainer/AvgDeveloperFooter";

const Velocity = ({ margin }) => {
  const {  loading, filters } = useSelector((state) => state.enggDashboard);
  return (
    <div className="tabContainer">
      <div className="filterContainer">
        <VelocityFilter />
      </div>
      <div className="widgetContainer">
        <div className="widgetItems full">
          <Loader
            loading={loading.teamVelocity}
            tip="Loading..."
            data-test-id="wrap-around-container"
          >
            <Widget
              title="Team Velocity"
              id="stages"
              // width="50%"
              body={<TeamVelocity margin={margin} />}
            />
          </Loader>
        </div>
      </div>
      <div className="widgetContainer">
        <div className="widgetItems full">
          <Loader
            loading={loading.avgDeveloperVelocity}
            tip="Loading..."
            data-test-id="wrap-around-container"
          >
            <Widget
              title={<p>Average Velocity Per Developer  <span className="titleSubText">{`Threshold (${filters.viewByVelocity === "Quarterly" ? 60 : 20}SP)`}</span></p>}
              id="avgDeveloper"
              // width="50%"
              body={<AverageTeamVelocity />}
              // footer={<AvgDeveloperFooter />}
            />
          </Loader>
        </div>
      </div>
      <div className="widgetContainer">
      <div className="widgetItems full">
        <Loader
          loading={loading.developerVelocity}
          tip="Loading..."
          data-test-id="wrap-around-container"
        >
          <Widget
            title={<p>Developer Velocity  <span className="titleSubText">{`Threshold (${filters.viewByVelocity === "Quarterly" ? 60 : 20}SP)`}</span></p>}
            id="developerVelocity"
            // width="100%"
            body={<DeveloperVelocity />}
            // footer={<DeveloperFooter />}
          />
        </Loader>
      </div>
      </div>
    </div>
  );
};

export default Velocity;
