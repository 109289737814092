import { Table } from "antd";
import React from "react";
import {
  generateColumnsForWeeklyView,
  generateDataSourceForWeeklyView,
  generateWeeksArray,
} from "../../../utils";
import { useSelector } from "react-redux";

const WeeklyView = ({
  uniqueComponent,
  uniqueAssignee,
  uniqueBusinessName,
  sprintDatesInArray,
  generatedComponentData,
  generatedAssigneeData,
  generatedBusinessData,
  title,
  footer,
}) => {
  const { currentTeam } = useSelector((state) => state.team);
  const { currentSelectionViewBy } = useSelector((state) => state.checkbox);
  const weeksInArray = generateWeeksArray(sprintDatesInArray);
  const columns = generateColumnsForWeeklyView(weeksInArray, currentSelectionViewBy, currentTeam?.project_id);
  const dataSource = generateDataSourceForWeeklyView(
    uniqueComponent,
    columns,
    generatedComponentData
  );
  const dataSourceAssignee = generateDataSourceForWeeklyView(
    uniqueAssignee,
    columns,
    generatedAssigneeData
  );
  const dataSourceBusinessName = generateDataSourceForWeeklyView(
    uniqueBusinessName,
    columns,
    generatedBusinessData
  );

  return (
    <Table
      className="jira-ssot-table monthly-table"
      columns={columns}
      dataSource={
        currentTeam.project_id === 12530 ? 
        currentSelectionViewBy === 'component' ? dataSource : 
        currentSelectionViewBy === 'assignee name' ? dataSourceAssignee : 
        dataSourceBusinessName : dataSourceAssignee
      }
      // dataSource={dataSourceAssignee}
      size="small"
      title={title}
      footer={footer}
      pagination={false}
      scroll={{ y: 320 }}
    />
  );
};

export default WeeklyView;
