import React from "react";
import "./MonthlyDetails.css";
import JiraView from "./JiraView";
import { formatDate } from "../Helper/Helper";
import moment from "moment";

const MonthlyDetails = ({ date, filteredEta }) => {
  const parsedDate = moment(date, 'DD MMM, ddd');
  const formattedDate = parsedDate.format('DD MMM');
  let etaFormattedDate = formatDate(date);

  const currentFilterItem = filteredEta.filter(
    (eta) => eta.dt_eta_due_latest === etaFormattedDate
  );
  return (
    <div className={`monthly-card-container`}>
      <div className="monthly-card-header flex">
        <span>
          {currentFilterItem.length === 0 ? "No ETA" : "Total"}
          {currentFilterItem.length ? `: ${currentFilterItem.length}` : ""}
        </span>
        <span className="monthly-date">{formattedDate}</span>
      </div>
      <div className="monthly-card-body">
        {currentFilterItem.map((item) => (
          <JiraView item={item} date={date} />
        ))}
      </div>
    </div>
  );
};

export default MonthlyDetails;
