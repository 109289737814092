import React, { useMemo, useState } from "react";
import "./DashboardCalendar.css";
import { useSelector } from "react-redux";
import {
  createArrayOfObjectsFromUniqueKeys,
  filterDataByCheckboxes,
  filterWeekdays,
  generateDateArray,
  generateWeeksArray,
  getUniqueValuesByArray,
  getUniqueValuesByKey,
} from "../utils";
import WeeklyView from "./CalendarView/WeeklyView/WeeklyView";
import CalendarHeader from "./CalendarHeader/CalendarHeader";
import CalendarFooter from "./CalendarFooter/CalendarFooter";
import DayView from "./CalendarView/DayView/DayView";
import moment from "moment";

const DashboardCalendar = ({ filteredEta }) => {
  // Section 1: State
  const [currentPage, setCurrentPage] = useState(1);

  // Section 2: Redux Store Access
  const { sprintStartDate, sprintEndDate } = useSelector((state) => state.filter);
  const { isCompletedSelected, isOpenSelected } = useSelector(
    (state) => state.checkbox
  );

  // Section 3: Memoized Data and Calculations
  const { currentSelectionView } = useSelector((state) => state.checkbox);
  const sprintDatesInArray = generateDateArray(
    moment(sprintStartDate).format("YYYY-MM-DD"),
    moment(sprintEndDate).format("YYYY-MM-DD")
  );
  // const completedDates = completeDatesToCount(sprintDatesInArray, 31);
  const weeksInArray = generateWeeksArray(sprintDatesInArray);

  const filteredWeeksArray = useMemo(
    () =>
      weeksInArray.map((week) => ({
        ...week,
        dates: filterWeekdays(week.dates),
      })),
    [weeksInArray]
  );

  const currentWeekDates = filteredWeeksArray.find(
    (item, index) => index + 1 === currentPage
  );

  const uniqueComponent = useMemo(
    () =>
      getUniqueValuesByArray(filteredEta, {
        name: "component",
        nullLabel: "Components",
      }),
    [filteredEta]
  );
  const uniqueAssignee = getUniqueValuesByKey(filteredEta, {
    name: "assignee_name",
    nullLabel: "Assignee",
  });

  const uniqueBusinessName = getUniqueValuesByKey(filteredEta, {
    name: "businessname",
    nullLabel: "Business Name",
  });

  const generatedComponentData = useMemo(
    () =>
      createArrayOfObjectsFromUniqueKeys(
        filterDataByCheckboxes(
          filteredEta,
          isCompletedSelected,
          isOpenSelected
        ),
        uniqueComponent, 'component'
      ),
    [filteredEta, isCompletedSelected, isOpenSelected, uniqueComponent]
  );
  const generatedAssigneeData = useMemo(
    () =>
      createArrayOfObjectsFromUniqueKeys(
        filterDataByCheckboxes(
          filteredEta,
          isCompletedSelected,
          isOpenSelected
        ),
        uniqueAssignee, 'assignee_name'
      ),
    [filteredEta, isCompletedSelected, isOpenSelected, uniqueAssignee]
  );

  const generatedBusinessData = useMemo(
    () =>
    createArrayOfObjectsFromUniqueKeys(
      filterDataByCheckboxes(
        filteredEta,
        isCompletedSelected,
        isOpenSelected
      ),
      uniqueBusinessName, 'businessname'
    ),
    [filteredEta, isCompletedSelected, isOpenSelected, uniqueBusinessName]
  );

  // Section 5: Render
  return (
    <>
      {currentSelectionView === "days" ? (
        <DayView
          currentWeekDates={currentWeekDates}
          uniqueComponent={uniqueComponent}
          uniqueAssignee={uniqueAssignee}
          uniqueBusinessName={uniqueBusinessName}
          generatedComponentData={generatedComponentData}
          generatedAssigneeData={generatedAssigneeData}
          generatedBusinessData={generatedBusinessData}
          title={() => (
            <CalendarHeader
              currentView={currentSelectionView}
              total={sprintDatesInArray.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
          footer={() => <CalendarFooter />}
        />
      ) : (
        <WeeklyView
          sprintDatesInArray={sprintDatesInArray}
          uniqueComponent={uniqueComponent}
          uniqueAssignee={uniqueAssignee}
          uniqueBusinessName={uniqueBusinessName}
          generatedComponentData={generatedComponentData}
          generatedAssigneeData={generatedAssigneeData}
          generatedBusinessData={generatedBusinessData}
          title={() => (
            <CalendarHeader
              currentView={currentSelectionView}
              total={sprintDatesInArray.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
          footer={() => <CalendarFooter />}
        />
      )}
    </>
  );
};

export default DashboardCalendar;
