import moment from "moment";
import {
  resetCodeCoverage,
  resetCustomerDefect,
  resetDefaultView,
  resetDefaultViewVelocity,
  setAutomationCodeCoverage,
  setAutomationTestCoverage,
  setAvgDeveloperVelocityData,
  setCFDFilter,
  setCfdData,
  setCodeCoverageFilter,
  setCodeCoverageLastUpdate,
  setCycleDateRange,
  setCycleFilter,
  setDeveloperVelocityData,
  setFilters,
  setJiraComparisonData,
  setJiraDateRange,
  setLoading,
  setOvertimeData,
  setStagesData,
  setTeamVelocityData,
  setVelocityFilter,
  toggleLoadingCfd,
  toggleLoadingCfdFilter,
  toggleLoadingCodeCoverage,
  toggleLoadingCodeCoverageFilter,
  toggleLoadingCycle,
  toggleLoadingFilterCycle,
  toggleLoadingVelocity,
  toggleLoadingVelocityFilter,
  toggleVelocityAssignee,
} from "../../features/JiraAnalysis/enggDashboardSlice";
import { SERVER_URL } from "../../config/config";
import axios from "axios";
import Logger from "../../config/logger";
import { useQuery } from "@tanstack/react-query";
import { Tooltip, Typography, message } from "antd";
import { ThemeManager } from "@nutanix-ui/prism-reactjs";
import { Bar, LabelList } from "@nutanix-ui/recharts";
const MODULE = "kpi_enggineering_client";

const applyFilters = (filtersToSet, dispatch) => {
  filtersToSet.forEach((filter) => {
    dispatch(setFilters(filter));
  });
};

// RESET - VELOCITY STATES
export const resetVelocityFilters = (dispatch) => {
  const filtersToSet = [
    { section: "selectedVelocityProduct", value: ["All"] },
    { section: "selectedVelocityComponent", value: ["All"] },
    { section: "selectedVelocityIssueType", value: ["All"] },
    { section: "selectedVelocityPriority", value: ["All"] },
    { section: "selectedVelocityLabel", value: ["All"] },
    { section: "selectedVelocityAssignee", value: ["All"] },
    { section: "selectedVelocity", value: "Production" },
    { section: "viewByVelocity", value: "Quarterly" },
  ];
  applyFilters(filtersToSet);
  dispatch(toggleLoadingVelocity(true));
  dispatch(resetDefaultViewVelocity());
};
// RESET - CYCLE STATES
export const resetCycleFilters = (dispatch) => {
  const startDate = moment().subtract(3, "month").startOf("day").toDate();
  const endDate = moment().endOf("day").toDate();
  const filtersToSet = [
    { section: "selectedProduct", value: ["All"] },
    { section: "selectedComponent", value: ["All"] },
    { section: "selectedStage", value: ["All"] },
    { section: "selectedIssueType", value: ["All"] },
    { section: "selectedPriority", value: ["All"] },
    { section: "selectedLabel", value: ["All"] },
    { section: "selectedStoryPoint", value: ["All"] },
    { section: "selectedAssignee", value: ["All"] },
    { section: "selectedQAContact", value: ["All"] },
    { section: "selectedJiraIssues", value: ["All"] },
    { section: "selectedTicketProgress", value: "Only Closed" },
    { section: "selectedCycleTime", value: "Average" },
    { section: "viewBy", value: "Quarterly" },
  ];
  applyFilters(filtersToSet, dispatch);
  dispatch(setCycleDateRange({ startDate, endDate }));
  dispatch(setJiraDateRange({ startDate, endDate }));
  dispatch(toggleLoadingCycle(true));
  dispatch(resetDefaultView());
};
// RESET - CUSTOMERS STATES
export const resetCustomerFilters = (dispatch) => {
  const filtersToSet = [
    { section: "selectedCustomerProduct", value: ["All"] },
    { section: "selectedCustomerComponent", value: ["All"] },
    { section: "selectedCustomerIssueType", value: ["All"] },
    { section: "selectedCustomerLabel", value: ["All"] },
    { section: "viewByCfd", value: "Quarterly" },
  ];
  applyFilters(filtersToSet, dispatch);
  dispatch(resetCustomerDefect());
  dispatch(toggleLoadingCfd(true));
};
// RESET - CODE COVERAGE STATES
export const resetCodeCoverageFilters = (dispatch, codeCoverageFilter) => {
  if (codeCoverageFilter?.products && codeCoverageFilter?.products.length > 0) {
    dispatch(
      setFilters({
        section: "selectedCodeCoverageProduct",
        value: codeCoverageFilter.products[0],
      })
    );
  }
  const filtersToSet = [{ section: "viewByCC", value: "Day" }];
  applyFilters(filtersToSet, dispatch);
  dispatch(resetCodeCoverage());
  dispatch(toggleLoadingCodeCoverage(true));
};

const navigateTo = (url) => (url ? (window.location.href = url) : null);
const loginUrl = `${SERVER_URL}/login`;

const handleError = (error) => {
  if (error.response && error.response.data.message === "User not authenticated") {
    // Handle unauthorized access, redirect to login screen
    Logger.error("User not authenticated. Redirecting to login screen.");
    // Perform navigation to the login screen here
    // Example: window.location.href = '/login';
    navigateTo(loginUrl);
    throw new Error(error?.response?.data.message);
  }
  // Handle other errors if needed
  throw error;
};

const getDeveloperNames = (teamMembers) => {
  const developerNames = teamMembers
    .filter((member) => member.role === "Developer")
    .map((member) => member.name);

  return developerNames;
};

const selectDeveloper = (velocityFilter, dispatch) => {
  const developerNames = getDeveloperNames(
    Object.keys(velocityFilter).length > 0 ? velocityFilter.assignees : []
  );
  dispatch(
    setFilters({ section: "selectedVelocityAssignee", value: developerNames })
  );

  dispatch(toggleVelocityAssignee(false));
  return developerNames;
};

// FETCH - Velocity Filter
export const fetchVelocityFilter = async (
  dispatch,
  filters,
  velocityAssigneeChanged,
  loadingVelocity
) => {
  try {
    let assignees = null;

    if (velocityAssigneeChanged) {
      assignees = filters.selectedVelocityAssignee;
    }
    dispatch(
      setLoading({
        teamVelocity: true,
        developerVelocity: true,
        avgDeveloperVelocity: true,
      })
    );
    const payload = {
      product:
        filters.selectedVelocityProduct[0] === "All"
          ? null
          : filters.selectedVelocityProduct,
      issue_type:
        filters.selectedVelocityIssueType[0] === "All"
          ? null
          : filters.selectedVelocityIssueType,
      priority:
        filters.selectedVelocityPriority[0] === "All"
          ? null
          : filters.selectedVelocityPriority,
      labels:
        filters.selectedVelocityLabel[0] === "All"
          ? null
          : filters.selectedVelocityLabel,
      assignee_name:
        filters.selectedVelocityAssignee[0] === "All" 
        ? null 
        : assignees,
      component: filters.selectedVelocityComponent[0] === "All" 
      ? null : filters.selectedVelocityComponent
    };
    const VELOCITYFILTERS = `${SERVER_URL}/velocity-engineering-kpis-filter?payload=${encodeURIComponent(
      JSON.stringify(payload)
    )}`;
    const VelocityFiltersResponse = axios.get(VELOCITYFILTERS);
    const VelocityFilterData = (await VelocityFiltersResponse).data.data;
    if (!velocityAssigneeChanged) {
      const developerNames = selectDeveloper(VelocityFilterData, dispatch);
      if (developerNames.length > 0) {
        fetchVelocityAPI(developerNames, filters, loadingVelocity, dispatch);
      } else {
        fetchVelocityAPI(null);
      }
    } else {
      let assignee =
        filters.selectedVelocityAssignee[0] === "All"
          ? null
          : filters.selectedVelocityAssignee;
      fetchVelocityAPI(assignee, filters, loadingVelocity, dispatch);
    }
    dispatch(setVelocityFilter(VelocityFilterData));
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(toggleLoadingVelocityFilter(false));
  }
};

// FETCH - Cycle Filter
export const fetchCycleFilter = async (dispatch, filters) => {
  try {
    dispatch(toggleLoadingFilterCycle(true));
    dispatch(
      setLoading({
        stages: true,
        overtime: true,
        jiraComparison: true,
      })
    );
    const payload = {
      product:
        filters.selectedProduct[0] === "All" ? null : filters.selectedProduct,
      component:
        filters.selectedComponent[0] === "All" ? null : filters.selectedComponent,
      jira_status_derived:
        filters.selectedStage[0] === "All" ? null : filters.selectedStage,
      issue_type:
        filters.selectedIssueType[0] === "All"
          ? null
          : filters.selectedIssueType,
      assignee_name:
        filters.selectedAssignee[0] === "All" ? null : filters.selectedAssignee,
      qa_contact:
        filters.selectedQAContact[0] === "All"
          ? null
          : filters.selectedQAContact,
      priority:
        filters.selectedPriority[0] === "All" ? null : filters.selectedPriority,
      story_point_latest:
        filters.selectedStoryPoint[0] === "All"
          ? null
          : filters.selectedStoryPoint,
      labels: filters.selectedLabel[0] === "All" ? null : filters.selectedLabel,
      component: null
    };
    const CYCLEFILTERS = `${SERVER_URL}/cycle-time-engineering-kpis-filter?payload=${encodeURIComponent(
      JSON.stringify(payload)
    )}`;
    const CycleFiltersResponse = axios.get(CYCLEFILTERS);
    const CycleFilterData = (await CycleFiltersResponse).data.data;
    dispatch(setCycleFilter(CycleFilterData));
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(toggleLoadingFilterCycle(false));
  }
};

// FETCH - CFD Filter
export const fetchCFDFilter = async (dispatch, filters) => {
  try {
    dispatch(toggleLoadingCfdFilter(true));
    dispatch(
      setLoading({
        cfd: true,
      })
    );
    const payload = {
      product:
        filters.selectedCustomerProduct[0] === "All"
          ? null
          : filters.selectedCustomerProduct,
      component:
        filters.selectedCustomerComponent[0] === "All"
          ? null
          : filters.selectedCustomerComponent,
      priority:
        filters.selectedCustomerIssueType[0] === "All"
          ? null
          : filters.selectedCustomerIssueType,
      labels:
        filters.selectedCustomerLabel[0] === "All"
          ? null
          : filters.selectedCustomerLabel,
    };
    const CFDFILTERS = `${SERVER_URL}/cfd-engineering-kpis-filter?payload=${encodeURIComponent(
      JSON.stringify(payload)
    )}`;
    const CFDFiltersResponse = axios.get(CFDFILTERS);
    const CFDFilterData = (await CFDFiltersResponse).data.data;
    dispatch(setCFDFilter(CFDFilterData));
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(toggleLoadingCfdFilter(false));
  }
};

// FETCH - Code Coverage Filter
export const fetchCodeCoverageFilter = async (dispatch) => {
  try {
    dispatch(toggleLoadingCodeCoverageFilter(true));
    dispatch(
      setLoading({
        currentCoverage: true,
        automationCoverage: true,
      })
    );
    const CODE_COVERAGE_FILTERS = `${SERVER_URL}/code-coverage-kpis-filter`;
    const codeCoverageResponse = axios.get(CODE_COVERAGE_FILTERS);
    const codeCoverageFilterData = (await codeCoverageResponse).data.data;
    dispatch(setCodeCoverageFilter(codeCoverageFilterData));
    if (
      codeCoverageFilterData?.products &&
      codeCoverageFilterData.products.length > 0
    ) {
      dispatch(
        setFilters({
          section: "selectedCodeCoverageProduct",
          value: codeCoverageFilterData.products[0],
        })
      );
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(toggleLoadingCodeCoverageFilter(false));
  }
};

// FETCH - Velocity API
const fetchVelocityAPI = async (
  assignee,
  filters,
  loadingVelocity,
  dispatch
) => {
  if (
    filters.selectedVelocityProduct.length > 0 &&
    loadingVelocity &&
    assignee !== null
  ) {
    try {
      const payload = {
        product:
          filters.selectedVelocityProduct[0] === "All"
            ? null
            : filters.selectedVelocityProduct,
        component:
          filters.selectedVelocityComponent[0] === "All"
            ? null
            : filters.selectedVelocityComponent,
        issue_type:
          filters.selectedVelocityIssueType[0] === "All"
            ? null
            : filters.selectedVelocityIssueType,
        priority:
          filters.selectedVelocityPriority[0] === "All"
            ? null
            : filters.selectedVelocityPriority,
        labels:
          filters.selectedVelocityLabel[0] === "All"
            ? null
            : filters.selectedVelocityLabel,
        assignee_name: assignee,
        choose_velocity_option: filters.selectedVelocity,
        view_by: filters.viewByVelocity.toLowerCase(),
      };
      const TEAM_VELOCITY = `${SERVER_URL}/team-velocity?payload=${encodeURIComponent(
        JSON.stringify(payload)
      )}`;
      const DEVELOPER_VELOCITY = `${SERVER_URL}/developer-velocity?payload=${encodeURIComponent(
        JSON.stringify(payload)
      )}`;
      const AVG_DEVELOPER_VELOCITY = `${SERVER_URL}/average-velocity-per-developer?payload=${encodeURIComponent(
        JSON.stringify(payload)
      )}`;
      const teamVelocityResponse = axios.get(TEAM_VELOCITY);
      const developerVelocityResponse = axios.get(DEVELOPER_VELOCITY);
      const avgDeveloperVelocityResponse = axios.get(AVG_DEVELOPER_VELOCITY);

      const teamVelocityData = (await teamVelocityResponse).data;
      if (teamVelocityData) {
        dispatch(
          setTeamVelocityData(
            teamVelocityData?.data !== null ? teamVelocityData?.data : []
          )
        );
        dispatch(
          setLoading({
            teamVelocity: false,
          })
        );
      }
      const developerVelocityData = (await developerVelocityResponse).data;
      if (developerVelocityData) {
        dispatch(
          setDeveloperVelocityData(
            developerVelocityData?.data !== null
              ? developerVelocityData?.data
              : []
          )
        );
        dispatch(
          setLoading({
            developerVelocity: false,
          })
        );
      }
      const avgDeveloperVelocityData = (await avgDeveloperVelocityResponse)
        .data;
      if (avgDeveloperVelocityData) {
        dispatch(
          setAvgDeveloperVelocityData(
            avgDeveloperVelocityData?.data !== null
              ? avgDeveloperVelocityData?.data
              : []
          )
        );
        dispatch(
          setLoading({
            avgDeveloperVelocity: false,
          })
        );
      }
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(toggleLoadingVelocity(false));
      dispatch(toggleVelocityAssignee(false));
    }
  }
};

// FETCH - CYCLE API
export const fetchCycleAPI = async (
  dispatch,
  filters,
  cycleDateRange,
  jiraDateRange
) => {
  try {
    const payload = {
      product:
        filters.selectedProduct[0] === "All" ? null : filters.selectedProduct,
      component:
        filters.selectedComponent[0] === "All" ? null : filters.selectedComponent,
      jira_status_derived:
        filters.selectedStage[0] === "All" ? null : filters.selectedStage,
      issue_type:
        filters.selectedIssueType[0] === "All"
          ? null
          : filters.selectedIssueType,
      assignee_name:
        filters.selectedAssignee[0] === "All" ? null : filters.selectedAssignee,
      qa_contact:
        filters.selectedQAContact[0] === "All"
          ? null
          : filters.selectedQAContact,
      ticket_status: filters.selectedTicketProgress,
      priority:
        filters.selectedPriority[0] === "All" ? null : filters.selectedPriority,
      story_point_latest:
        filters.selectedStoryPoint[0] === "All"
          ? null
          : filters.selectedStoryPoint,
      labels: filters.selectedLabel[0] === "All" ? null : filters.selectedLabel,
    };
    const satgesPayload = {
      ...payload,
      open_start_date: `${moment(cycleDateRange?.startDate).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      )}`,
      open_end_date: `${moment(cycleDateRange?.endDate).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      )}`,
    };
    const overtimePayload = {
      ...payload,
      view_by: "quarterly",
    };
    const jiraComparisonPayload = {
      ...payload,
      issuenum_list: [],
      open_start_date: `${moment(jiraDateRange?.startDate).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      )}`,
      open_end_date: `${moment(jiraDateRange?.endDate).format(
        "YYYY-MM-DD HH:mm:ss.SSS"
      )}`,
    };
    const CYCLE_TIME_SATGES_URL = `${SERVER_URL}/cycle-time-specific-duration?payload=${encodeURIComponent(
      JSON.stringify(satgesPayload)
    )}`;
    const CYCLE_TIME_OVERTIME_URL = `${SERVER_URL}/cycle-time-over-period-time-comp?payload=${encodeURIComponent(
      JSON.stringify(overtimePayload)
    )}`;
    const CYCLE_TIME_JIRA_COMPARISON_URL = `${SERVER_URL}/cycle-time-projects-comp?payload=${encodeURIComponent(
      JSON.stringify(jiraComparisonPayload)
    )}`;

    const stagesResponse = axios.get(CYCLE_TIME_SATGES_URL);
    const overtimeResponse = axios.get(CYCLE_TIME_OVERTIME_URL);
    const jiraComparisonResponse = axios.get(CYCLE_TIME_JIRA_COMPARISON_URL);

    const stagesData = (await stagesResponse).data;
    if (stagesData) {
      dispatch(
        setStagesData(
          stagesData.data.cycle_time_specific_duration !== null
            ? stagesData.data.cycle_time_specific_duration
            : []
        )
      );
      dispatch(
        setLoading({
          stages: false,
        })
      );
    }

    const overTime = (await overtimeResponse).data;
    if (overTime) {
      dispatch(setOvertimeData(overTime.data !== null ? overTime.data : []));
      dispatch(
        setLoading({
          overtime: false,
        })
      );
    }

    const jiraComparison = (await jiraComparisonResponse).data;
    if (jiraComparison) {
      dispatch(
        setJiraComparisonData(
          jiraComparison.data !== null ? jiraComparison.data : []
        )
      );
      dispatch(
        setLoading({
          jiraComparison: false,
        })
      );
      const issuenumValues =
        jiraComparison?.data && Array.isArray(jiraComparison.data)
          ? jiraComparison.data.map((item) => item.issuenum)
          : [];
      const uniqueIssuenumValues = [...new Set(issuenumValues)];
      const firstTenUniqueIssuenumValues = uniqueIssuenumValues.slice(0, 10);
      dispatch(
        setFilters({
          section: "selectedJiraIssues",
          value: firstTenUniqueIssuenumValues,
        })
      );
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(toggleLoadingCycle(false));
  }
};

// FETCH - CFD API
export const fetchCFDAPI = async (dispatch, filters) => {
  try {
    const payload = {
      product:
        filters.selectedCustomerProduct[0] === "All"
          ? null
          : filters.selectedCustomerProduct,
      component:
        filters.selectedCustomerComponent[0] === "All"
          ? null
          : filters.selectedCustomerComponent,
      priority:
        filters.selectedCustomerIssueType[0] === "All"
          ? null
          : filters.selectedCustomerIssueType,
      labels:
        filters.selectedCustomerLabel[0] === "All"
          ? null
          : filters.selectedCustomerLabel,
      view_by: filters.viewByCfd.toLowerCase(),
    };
    const CFD = `${SERVER_URL}/customer-found-defects?payload=${encodeURIComponent(
      JSON.stringify(payload)
    )}`;
    const cfdResponse = axios.get(CFD);
    const cfdData = (await cfdResponse).data;
    if (cfdData) {
      dispatch(setCfdData(cfdData.data !== null ? cfdData.data : []));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(
      setLoading({
        cfd: false,
      })
    );
    dispatch(toggleLoadingCfd(false));
  }
};

// FETCH - CC_Trends
export const fetchCCTrendsAPI = async (dispatch, filters) => {
  try {
    dispatch(
      setLoading({
        currentCoverage: true,
        automationCoverage: true,
        automationTestCoverage: true,
      })
    );
    const payload = {
      productname: [filters.selectedCodeCoverageProduct],
      labels: null,
      view_by: filters.viewByCC,
    };
    const payloadTestRate = {
      product: [filters.selectedCodeCoverageProduct],
      labels: null,
      view_by: filters.viewByCC,
    };
    const automationCC = `${SERVER_URL}/code-coverage-kpi?payload=${encodeURIComponent(
      JSON.stringify(payload)
    )}`;
    const automationTestRate = `${SERVER_URL}/automotation-test-rate-kpi?payload=${encodeURIComponent(
      JSON.stringify(payloadTestRate)
    )}`;
    const automationCCResponse = axios.get(automationCC);
    const automationTestRateResponse = axios.get(automationTestRate);
    const automationCCData = (await automationCCResponse).data;
    const automationTestRateData = (await automationTestRateResponse).data;
    if (automationCCData && automationCCData.data !== null) {
      dispatch(setCodeCoverageLastUpdate(automationCCData.data[0].period));
      dispatch(setAutomationCodeCoverage(automationCCData.data));
    }
    if (automationTestRateData) {
      dispatch(
        setAutomationTestCoverage(
          automationTestRateData.data !== null
            ? automationTestRateData.data
            : []
        )
      );
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(
      setLoading({
        currentCoverage: false,
        automationCoverage: false,
        automationTestCoverage: false,
      })
    );
    dispatch(toggleLoadingCodeCoverage(false));
  }
};

export const transformAutomationCoverage = (inputArray) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return inputArray.map((item) => {
    const date = new Date(item.period);
    const formattedDate = `${monthNames[date.getMonth()]} ${String(
      date.getDate()
    ).padStart(2, "0")}`;
    return {
      name: formattedDate,
      value: parseFloat(item.linecoverage_percentage),
    };
  });
};

export const transformAutomationTest = (inputArray) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return inputArray.map((item) => {
    const date = new Date(item.period);
    const formattedDate = `${monthNames[date.getMonth()]} ${String(
      date.getDate()
    ).padStart(2, "0")}`;
    return {
      name: formattedDate,
      value: item.pass_percentage * 100,
      product: item.product,
    };
  });
};

export const transformDonutCoverageData = (coverageData) => {
  const coverageValue = parseFloat(coverageData?.value);
  const covered = Math.round(coverageValue * 10) / 10;
  const notCovered = Math.round((100 - covered) * 10) / 10;

  const getColor = (value, type) => {
    if (type === "coverage") {
      if (value >= 75) {
        return "#2BA664"; // Green
      } else if (value >= 60 && value < 75) {
        return "#FFBC0B"; // Yellow
      } else {
        return "#C44556"; // Red
      }
    } else if (type === "notCovered") {
      if (value <= 25) {
        return "#C0F5D7"; // Green
      } else if (value <= 40 && value > 25) {
        return "#FFEDBA"; // Yellow
      } else {
        return "#FFE4E5"; // Red
      }
    }
  };

  const transformedData = [
    {
      key: "covered",
      color: getColor(covered, "coverage"),
      name: "Coverage",
      value: covered ? covered : 0,
    },
    {
      key: "notCovered",
      color: getColor(notCovered, "notCovered"),
      name: "Not Covered",
      value: notCovered,
    }, // Round to one decimal place
  ];

  return transformedData;
};

const fetchVelocityDetail = async (payload) => {
  Logger.info(
    `Started fetching velocity detail api`,
    MODULE,
    window.location.href
  );
  const encodedPayload = encodeURIComponent(JSON.stringify(payload));
  message.loading({ content: "Action in progress...", key: "loading" });
  const response = await axios.get(
    `${SERVER_URL}/velocity-detail-kpi?payload=${encodedPayload}`
  );
  message.success({
    content: "Data fetched successfully",
    key: "loading",
    duration: 2,
  });
  Logger.info(
    `Successfully fetched velocity detail api`,
    MODULE,
    window.location.href
  );
  if (response.data.data === null) {
    message.info({ content: "No data found for this request", duration: 2 });
    return [];
  }
  return response.data.data;
};

const fetchCycleDetail = async (payload) => {
  Logger.info(
    `Started fetching Cycle detail api`,
    MODULE,
    window.location.href
  );
  const encodedPayload = encodeURIComponent(JSON.stringify(payload));
  message.loading({ content: "Action in progress...", key: "loading" });
  const response = await axios.get(
    `${SERVER_URL}/cycle-time-kpi_details?payload=${encodedPayload}`
  );
  message.success({
    content: "Data fetched successfully",
    key: "loading",
    duration: 2,
  });
  Logger.info(
    `Successfully fetched cycle detail api`,
    MODULE,
    window.location.href
  );
  if (response.data?.data?.cycle_time_specific_duration === null) {
    message.info({ content: "No data found for this request", duration: 2 });
    return [];
  }
  return response.data?.data?.cycle_time_specific_duration;
};

const fetchCFDDetail = async (payload) => {
  Logger.info(`Started fetching CFD detail api`, MODULE, window.location.href);
  const encodedPayload = encodeURIComponent(JSON.stringify(payload));
  message.loading({ content: "Action in progress...", key: "loading" });
  const response = await axios.get(
    `${SERVER_URL}/cfd-detail-kpi?payload=${encodedPayload}`
  );
  message.success({
    content: "Data fetched successfully",
    key: "loading",
    duration: 2,
  });
  Logger.info(
    `Successfully fetched CFD detail api`,
    MODULE,
    window.location.href
  );
  if (response.data.data === null) {
    message.info({ content: "No data found for this request", duration: 2 });
    return [];
  }
  return response.data.data;
};

const fetchLastRefreshed = async () => {
  const response = await axios.get(`${SERVER_URL}/last-refreshed-date`);
  return response.data.data;
};

const isAll = (item) => item[0] === "All";

const createPayload = (filters, interval, graph, param) => {
  const {
    selectedVelocityProduct,
    selectedVelocityComponent,
    selectedVelocityIssueType,
    selectedVelocityPriority,
    selectedVelocityAssignee,
    selectedVelocityLabel,
    selectedVelocity,
    viewByVelocity,
  } = filters;

  const commonPayload = {
    issue_type: isAll(selectedVelocityIssueType)
      ? null
      : selectedVelocityIssueType,
    priority: isAll(selectedVelocityPriority) ? null : selectedVelocityPriority,
    labels: isAll(selectedVelocityLabel) ? null : selectedVelocityLabel,
    component: isAll(selectedVelocityComponent) ? null : selectedVelocityComponent,
    choose_velocity_option: selectedVelocity,
    view_by: viewByVelocity.toLowerCase(),
    interval: interval,
  };

  if (graph === "team_velocity") {
    return {
      ...commonPayload,
      product: isAll(selectedVelocityProduct) ? null : selectedVelocityProduct,
      assignee_name: isAll(selectedVelocityAssignee)
        ? null
        : selectedVelocityAssignee,
    };
  } else if (graph === "avg_team_velocity") {
    return {
      ...commonPayload,
      product: [param],
      assignee_name: isAll(selectedVelocityAssignee)
        ? null
        : selectedVelocityAssignee,
    };
  } else if (graph === "dev_velocity") {
    return {
      ...commonPayload,
      product: isAll(selectedVelocityProduct) ? null : selectedVelocityProduct,
      assignee_name: [param],
    };
  }
};

export const useVelocityDetail = (
  filters,
  isEnabled,
  interval,
  graph,
  param = null
) => {
  const payload = createPayload(filters, interval, graph, param);
  let queryKey;
  switch (graph) {
    case "team_velocity":
      queryKey = "velocity_detail";
      break;
    case "avg_team_velocity":
      queryKey = "velocity_detail_avg";
      break;
    case "dev_velocity":
      queryKey = "velocity_detail_dev";
      break;
    default:
      queryKey = "velocity_detail_default";
      break;
  }
  return useQuery({
    queryKey: [queryKey, payload],
    queryFn: () => fetchVelocityDetail(payload),
    enabled: isEnabled,
    staleTime: 3 * 3600000,
    gcTime: 3 * 3600000,
  });
};

const getStagePayload = (filters, graph, param, dateRange = null) => {
  const {
    selectedProduct,
    selectedComponent,
    selectedIssueType,
    selectedAssignee,
    selectedQAContact,
    selectedTicketProgress,
    selectedPriority,
    selectedStoryPoint,
    selectedLabel,
    selectedStage,
    viewBy,
  } = filters;

  const getFilterValue = (filter) => (isAll(filter) ? null : filter);

  const basePayload = {
    jira_status_derived: getFilterValue(selectedStage),
    product: getFilterValue(selectedProduct),
    component: getFilterValue(selectedComponent),
    issue_type: getFilterValue(selectedIssueType),
    assignee_name: getFilterValue(selectedAssignee),
    qa_contact: getFilterValue(selectedQAContact),
    ticket_status: selectedTicketProgress,
    priority: getFilterValue(selectedPriority),
    story_point_latest: getFilterValue(selectedStoryPoint),
    labels: getFilterValue(selectedLabel),
    open_start_date: null,
    open_end_date: null,
    issuenum: null,
    view_by: null,
    interval: null,
  };
  if (graph === "cycle_time_stages") {
    return {
      ...basePayload,
      jira_status_derived: [param],
      open_start_date: dateRange
        ? `${moment(dateRange.startDate).format("YYYY-MM-DD HH:mm:ss.SSS")}`
        : null,
      open_end_date: dateRange
        ? `${moment(dateRange.endDate).format("YYYY-MM-DD HH:mm:ss.SSS")}`
        : null,
    };
  } else if (graph === "cycle_time_stages_overtime") {
    return {
      ...basePayload,
      view_by: viewBy.toLowerCase(),
      interval: param,
    };
  } else {
    return {
      ...basePayload,
      open_start_date: dateRange
        ? `${moment(dateRange.startDate).format("YYYY-MM-DD HH:mm:ss.SSS")}`
        : null,
      open_end_date: dateRange
        ? `${moment(dateRange.endDate).format("YYYY-MM-DD HH:mm:ss.SSS")}`
        : null,
      issuenum: param,
    };
  }
};

export const useLastRefreshed = () => {
  return useQuery({
    queryKey: ["last refreshed"],
    queryFn: () => fetchLastRefreshed(),
    staleTime: 3 * 3600000,
    gcTime: 3 * 3600000,
  });
};

export const useStageDetail = (
  filters,
  isEnabled,
  graph,
  param,
  cycleDateRange
) => {
  const payload = getStagePayload(filters, graph, param, cycleDateRange);
  return useQuery({
    queryKey: [graph, payload],
    queryFn: () => fetchCycleDetail(payload),
    enabled: isEnabled,
    staleTime: 3 * 3600000,
    gcTime: 3 * 3600000,
  });
};

export const useCFDDetail = (filters, isEnabled, param, interval) => {
  const { selectedCustomerIssueType, selectedCustomerLabel, viewByCfd } =
    filters;
  const getFilterValue = (filter) => (isAll(filter) ? null : filter);
  const payload = {
    product: [param],
    priority: getFilterValue(selectedCustomerIssueType),
    labels: getFilterValue(selectedCustomerLabel),
    view_by: viewByCfd.toLowerCase(),
    interval: interval,
  };
  return useQuery({
    queryKey: ["cfdDetail", payload],
    queryFn: () => fetchCFDDetail(payload),
    enabled: isEnabled,
    staleTime: 3 * 3600000,
    gcTime: 3 * 3600000,
  });
};

export const getVelocityDataSource = (data) => {
  return data.map((item, index) => {
    const dataSourceItem = {
      key: index + 1,
      IssueNum: item.IssueNum || "-",
      Product: item.Product || "-",
      IssueType: item.IssueType || "-",
      Assignee_Name: item.Assignee_Name || "-",
      Assignee_User_Name: item.Assignee_User_Name || "-",
      Labels: item.Labels || "-",
      Priority: item.Priority || "-",
      Story_Points: item.Story_Points,
      Velocity_For: item.Velocity_For,
      Velocity_Jira_Status: item.Velocity_Jira_Status,
      velocity_option_date: item.velocity_option_date || "-",
      Velocity_Option_Fiscal_Qtr: item.Velocity_Option_Fiscal_Qtr || "-",
      Velocity_Option_Fiscal_Month: item.Velocity_Option_Fiscal_Month || "-",
      Velocity_Option_Calendar_Month: item.Velocity_Option_Calendar_Month,
    };
    return dataSourceItem;
  });
};

export const getStageDataSource = (data) => {
  return data.map((item, index) => {
    const dataSourceItem = {
      key: index + 1,
      IssueNum: item.IssueNum || "-",
      Product: item.Product || "-",
      IncludeTickets: item.IncludeTickets || "-",
      IssueType: item.IssueType || "-",
      Priority: item.Priority || "-",
      StoryPoints: item.StoryPoints,
      AssigneeName: item.AssigneeName || "-",
      QAContact: item.QAContact || "-",
      DevStartDate: item.DevStartDate || "-",
      DevStartFiscalQtr: item.DevStartFiscalQtr || "-",
      DevStartFiscalMonth: item.DevStartFiscalMonth || "-",
      DevStartCalendarMonth: item.DevStartCalendarMonth || "-",
      JiraStatusDerived: item.JiraStatusDerived || "-",
      DaysSpentinJiraStatus: item.DaysSpentinJiraStatus || "-",
    };
    return dataSourceItem;
  });
};

export const getCustomerDefectDataSource = (data) => {
  return data.map((item, index) => {
    const dataSourceItem = {
      key: index + 1,
      IssueNum: item.IssueNum || "-",
      Product: item.Product || "-",
      IssueType: item.IssueType || "-",
      Priority: item.Priority || "-",
      Labels: item.Labels || "-",
      DefectType: item.DefectType || "-",
      JiraCreatedDate: item.JiraCreatedDate || "-",
      JiraCreatedFiscalQtr: item.JiraCreatedFiscalQtr || "-",
      JiraCreatedFiscalMonth: item.JiraCreatedFiscalMonth || "-",
      JiraCreatedCalendarMonth: item.JiraCreatedCalendarMonth || "-",
    };
    return dataSourceItem;
  });
};

const convertDateFormat = (dateString) => {
  // Ensure the input is in the correct format
  const inputFormat = "YYYY-MM-DD HH:mm:ss";
  // Define the desired output format
  const outputFormat = "DD MMM YYYY, hh:mm A";

  // Parse and format the date
  return moment(dateString, inputFormat).format(outputFormat);
};

export const getVelocityColumn = () => {
  return [
    {
      title: <span className="text_bold">Issue Number</span>,
      dataIndex: "IssueNum",
      ellipsis: true,
      width: 120,
      align: "center",
      render: (text, record) => {
        const jiraLink = `https://jira.nutanix.com/browse/${text}`;
        return (
          <Typography.Link href={jiraLink} target="_blank">
            {text}
          </Typography.Link>
        );
      },
    },
    {
      title: <span className="text_bold">Product</span>,
      dataIndex: "Product",
      ellipsis: true,
      width: 200,
    },
    {
      title: <span className="text_bold">Issue Type</span>,
      dataIndex: "IssueType",
      ellipsis: true,
      width: 120,
    },
    {
      title: <span className="text_bold">Assignee Name</span>,
      dataIndex: "Assignee_Name",
      ellipsis: true,
      width: 150,
    },
    {
      title: <span className="text_bold">Assignee User Name</span>,
      dataIndex: "Assignee_User_Name",
      ellipsis: true,
      width: 200,
    },
    {
      title: <span className="text_bold">Priority</span>,
      dataIndex: "Priority",
      ellipsis: true,
      width: 120,
    },
    {
      title: <span className="text_bold">Labels</span>,
      dataIndex: "Labels",
      ellipsis: true,
      width: 120,
    },
    {
      title: <span className="text_bold">Velocity For</span>,
      dataIndex: "Velocity_For",
      ellipsis: true,
      width: 120,
    },
    {
      title: <span className="text_bold">Velocity Jira Status</span>,
      dataIndex: "Velocity_Jira_Status",
      ellipsis: true,
      width: 120,
    },
    {
      title: <span className="text_bold">Velocity Option Date</span>,
      dataIndex: "velocity_option_date",
      ellipsis: true,
      width: 200,
      render: (text, record) => {
        return convertDateFormat(text);
      },
    },
    {
      title: <span className="text_bold">Velocity Option Fiscal Qtr</span>,
      dataIndex: "Velocity_Option_Fiscal_Qtr",
      ellipsis: true,
      width: 200,
      render: (text, record) => {
        return formatQuarter(text);
      },
    },
    {
      title: <span className="text_bold">Velocity Option Fiscal Month</span>,
      dataIndex: "Velocity_Option_Fiscal_Month",
      ellipsis: true,
      width: 200,
    },
    {
      title: <span className="text_bold">Velocity Option Calendar Month</span>,
      dataIndex: "Velocity_Option_Calendar_Month",
      ellipsis: true,
      width: 200,
    },
    {
      title: <span className="text_bold">Story Points</span>,
      dataIndex: "Story_Points",
      ellipsis: true,
      align: "center",
      width: 120,
    },
  ];
};

export const getStageColumn = () => {
  return [
    {
      title: <span className="text_bold">Issue Number</span>,
      dataIndex: "IssueNum",
      ellipsis: true,
      width: 120,
      align: "center",
      render: (text, record) => {
        const jiraLink = `https://jira.nutanix.com/browse/${text}`;
        return (
          <Typography.Link href={jiraLink} target="_blank">
            {text}
          </Typography.Link>
        );
      },
    },
    {
      title: <span className="text_bold">Product</span>,
      dataIndex: "Product",
      ellipsis: true,
      width: 200,
    },
    {
      title: <span className="text_bold">Include Tickets</span>,
      dataIndex: "IncludeTickets",
      ellipsis: true,
      width: 200,
    },
    {
      title: <span className="text_bold">Issue Type</span>,
      dataIndex: "IssueType",
      ellipsis: true,
      width: 200,
    },
    {
      title: <span className="text_bold">Priority</span>,
      dataIndex: "Priority",
      ellipsis: true,
      width: 200,
    },
    {
      title: <span className="text_bold">Story Points</span>,
      dataIndex: "StoryPoints",
      ellipsis: true,
      width: 120,
      align: "center",
    },
    {
      title: <span className="text_bold">Assignee Name</span>,
      dataIndex: "AssigneeName",
      ellipsis: true,
      width: 200,
    },
    {
      title: <span className="text_bold">QA Contact</span>,
      dataIndex: "QAContact",
      ellipsis: true,
      width: 200,
    },
    {
      title: <span className="text_bold">Dev Start Date</span>,
      dataIndex: "DevStartDate",
      ellipsis: true,
      width: 200,
      render: (text, record) => {
        return convertDateFormat(text);
      },
    },
    {
      title: <span className="text_bold">Dev Start Fiscal Qtr</span>,
      dataIndex: "DevStartFiscalQtr",
      ellipsis: true,
      width: 200,
      render: (text, record) => {
        return formatQuarter(text);
      },
    },
    {
      title: <span className="text_bold">Dev Start Fiscal Month</span>,
      dataIndex: "DevStartFiscalMonth",
      ellipsis: true,
      width: 200,
    },
    {
      title: <span className="text_bold">Dev Start Calendar Month</span>,
      dataIndex: "DevStartCalendarMonth",
      ellipsis: true,
      width: 200,
    },
    {
      title: <span className="text_bold">Jira Status Derived</span>,
      dataIndex: "JiraStatusDerived",
      ellipsis: true,
      width: 200,
      // align: 'center',
    },
    {
      title: <span className="text_bold">Days Spent in Jira Status</span>,
      dataIndex: "DaysSpentinJiraStatus",
      ellipsis: true,
      width: 200,
      align: "center",
    },
  ];
};

export const getCustomerDefectColumn = () => {
  return [
    {
      title: <span className="text_bold">Issue Number</span>,
      dataIndex: "IssueNum",
      ellipsis: true,
      width: 120,
      align: "center",
      render: (text, record) => {
        const jiraLink = `https://jira.nutanix.com/browse/${text}`;
        return (
          <Typography.Link href={jiraLink} target="_blank">
            {text}
          </Typography.Link>
        );
      },
    },
    {
      title: <span className="text_bold">Product</span>,
      dataIndex: "Product",
      ellipsis: true,
      width: 120,
    },
    {
      title: <span className="text_bold">Issue Type</span>,
      dataIndex: "IssueType",
      ellipsis: true,
      width: 120,
    },
    {
      title: <span className="text_bold">Priority</span>,
      dataIndex: "Priority",
      ellipsis: true,
      width: 120,
    },
    {
      title: <span className="text_bold">Labels</span>,
      dataIndex: "Labels",
      ellipsis: true,
      width: 120,
    },
    {
      title: <span className="text_bold">Defect Type</span>,
      dataIndex: "DefectType",
      ellipsis: true,
      width: 120,
    },
    {
      title: <span className="text_bold">Jira Created Date</span>,
      dataIndex: "JiraCreatedDate",
      ellipsis: true,
      width: 200,
      render: (text, record) => {
        return convertDateFormat(text);
      },
    },
    {
      title: <span className="text_bold">Jira Created Fiscal Qtr</span>,
      dataIndex: "JiraCreatedFiscalQtr",
      ellipsis: true,
      width: 200,
      render: (text, record) => {
        return formatQuarter(text);
      },
    },
    {
      title: <span className="text_bold">Jira Created Fiscal Month</span>,
      dataIndex: "JiraCreatedFiscalMonth",
      ellipsis: true,
      width: 200,
    },
    {
      title: <span className="text_bold">Jira Created Calendar Month</span>,
      dataIndex: "JiraCreatedCalendarMonth",
      ellipsis: true,
      width: 200,
    },
  ];
};

// check from here
export const transformAndCalculatePercentagesJira = (
  quarterlyData,
  filters
) => {
  const allStatuses = [
    "Blocked",
    "Business UAT",
    "Code Review",
    "Deployment",
    "Development",
    "QA Testing",
    "QA/UAT Done",
  ];

  const result = quarterlyData.map((quarter) => {
    const quarterName = quarter.issuenum;
    const quarterObj = { name: quarterName };

    let sum = 0;
    allStatuses.forEach((status) => {
      const stage = quarter.data.find(
        (data) => data.jira_status_derived === status
      );
      const key = {
        "Total Day Spent": "total_days_spent",
        Average: "avg_days_spent",
        Median: "median_days_spent",
        "Percentile 90th Day Spent": "percentile_90th_days_spent",
      }[filters.selectedCycleTime];
      const value = stage ? stage[key] : 0;
      sum += value;
      quarterObj[status] = Number(value?.toFixed(1));
    });
    quarterObj.sum = sum;

    allStatuses.forEach((status) => {
      const percentageKey = status + "PERCENTAGE";
      if (sum !== 0) {
        quarterObj[percentageKey] = Math.round(
          (quarterObj[status] / sum) * 100
        );
      } else {
        // Handling the case where sum is 0
        quarterObj[percentageKey] = 0;
      }
    });

    return quarterObj;
  });

  return result;
};

export const transformAndCalculatePercentages = (quarterlyData, filters) => {
  const allStatuses = [
    "Blocked",
    "Business UAT",
    "Code Review",
    "Deployment",
    "Development",
    "QA Testing",
    "QA/UAT Done",
  ];

  const result = quarterlyData.map((quarter) => {
    const quarterName =
      quarter[filters.viewBy === "Monthly" ? "monthly" : "quarterly"];
    const quarterObj = { name: quarterName };

    let sum = 0;
    allStatuses.forEach((status) => {
      const stage = quarter?.data?.find(
        (data) => data.jira_status_derived === status
      );
      const key = {
        "Total Day Spent": "total_days_spent",
        Average: "avg_days_spent",
        Median: "median_days_spent",
        "Percentile 90th Day Spent": "percentile_90th_days_spent",
      }[filters.selectedCycleTime];
      const value = stage ? stage[key] : 0;
      sum += value;
      quarterObj[status] = Number(value?.toFixed(1));
    });
    quarterObj.sum = sum;
    allStatuses.forEach((status) => {
      const percentageKey = status + "PERCENTAGE";
      if (sum !== 0) {
        quarterObj[percentageKey] = (
          (quarterObj[status] / sum) *
          100
        ).toFixed();
      } else {
        // Handling the case where sum is 0
        quarterObj[percentageKey] = 0;
      }
    });

    return quarterObj;
  });

  return result.reverse();
};

export const formatQuarter = (text) => {
  // Extract the year and quarter from the text
  const year = text.substring(2, 4); // Extract the last two digits of the year
  const quarter = text.substring(6);

  // Format the text as "FYYY Q#"
  return `FY${year} Q${quarter}`;
};

export const getDataByPage = (data, page, pageSize) => {
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  return data?.slice(startIndex, endIndex);
};

const sortQuarters = (quarters) => {
  const quarterOrder = { Q1: 1, Q2: 2, Q3: 3, Q4: 4 };
  quarters.sort((a, b) => {
    const [yearA, quarterA] = a.split("-");
    const [yearB, quarterB] = b.split("-");
    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }
    return quarterOrder[quarterA] - quarterOrder[quarterB];
  });
  return quarters;
};

const getMonthIndex = (monthStr) => {
  const monthIndexMap = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };
  const [month, year] = monthStr.split(",");
  return parseInt(year) * 12 + monthIndexMap[month];
};

const sortQuartersVelocity = (quarters) => {
  const quarterOrder = { Q1: 1, Q2: 2, Q3: 3, Q4: 4 };
  // Sort the quarters array
  quarters.sort((a, b) => {
    const [yearA, quarterA] = a?.period_selection.split("-");
    const [yearB, quarterB] = b?.period_selection.split("-");
    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }
    return quarterOrder[quarterA] - quarterOrder[quarterB];
  });
  return quarters;
};

export const getPeriodSelectionVelocity = (velocityData, viewBy) => {
  let periodSelection = [];
  const uniquePeriods = [];

  velocityData?.forEach((item) => {
    if (item.data) {
      item.data.forEach((dataItem) => {
        const period = dataItem?.period_selection;
        const issueTypeBucket = dataItem?.issue_type_bucket || [];

        // Skip if period or issue_type_bucket is invalid
        if (!period || !issueTypeBucket.length) {
          return;
        }

        // Check if this period is already in uniquePeriods
        const existingPeriod = uniquePeriods.find(
          (periodItem) => periodItem.period_selection === period
        );

        if (existingPeriod) {
          // Merge issue_type_bucket ensuring uniqueness with Set
          existingPeriod.issue_type_bucket = [
            ...new Set([
              ...existingPeriod.issue_type_bucket,
              ...issueTypeBucket,
            ]),
          ];
        } else {
          // Add new period and issue_type_bucket
          uniquePeriods.push({
            period_selection: period,
            issue_type_bucket: issueTypeBucket,
          });
        }
      });
    }
  });

  // Sort quarters if needed
  const sortedUniquePeriods =
    viewBy === "Quarterly"
      ? sortQuartersVelocity(uniquePeriods)
      : uniquePeriods;

  // Build periodSelection array
  sortedUniquePeriods.forEach((period) => {
    if (period.period_selection) {
      periodSelection.push({
        name: period.period_selection,
        issue_type_bucket: period.issue_type_bucket,
      });
    }
  });

  // Sort by month only if viewBy is not "Quarterly"
  const sortedPeriodSelection =
    viewBy === "Quarterly"
      ? periodSelection
      : periodSelection.sort((a, b) => {
          const monthIndexA = getMonthIndex(a.name);
          const monthIndexB = getMonthIndex(b.name);
          return monthIndexA - monthIndexB;
        });
  // Return the sorted period selection
  return sortedPeriodSelection;
};

export const generateBarDataVelocity = (data, periodSelection, dataKey) => {
  return data.map((item) => {
    // Create a new object for each item (e.g., 'Sizer', 'Prism')
    const newObj = { name: item.name };

    // Iterate over the periodSelection array to add data for each period
    periodSelection.forEach((periodItem) => {
      // Find the matching period_selection data in the item
      const periodData = item.data.find(
        (dataItem) => dataItem.period_selection === periodItem.name
      );

      // Initialize period data with a default value of 0 if periodData is not found
      newObj[periodItem.name] = periodData
        ? Number(periodData[dataKey]?.toFixed(1)) || 0
        : 0;

      // Initialize issue type data with default values of 0
      const issueTypes = ["defects", "enhancement", "feature"];
      issueTypes.forEach((issueType) => {
        newObj[`${periodItem.name}-${issueType}`] = 0; // Default to 0
      });

      if (
        periodData &&
        periodData.issue_type_bucket &&
        periodData.issue_type_bucket[0]
      ) {
        // Loop through issue_type_bucket and update existing keys with actual values
        Object.entries(periodData.issue_type_bucket[0]).forEach(
          ([issueType, value]) => {
            newObj[`${periodItem.name}-${issueType}`] = value;
          }
        );
      }
    });

    return newObj;
  });
};

export const renderBarVelocity = (
  developerData,
  velocityData,
  filter,
  setParam,
  setEnabled,
  view,
  graph
) => {
  const quarters = [
    developerData.firstQuarter,
    developerData.secondQuarter,
    developerData.thirdQuarter,
    developerData.fourthQuarter,
  ];

  const months = [
    developerData.firstMonth,
    developerData.secondMonth,
    developerData.thirdMonth,
    developerData.fourthMonth,
    developerData.fifthMonth,
    developerData.sixthMonth,
  ];

  const bars = (periods) => {
    const selectedPeriods = periods.reduce((acc, period, index) => {
      if (!period) {
        acc.push(index);
      }
      return acc;
    }, []);
    const issueTypeColors = {
      defects: "#f55656", // Red color for defects
      feature: "#22a5f7", // Blue color for features
      enhancement: "#ffbc0b", // Yellow color for enhancement
    };
    const periodSelection = getPeriodSelectionVelocity(velocityData, filter);
    
      return periodSelection
        .filter((item, index) => !selectedPeriods.includes(index))
        .map((item, index) => {
          const barSize = 15; // Common bar size
          const uniqueStackId = `stack-1-${index}`; // Unique stackId for the first Bar
          const issueTypes = graph === 'avg_dev' ?  item?.issue_type_bucket[0] : item?.issue_type_bucket[index];
          if(issueTypes !== null && issueTypes !== undefined){
            const bars = Object?.keys(issueTypes)?.map((key, i, keysArray) => {
              // Check if the current key is the last one in the array
              const isLastKey = i === keysArray.length - 1;
              const color = issueTypeColors[key] || "#888888";
              return (
                <Bar
                  key={`${item.name}-${i}`} // Unique key for each Bar
                  stackId={uniqueStackId}
                  barSize={barSize}
                  barGap={20}
                  dataKey={`${item.name}-${key}`} // Unique dataKey per issue type
                  fill={color} // Use hardcoded color
                  onClick={(data) => {
                    handleBarClick(data, item.name, setParam, setEnabled);
                  }}
                >
                  {view && isLastKey && (
                    <LabelList
                      dataKey={`${item.name}`}
                      position="top"
                      style={labelStyle}
                    />
                  )}
                </Bar>
              );
            });
            return bars;
          }
        });
  };

  if (filter === "Quarterly") {
    return bars(quarters);
  } else {
    return bars(months);
  }
};


export const getPeriodSelection = (developerVelocityData, viewBy) => {
  let color = [
    "#BDE4FD",
    "#91D2FB",
    "#22A5F7",
    "#1B6DC6",
    "#4682B4",
    "#6495ED",
  ];
  let periodSelection = [];
  const velocityData = developerVelocityData.map((item) => item.data).flat();
  let uniquePeriods = [...new Set(velocityData.map((item) => item?.period_selection))];
  uniquePeriods = viewBy === "Quarterly" ? sortQuarters(uniquePeriods) : uniquePeriods;
  uniquePeriods.forEach((item, index) => {
    periodSelection.push({ name: item, color: color[index] });
  });
  // Sort the data array
  const sortedPeriodSelection = periodSelection.sort((a, b) => {
    const monthIndexA = getMonthIndex(a.name);
    const monthIndexB = getMonthIndex(b.name);
    return monthIndexA - monthIndexB;
  });
  return viewBy === "Quarterly" ? periodSelection : sortedPeriodSelection;
};

export const getPeriodSelectionCustomer = (developerVelocityData, viewBy) => {
  let color = [
    "#BDE4FD",
    "#91D2FB",
    "#22A5F7",
    "#1B6DC6",
    "#4682B4",
    "#6495ED",
  ];
  let periodSelection = [];
  const velocityData = [
    ...new Set(
      developerVelocityData
        .map((item) => item.data)
        .flat()
        .map((item) => item.period_selection)
    ),
  ];
  velocityData?.forEach((item, index) => {
    periodSelection.push({ name: item, color: color[index] });
  });
  // Sort the data array
  const sortedPeriodSelection = periodSelection.sort((a, b) => {
    const monthIndexA = getMonthIndex(a.name);
    const monthIndexB = getMonthIndex(b.name);
    return monthIndexA - monthIndexB;
  });
  return viewBy === "Quarterly"
    ? periodSelection.reverse()
    : sortedPeriodSelection;
};

export const findObjectByKey = (array, key, value) => {
  for (let obj of array) {
    if (obj[key] === value) {
      return obj;
    }
  }
  return null; // If object not found
};

export const generateMenuForDropdown = (data, id) => {
  const tooltips = {
    chooseVelocity: {
      Production:
        "Includes Jira's moved to Closed / Done during the time frame.",
      UAT: "Includes Jira's moved to 'Business UAT' or 'Ready for Stage' Stage during the time frame.",
    },
    cycleTicketProgress: {
      All: "Includes all Jira's if development started during the time frame.",
      "Only Closed":
        "Includes only Closed Jira's for which development started during the time frame.",
    },
  };

  const items = data?.map((item) => {
    let tooltipTitle = tooltips[id]?.[item]; // Get tooltip title based on ID and item
    const label = tooltipTitle ? (
      <Tooltip title={tooltipTitle} key={item}>
        {item}
      </Tooltip>
    ) : (
      item
    );
    return {
      label,
      value: item,
      'data-testid': item
    };
  });
  return items;
};

export const labelStyle = {
  fontSize: "11px",
  fill: "#9AA5B5",
  display: "flex",
  width: "100px",
};

export const ApplicationComponent = {
  CFD: "CFD",
  CYCLE_TIME: "CYCLE_TIME",
  VELOCITY: "VELOCITY",
  CODECOVERAGE: "CODECOVERAGE",
};

export const generateBarData = (data, periodSelection, dataKey) => {
  return data.map((item) => {
    const newObj = { name: item.name };
    periodSelection.forEach((periodItem) => {
      const numbers = item.data.find(
        (dataItem) => dataItem.period_selection === periodItem.name
      )?.[dataKey]; // Dynamic dataKey
      newObj[periodItem.name] = Number(numbers?.toFixed(1)) || null;
    });
    return newObj;
  });
};

export const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={30}
        y={10}
        dy={0} // Adjusted for vertical centering
        dx={-25}
        textAnchor="end"
        fill={ThemeManager.getVar("gray-1")}
        fontSize={11}
        transform="rotate(-15)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const handleBarClick = (data, interval, setParam, setEnabled) => {
  setParam({ name: data.name, interval });
  setEnabled(true);
};

export const renderBar = (
  developerData,
  velocityData,
  filter,
  setParam,
  setEnabled,
  view
) => {
  const quarters = [
    developerData.firstQuarter,
    developerData.secondQuarter,
    developerData.thirdQuarter,
    developerData.fourthQuarter,
  ];

  const months = [
    developerData.firstMonth,
    developerData.secondMonth,
    developerData.thirdMonth,
    developerData.fourthMonth,
    developerData.fifthMonth,
    developerData.sixthMonth,
  ];

  const bars = (periods) => {
    const selectedPeriods = periods.reduce((acc, period, index) => {
      if (!period) {
        acc.push(index);
      }
      return acc;
    }, []);

    const periodSelection = getPeriodSelection(velocityData, filter);

    let filteredPeriodSelection = periodSelection.filter(
      (item, index) => !selectedPeriods.includes(index)
    );

    return filteredPeriodSelection.map((item) => {
      const barSize = 15; // Common bar size
      return (
        <Bar
          key={item.name}
          barSize={barSize}
          dataKey={item.name}
          fill={item.color}
          onClick={(data) =>{
            console.log('data', data);
            
            handleBarClick(data, item.name, setParam, setEnabled)
          }}
        >
          {view && (
            <LabelList dataKey={item.name} position="top" style={labelStyle} />
          )}
        </Bar>
      );
    });
  };

  if (filter === "Quarterly") {
    return bars(quarters);
  } else {
    return bars(months);
  }
};

export const CustomizedXAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text dx={-35} x={0} y={0} fill={"#9aa5b5"} fontSize={12} transform="rotate(-55)">
        {payload.value}
      </text>
    </g>
  );
};
export const CustomizedYAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-15} y={10} fill={"#9aa5b5"} fontSize={12} >
        {payload.value}%
      </text>
    </g>
  );
};

export const graphHeight = 200;

export const getTotalDay = (findStageData) => {
  return (
    findStageData("Blocked") +
    findStageData("Development") +
    findStageData("Code Review") +
    findStageData("QA Testing") +
    findStageData("Business UAT") +
    findStageData("QA/UAT Done") +
    findStageData("Deployment")
  );
};

export const getPercentileData = (value, findStageData) => {
  return Math.round((findStageData(value) / getTotalDay(findStageData)) * 100);
};

// Function to create stage data
export const createStageData = (stages, findStageData) => {
  const colors = {
    Blocked: "#F55656",
    Development: "#F0CE75",
    "Code Review": "#3DAEF5",
    "QA Testing": "#596CA6",
    "Business UAT": "#8974F0",
    "QA/UAT Done": "#93D1B2",
    Deployment: "#3D8F8F",
  };

  return stages.map((stage) => ({
    stage,
    day: getPercentileData(stage, findStageData),
    dayCount: findStageData(stage),
    color: colors[stage] || "#000000", // Default color if stage is not found
  }));
};

export const footerData = [
  { id: "Blocked", label: "Blocked", color: "#F55656" },
  { id: "Development", label: "Development", color: "#F0CE75" },
  { id: "Code Review", label: "Code Review", color: "#3DAEF5" },
  { id: "QA Testing", label: "QA Testing", color: "#596CA6" },
  { id: "Business UAT", label: "Business UAT", color: "#8974F0" },
  { id: "QA/UAT Done", label: "QA/UAT Done", color: "#93D1B2" },
  { id: "Deployment", label: "Deployment", color: "#3D8F8F" },
];

export const CartesianGridColor = "#f2f4f6"
export const RefrenceLineColor = "#d5dae0"
