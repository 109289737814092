import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Input,
  Notification,
  NotificationTrigger,
} from "@nutanix-ui/prism-reactjs";

import "./styles.css";

import { ETLCanvasContext, ETLCanvasContextDispatcher } from "../../config/contexts";

import axios from "axios";

import { SERVER_URL } from "../../config/config";

import { useHistory, useLocation } from "react-router-dom";
export default function PipelineSetting(props) {
  const location = useLocation();
  const history = useHistory();
  const pipelineData = useContext(ETLCanvasContext);
  const setPipelineData = useContext(ETLCanvasContextDispatcher);
  const [processName, setProcessName] = useState(pipelineData.processName || "");
  const [enable_time_window, setEnable_time_window] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setProcessName(pipelineData.processName);
    pipelineData.processName&& setDisabled(false)
  }, [pipelineData]);

  const handlePipelineNameChange = (e) => {
    setProcessName((prevState) => e.target.value);
    setPipelineData({ ...pipelineData, processName: e.target.value });
    if (processName !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  const handleProcessTimeWindow = (e) => {
    setEnable_time_window((prevState) => e.target.value);
    setPipelineData({ ...pipelineData, enable_time_window: e.target.value });
  };

  const submitETL = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const pipelineName = queryParameters.get("pipelineName");
    if (
      Object.values(pipelineData?.SourceSchema).filter(
        (schemaObj) => schemaObj.length > 0
      ).length === 0
    ) {
      NotificationTrigger.add({
        id: "check source schema",
        className: "successAlert",
        message: "please add sources to proceed",
        type: "warning",
        autoDismissDelaySecs: 90,
      });
    } else if (
      Object.values(pipelineData?.TargetSchema).filter(
        (schemaObj) => schemaObj.length > 0
      ).length === 0
    ) {
      NotificationTrigger.add({
        id: "check target schema",
        className: "successAlert",
        message: "please add targets to proceed",
        type: "warning",
        autoDismissDelaySecs: 90,
      });
    } else if (
      (pipelineData?.transfromQuery?.globalQuery?.queryString?.query&&
       atob(pipelineData?.transfromQuery?.globalQuery?.queryString?.query)) === undefined) {
      NotificationTrigger.add({
        id: "check query",
        className: "successAlert",
        message: "please add transformation query to proceed",
        type: "warning",
        autoDismissDelaySecs: 90,
      });
    } else {
      NotificationTrigger.add({
        id: "upload success",
        className: "successAlert",
        message: "process creation Request has been submitted",
        type: "warning",
        autoDismissDelaySecs: 90,
      });
      setDisabled(true)
      axios
        .post(`${SERVER_URL}/createProcessModel`, pipelineData)
        .then((resp) => {
          NotificationTrigger.add({
            id: "upload success",
            className: "successAlert",
            message: "process got created",
            type: "success",
            autoDismissDelaySecs: 90,
          });
          setTimeout(
            history.push({
              pathname: `/PipeLine_Processes_List/${pipelineData.project_id}/${pipelineName}`,
              state: {
                Project: location.state?.project,
                pipeID: location.state?.pipeID,
                scheduleType: location.state.scheduleType,
              },
            }),
            3000
          );
        })
        .catch((err) => {
          NotificationTrigger.add({
            id: "upload success",
            className: "successAlert",
            message: "Something went wrong",
            type: "error",
            autoDismissDelaySecs: 90,
          });
        });
    }
  };
  return (
    <div className="pipeLineSettingContainer">
      <h1>{props?.pipeLineName.toUpperCase()}</h1>
      <div className="settingFormContainer">
        {location.state.scheduleType === "streaming" && (
          <Input
            data-testid ='paas-etlCanvas-streamTypeInput'
            placeholder="Enter Time Window"
            // wrapperProps={{ "data-test-id": "pipeLineNameForm" }}
            onChange={handleProcessTimeWindow}
            value={enable_time_window}
          />
        )}
        <div className="pipeLineNameForm">
          <Input
          data-testid ='paas-etlCanvas-processNameInput'
            placeholder="Enter Process Name"
            // wrapperProps={{ "data-test-id": "pipeLineNameForm" }}
            onChange={handlePipelineNameChange}
            value={processName}
          />
        </div>
        <div data-testid ='paas-etlCanvas-process-save-btn'>
          <Button disabled={disabled} onClick={submitETL}>
            Save
          </Button>
        </div>
      </div>
      <Notification className="alert modal " data-testid="paas-etlCanvas-notifications">
        <div
          style={{
            position: "fixed",
            top: "80px",
            right: "340px",
          }}
        />
      </Notification>
    </div>
  );
}
