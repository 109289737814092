import React, { useState, useContext, useEffect } from "react";
import {
  Modal,
  Button,
  StackingLayout,
  FormItemInput,
  FormItemSelect,
  Form,
  FormSection,
  TextLabel,
} from "@nutanix-ui/prism-reactjs";

import {
  ETLCanvasContext,
  ETLCanvasContextDispatcher,
} from "../../config/contexts";

import axios from "axios";
import { SERVER_URL } from "../../config/config";
export default function MongoDBModal(props) {
  const { data, schema, origin, handleMongoOnSave } = props;
  const pipelineData = useContext(ETLCanvasContext);
  const setPipelineData = useContext(ETLCanvasContextDispatcher);
  const queryParameters = new URLSearchParams(window.location.search);
  const projectID = queryParameters.get("projectID");
  const pipeLineID = queryParameters.get("pipelineID");
  useEffect(() => {
    setPipelineData({
      ...pipelineData,
      pipelineID: pipeLineID,
      project_id: projectID,
    });// eslint-disable-next-line 
  }, []);
  const DEFAULT_VALIDATIONS = [
    {
      alias: {
        [Form.VALIDATION_RULE.REQUIRED]: true,
        message: "Alias is required",
      },
    },
    {
      schemaForm: {
        [Form.VALIDATION_RULE.REQUIRED]: true,
        message: "schemaForm value is required",
      },
    },
    {
      tableName: {
        [Form.VALIDATION_RULE.REQUIRED]: true,
        message: "tableName value is required",
      },
    },
  ];
  const [schemaMapping, setSchemaMapping] = useState(
    data?.schemaMapping
      ? JSON.parse(data?.schemaMapping)
      : data?.schema_mapping
      ? JSON.parse(data?.schema_mapping)
      : ""
  );

  const [schemaAndTableData, setSchemaAndTableData] = useState([]);
  const [schemaLoading, setSchemaLoading] = useState(true);
  const [schemaData, setSchemaData] = useState([]);
  const [searchSchemaData, setSearchSchemaData] = useState([]);
  const [selectedTableName, setSelectedTableName] = useState(null);
  const [tableColsLoading, setTableColsLoading] = useState(true);

  const [tableNameData, setTableNameData] = useState([]);
  const [searchTableNameData, setSearchTableNameData] = useState([]);

  const [tableColsNameData, setTableColsNameData] = useState([]);
  const [searchTableColsNameData, setSearchTableColsNameData] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState(null);
  const [selectedOffsetCol, setSelectedOffsetCol] = useState(null);
  useEffect(() => {
    setSelectedTableName((prevState) => {
      return {
        key: data?.tableName?.key,
        label: data?.tableName?.label,
      };
    });
    setSelectedSchema((prevState) => {
      return {
        key: data?.schemaForm?.key,
        label: data?.schemaForm?.label,
      };
    });
    setSelectedOffsetCol((prevState) => {
      return {
        key: data?.offset_col ? data?.offset_col?.key : "",
        label: data?.offset_col ? data?.offset_col?.label : "",
      };
    });
    setSchemaMapping(
      data?.schemaMapping
        ? JSON.parse(data?.schemaMapping)
        : data?.schema_mapping
        ? JSON.parse(data?.schema_mapping)
        : ""
    );
  }, [data]);
  useEffect(() => {
    // fetchSchemaAndTable();// eslint-disable-next-line 
  }, [schema || data]);
  const fetchSchemaAndTable = (value) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const projectID = queryParameters.get("projectID");
    if (schema) {
      axios
        .post(`${SERVER_URL}/fetchSchemaAndTable`, {
          project_id: projectID,
          conn_name: schema?.value ? schema?.value :schema,
        })
        .then((resp) => {
          if (resp?.data?.data?.res) {
            constructSchemaData(resp.data.data.res);
          }
        });
    }
  };
  const constructSchemaData = (res) => {
    setSchemaAndTableData((prev) => res);
    let tempData = [];
    let schemaKeys = Object.keys(res);
    schemaKeys.forEach((o) => {
      tempData.push({
        key: o,
        label: o,
        rowProps:{
          'data-testid':o
         }
      });
    });
    setSchemaData((prevState) => tempData);
    setSearchSchemaData((prevState) => tempData);
    setSchemaLoading((prevState) => false);
  };
  const handleTableNameChange = (item) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const projectID = queryParameters.get("projectID");
    let payload = {
      project_id: projectID,
      conn_name: schema?.value,
      schema_name: selectedSchema?.label,
      object_name: item?.label,
    };
    setSelectedTableName((prevState) => item);
    // getTableColumns(payload);
  };
  const getTableColumns = (payload) => {
    axios.post(`${SERVER_URL}/getColsMetadataModel`, payload).then((resp) => {
      if (resp.data?.data?.res) {
        setTableColsLoading(false);
        const tableColKeys = Object.keys(resp?.data?.data?.res);
        let TablleColsData = [];
        tableColKeys.forEach((o, i) => {
          TablleColsData.push({
            id: ++i,
            key: o,
            label: o,
            rowProps:{
              'data-testid':o
             }
          });
        });
        setTableColsNameData((prevState) => TablleColsData);
        setSearchTableColsNameData((prevState) => TablleColsData);
      }
    });
  };
  const handleOnInputValueChange = (
    event,
    searchArray,
    setSearchArray,
    setSelectedValue
  ) => {
    if (event.target.value.length > 0) {
        if(searchArray.length===0){
            setSchemaLoading(false);
            setTableColsLoading(false)
        }
      const newRows = searchArray?.filter((row) => {
        return row.label
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      newRows.unshift({ key: event.target.value, label: event.target.value })
      setSearchArray(newRows);
      setSelectedValue({ key: event.target.value, label: event.target.value });
    } else if (event.target.value.length === 0) {
      setSearchArray(searchArray);
      setSelectedValue(null);
    }
  };
  const handleSchemaChange = (item) => {
    item !== null && setSelectedSchema((prevState) => item);
    const tableKeys = schemaAndTableData&&schemaAndTableData.length>0 && Object.keys(schemaAndTableData[item.key]);
    let tempData = [];
    tableKeys.length>0&&tableKeys?.forEach((o, i) => {
      tempData.push({
        key: i++,
        label: o,
        rowProps:{
          'data-testid':o
         }
      });
    });
    setTableNameData((prevState) => tempData || []);
    setSearchTableNameData((prevState) => tempData || []);
  };
  return (
    <Modal
      visible={props.visible}
      title={
        (data?.schema ||
        schema?.label) + " ( " + (data?.schemaValue || schema?.value) + ")" ||
        "Schema"
      }
      onClose={props.handleOnClose}
      closeIconProps={{ "data-test": "my-close-icon-data-test" }}
      footer={false}
    >
      <StackingLayout padding="20px">
        <Form
          key={data?.index || data?.id || schema?.id}
          id={data?.id || schema?.id}
          validations={DEFAULT_VALIDATIONS}
          onSubmit={(values) => handleMongoOnSave(values, schemaMapping)}
          defaultValues={{
            id: data?.id || schema?.id || "",
            alias: data?.alias || "",
            schemaForm: data?.schemaForm || "",
            tableName: data?.tableName || "",
          }}
        >
          {(formHelper) => {
            return (
              <FormSection>
                <FormItemInput
                  id="id"
                  disabled
                  formHelper={formHelper}
                  label="Input ID"
                />
                {origin !== "Target" && (
                  <FormItemInput
                    data-testid = 'paas-etlCanvas-mongo-alias'
                    id="alias"
                    formHelper={formHelper}
                    label={"Alias"}
                  />
                )}
                <FormItemSelect
                  data-testid = 'paas-etlCanvas-mongo-schemaForm'
                  id="schemaForm"
                  selected={selectedSchema}
                  rowsData={searchSchemaData}
                  loading={schemaLoading}
                  formHelper={formHelper}
                  onSelectedChange={(item) => handleSchemaChange(item)}
                  label={"Schema"}
                  inputProps={{ value: selectedSchema?.label || "" }}
                  searchable={true}
                  onChange={(event) =>
                    handleOnInputValueChange(
                      event,
                      schemaData,
                      setSearchSchemaData,
                      setSelectedSchema
                    )
                  }
                />
                <FormItemSelect
                  data-testid = 'paas-etlCanvas-mongo-tableName'
                  id="tableName"
                  selected={selectedTableName}
                  showSearch={true}
                  rowsData={searchTableNameData}
                  formHelper={formHelper}
                  onSelectedChange={(item) => handleTableNameChange(item)}
                  label={"Collection Name"}
                  inputProps={{ value: selectedTableName?.label || "" }}
                  searchable={true}
                  onChange={(event) =>
                    handleOnInputValueChange(
                      event,
                      tableNameData,
                      setSearchTableNameData,
                      setSelectedTableName
                    )
                  }
                />
                <FormItemSelect
                  data-testid = 'paas-etlCanvas-mongo-offset_col'
                  id="offset_col"
                  rowsData={searchTableColsNameData}
                  loading={tableColsLoading}
                  formHelper={formHelper}
                  label={"Offset Column"}
                  helpText="Optional"
                  onSelectedChange={(item) => setSelectedOffsetCol(item)}
                  selected={selectedOffsetCol}
                  inputProps={{ value: selectedOffsetCol?.label || "" }}
                  searchable={true}
                  onChange={(event) =>
                    handleOnInputValueChange(
                      event,
                      tableColsNameData,
                      setSearchTableColsNameData,
                      setSelectedOffsetCol
                    )
                  }
                />
                {origin === "Source" && (
                  <StackingLayout>
                    <TextLabel
                      type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}
                      data-test-id="type-primary"
                    >
                      Schema Configuration
                    </TextLabel>
                    <FormItemInput
                      data-testid = 'paas-etlCanvas-mongo-schema'
                      id="schema"
                      label="Object Schema"
                      placeholder="Object Schema"
                      value={schemaMapping?.file_schema || ""}
                      onChange={(e) =>
                        setSchemaMapping({ file_schema: e.target.value })
                      }
                    />
                  </StackingLayout>
                )}

                <Button onClick={formHelper.handleSubmit} data-testid = 'paas-etlCanvas-mongo-save-btn'>Save</Button>
              </FormSection>
            );
          }}
        </Form>
      </StackingLayout>
    </Modal>
  );
}
