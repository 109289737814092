import React, { useEffect, useState } from "react";
import "./DashboardStatus.css";
import DashboardStatusCard from "./DashboardStatusCard/DashboardStatusCard";
import { useSelector } from "react-redux";
import { useStoryOverviewData } from "../apiUtils";
import { filterDataByDropdown } from "../utils";

const DashboardStatus = ({ filterSummary }) => {
  
  const {
    selectedComponent,
    selectedIssueType,
    selectedAssignee,
    selectedLabels,
    selectedSprint,
  } = useSelector((state) => state.filter);
  const { currentTeam } = useSelector((state) => state.team);
  const [storyOverView, setStoryOverView] = useState([])
  const { data: storyOverViewData, error, isError } = useStoryOverviewData(currentTeam?.project_id, selectedSprint?.[0])

  if(isError){
    throw error
  }
  
  const [counts, setCounts] = useState({
    totalJira: 0,
    carryOver: 0,
    newIssues: 0,
    missingStoryPoints: 0,
    storyPointsAssigned: 0,
    etaNotAssigned: 0,
    etaAssigned: 0,
    closed: 0,
    inProgress: 0,
  });

  const [sum, setSum] = useState({
    total_jira: 0,
    carry_over: 0,
    new: 0,
    missing_sp: 0,
    assigned_sp: 0,
    eta_missing: 0,
    eta_assigned: 0,
    closed: 0,
    in_progress: 0,
  });

  
  
  useEffect(() => {
    if (storyOverViewData && storyOverViewData?.story_overview && storyOverViewData?.story_overview.length > 0) {
      const filteredStoryOverview = filterDataByDropdown(
        storyOverViewData?.story_overview,
        selectedSprint,
        selectedAssignee,
        selectedComponent,
        selectedIssueType,
        selectedLabels
      );
      setStoryOverView(filteredStoryOverview)
    }else{
      setStoryOverView([])
    }
  }, [selectedAssignee, selectedComponent, selectedIssueType, selectedLabels, selectedSprint, storyOverViewData]);
  
  useEffect(()=>{
    if(storyOverView.length > 0){
      const category = "story_point_latest";
      const initialSum = {
        total_jira: 0,
        carry_over: 0,
        new: 0,
        missing_sp: 0,
        assigned_sp: 0,
        eta_missing: 0,
        eta_assigned: 0,
        closed: 0,
        in_progress: 0,
      };

      const sumTotal = storyOverView.reduce((acc, item) => {
        acc.total_jira += item[category];
        if (item.issue_category === "Carry Over") acc.carry_over += item[category];
        if (item.issue_category === "New") acc.new += item[category];
        if (item.story_point_assigned_yn_flag === "Y") acc.assigned_sp += item[category];
        if (item.story_point_assigned_yn_flag === "N") acc.missing_sp += item[category];
        if (item.eta_assigned_yn_flag === "Y") acc.eta_assigned += item[category];
        if (item.eta_assigned_yn_flag === "N") acc.eta_missing += item[category];
        if (item.grouped_jira_status === "Closed") acc.closed += item[category];
        if (item.grouped_jira_status === "Open") acc.in_progress += item[category];
        return acc;
      }, initialSum);

      setSum(sumTotal);
    }
  },[storyOverView])

  // Calculate the total values based on filteredSummary
  useEffect(() => {
    if (filterSummary) {
      const initialCounts = {
        totalJira: 0,
        carryOver: 0,
        newIssues: 0,
        missingStoryPoints: 0,
        storyPointsAssigned: 0,
        etaNotAssigned: 0,
        etaAssigned: 0,
        closed: 0,
        inProgress: 0,
      };

      const newCounts = filterSummary.reduce((acc, item) => {
        acc.totalJira += item["total_jira"];
        acc.carryOver += item["carry_over"];
        acc.newIssues += item["new"];
        acc.missingStoryPoints += item["missing_story_points"];
        acc.storyPointsAssigned += item["story_points_assigned"];
        acc.etaNotAssigned += item["eta_not_assigned"];
        acc.etaAssigned += item["eta_assigned"];
        acc.closed += item["closed"];
        acc.inProgress += item["open"];
        return acc;
      }, initialCounts);

      setCounts({
        totalJira: newCounts.totalJira.toLocaleString(),
        carryOver: newCounts.carryOver.toLocaleString(),
        newIssues: newCounts.newIssues.toLocaleString(),
        missingStoryPoints: newCounts.missingStoryPoints.toLocaleString(),
        storyPointsAssigned: newCounts.storyPointsAssigned.toLocaleString(),
        etaNotAssigned: newCounts.etaNotAssigned.toLocaleString(),
        etaAssigned: newCounts.etaAssigned.toLocaleString(),
        closed: newCounts.closed.toLocaleString(),
        inProgress: newCounts.inProgress.toLocaleString(),
      });
    }
  }, [filterSummary]);

  return (
      <div className="dashboardStatusCardContainer">
        <DashboardStatusCard
          storyOverView={storyOverView}
          level={1}
          doubleRow={true}
          count={counts.totalJira}
          label={"Total Jira"}
          title={"Total Jira"}
          countFontSize={35}
          labelFontSize={18}
          sumStory={sum.total_jira}
        />
        <DashboardStatusCard
          storyOverView={storyOverView.filter(item => item.issue_category === "Carry Over")}
          redText
          count={counts.carryOver}
          label={"Carry Over"}
          title={"Carry Over"}
          sumStory={sum.carry_over}
        />
        <DashboardStatusCard
          storyOverView={storyOverView.filter(item => item.story_point_assigned_yn_flag === "N")}
          count={counts.missingStoryPoints}
          label={"Missing Story Points"}
          title={"Missing SP"}
          sumStory={sum.missing_sp}
          redText
        />
        <DashboardStatusCard
          storyOverView={storyOverView.filter(item => item.eta_assigned_yn_flag === "N")}
          redText
          count={counts.etaNotAssigned}
          label={"ETA Not Assigned"}
          title={"ETA Missing"}
          sumStory={sum.eta_missing}
        />
        <DashboardStatusCard
          storyOverView={storyOverView.filter(item => item.grouped_jira_status === "Closed")}
          greenText
          count={counts.closed}
          label={"Closed"}
          title={"Closed"}
          sumStory={sum.closed}
        />
        <DashboardStatusCard
          storyOverView={storyOverView.filter(item => item.issue_category === "New")}
          greenText
          count={counts.newIssues}
          label={"New"}
          title={"New"}
          sumStory={sum.new}
        />
        <DashboardStatusCard
          storyOverView={storyOverView.filter(item => item.story_point_assigned_yn_flag === "Y")}
          count={counts.storyPointsAssigned}
          label={"Story Points Assigned"}
          title={"Assigned SP"}
          greenText
          sumStory={sum.assigned_sp}
        />
        <DashboardStatusCard
          storyOverView={storyOverView.filter(item => item.eta_assigned_yn_flag === "Y")}
          greenText
          count={counts.etaAssigned}
          label={"ETA Assigned"}
          title={"ETA Assigned"}
          sumStory={sum.eta_assigned}
        />
        <DashboardStatusCard
          storyOverView={storyOverView.filter(item => item.grouped_jira_status === "Open")}
          blueText
          count={counts.inProgress}
          label={"In Progress"}
          title={"In Progress"}
          sumStory={sum.in_progress}
        />
      </div>
  );
};

export default DashboardStatus;
