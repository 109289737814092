import React from "react";
import { Button, Dropdown, Pagination, Typography } from "antd";
import {
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import { setCurrentSelectionView, setCurrentSelectionViewBy } from "../../../../features/JiraAnalysis/checkBoxSlice";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeText, headerItems, headerItemsViewBy } from "../../utils";

const CalendarHeader = ({
  currentView,
  total,
  currentPage,
  setCurrentPage,
}) => {
  const dispatch = useDispatch();
  const { currentTeam } = useSelector((state) => state.team);
  const { currentSelectionViewBy } = useSelector((state) => state.checkbox);

  const handleOnClick = (e) => {
    const selectedItemKey = e.key;
    const selectedMenuItem = headerItems.find((item) => item.key === selectedItemKey);
    if (
      selectedMenuItem &&
      (selectedMenuItem.label === "Days" || selectedMenuItem.label === "Weekly")
    ) {
      // Call a function passed from the parent component to set the current selection view
      dispatch(setCurrentSelectionView(selectedMenuItem.label.toLowerCase()));
    }
  };

  const handleComponentChange = (e) => {
    const selectedItemKey = e.key;
    const selectedMenuItem = headerItemsViewBy.find((item) => item.key === selectedItemKey);
    if (
      selectedMenuItem &&
      (
        selectedMenuItem.label === "Component" || 
        selectedMenuItem.label === "Bussiness Name" ||
        selectedMenuItem.label === "Assignee Name"
      )
    ) {
      // Call a function passed from the parent component to set the current selection view
      dispatch(setCurrentSelectionViewBy(selectedMenuItem.label.toLowerCase()));
    }
  }
  return (
    <div className="table-title-container flex" data-testid="calendar-header">
      <div className="left-title-content">
        <Typography.Text className="headerTitle">
          Overview of ETA
        </Typography.Text>
      </div>
      <div className="right-title-content flex align-content-center" style={{ gap: '0px' }}>
        <div style={{ display: "flex", gap: "35px" }}>
          {currentTeam && currentTeam.project_id === 12530 && (
            <Dropdown
              menu={{
                items: headerItemsViewBy,
                selectable: true,
                onClick: handleComponentChange,
                disabled: currentTeam?.project_key_desc === 'No Team Assigned'
              }}
            >
              <Button className="filter-button day-view-dropdown">
                View By : {capitalizeText(currentSelectionViewBy)}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "5px",
                  }}
                >
                  <CaretUpOutlined style={{ fontSize: "8px" }} />
                  <CaretDownOutlined style={{ fontSize: "8px" }} />
                </div>
              </Button>
            </Dropdown>
          )}
          <Dropdown
            menu={{
              // Pass the items and click handler to the dropdown menu
              items: headerItems,
              selectable: true,
              onClick: handleOnClick,
              disabled: currentTeam?.project_key_desc === 'No Team Assigned'
            }}
          >
            <Button className="filter-button day-view-dropdown">
              {capitalizeText(currentView)}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "5px",
                }}
              >
                <CaretUpOutlined style={{ fontSize: "8px" }} />
                <CaretDownOutlined style={{ fontSize: "8px" }} />
              </div>
            </Button>
          </Dropdown>
        </div>
        {currentView === "days" && (
          <Pagination
            simple
            current={currentPage}
            total={total}
            pageSize={7}
            onChange={(page) => setCurrentPage(page)}
            className="calendar-pagination"
          />
        )}
      </div>
    </div>
  );
};

export default CalendarHeader;
