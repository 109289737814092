import { Table } from "antd";
import React from "react";
import "./DayView.css";
import { generateColumnsForDayView, generateDataSourceForDayView } from "../../../utils.js";
import { useSelector } from "react-redux";

const DayView = ({ currentWeekDates , title, uniqueComponent, generatedAssigneeData, uniqueAssignee, uniqueBusinessName, generatedBusinessData, generatedComponentData, footer }) => {
  const { currentTeam } = useSelector((state) => state.team);
  const { currentSelectionViewBy } = useSelector((state) => state.checkbox);
  const columns = generateColumnsForDayView(currentWeekDates, currentSelectionViewBy, currentTeam?.project_id)
  const dataSource = generateDataSourceForDayView(columns, uniqueComponent, generatedComponentData)
  const dataSourceAssignee = generateDataSourceForDayView(columns, uniqueAssignee, generatedAssigneeData)
  const dataSourceBusinessName = generateDataSourceForDayView(columns, uniqueBusinessName, generatedBusinessData)
  return (
    <>
      <Table
        className="jira-ssot-table"
        columns={columns}
        dataSource={
          currentTeam.project_id === 12530 ? 
          currentSelectionViewBy === 'component' ? dataSource : 
          currentSelectionViewBy === 'assignee name' ? dataSourceAssignee : 
          dataSourceBusinessName : dataSourceAssignee
        }
        size="small"
        title={title}
        footer={footer}
        pagination={false}
        scroll={{ y: 320 }}
      />
    </>
  );
};

export default DayView;
