// teamSlice.js
import { createSlice } from '@reduxjs/toolkit';

const teamSlice = createSlice({
  name: 'team',
  initialState: {
    currentTeam: {},
    currentViewBy: "",
    isRefreshed:false
  },
  reducers: {
    setCurrentTeam: (state, action) => {
      state.currentTeam = action.payload;
    },
    setCurrentViewBy: (state, action) => {
      state.currentViewBy = action.payload;
    },
    setRefreshed :(state, action) => {
      state.isRefreshed = action.payload;
    }
  },
});

export const { setCurrentTeam, setCurrentViewBy, setRefreshed } = teamSlice.actions;
export default teamSlice.reducer;
