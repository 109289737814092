import React from "react";
import "./DayView.css";
import { statusClassMap } from "../Helper/Helper";
import { Typography } from "antd";

const DayViewDetail = ({ title, description, status = false }) => {
  const statusClass = status ? statusClassMap[status] : "no-eta"; // Default to "completed" if status is undefined
  return (
    <div className="day-view-container" data-testid="day-view-container">
      <Typography.Link
        className={`day-view-title`}
        target="_blank"
        href={`https://jira.nutanix.com/browse/${title}`}
      >
        <p className={`day-view-title ${statusClass}`} data-testid="day-view-title">{title}</p>
      </Typography.Link>
      <p className="day-view-description" data-testid="day-view-description">{description}</p>
    </div>
  );
};

export default DayViewDetail;
