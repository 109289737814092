import { Typography } from "antd";
import moment from "moment";
import React from "react";
import Logger from "../../../../../config/logger";

const JiraTooltipHeader = ({ item }) => {
  Logger.info(`User clicked to retrieve information for Jira number: ${item.issuenum} at https://jira.nutanix.com/browse/${item.issuenum}.`, "jira_ssot_client", window.location.href);
  const parsedCreatedDate = moment(item.dt_created).utcOffset(-7); // Adjust for the given time offset
  const parsedUpdatedDate = moment(item.dt_updated).utcOffset(-7); // Adjust for the given time offset
  const formattedCreatedDate = parsedCreatedDate.format("DD MMM, ddd");
  const formattedUpdatedDate = parsedUpdatedDate.format("DD MMM, ddd");
  // const components = Array.isArray(item.component)
  //   ? item.component.join(", ")
  //   : item.component;
  return (
    <>
      <div className="tooltip-header">
      <div className="tooltip-items">
          <span className="tooltip-items-title">Ticket No.</span>
          <span className="tooltip-items-description" strong>
          : <Typography.Link
            className={`day-view-title tooltip-items-description`}
            target="_blank"
            href={`https://jira.nutanix.com/browse/${item.issuenum}`}
          >
            {item.issuenum}
          </Typography.Link>
          </span>
        </div>
        <div className="tooltip-items">
          <span className="tooltip-items-title">Created Date</span>
          <span className="tooltip-items-description" strong>
            : {formattedCreatedDate}
          </span>
        </div>
        <div className="tooltip-items">
          <span className="tooltip-items-title">Last Updated Date</span>
          <span className="tooltip-items-description" strong>
            : {formattedUpdatedDate}
          </span>
        </div>
        <div className="tooltip-items">
          <span className="tooltip-items-title">Last Updated By</span>
          <span className="tooltip-items-description" strong>
            : {item.last_commented_by ? item.last_commented_by : "NA"}
          </span>
        </div>
        <div className="tooltip-items">
          <span className="tooltip-items-title">Last Commented</span>
          <span className="tooltip-items-description" strong>
            : {item.latest_comments? item.latest_comments : "NA" }
          </span>
        </div>
        
      </div>
    </>
  );
};

export default JiraTooltipHeader;
