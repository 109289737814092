// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/NutanixSoft-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".labelCircle{\n    margin: 0px;\n    font-size: 11px;\n    width: 16px;\n    height: 16px;\n    display: grid;\n    place-items: center;\n    background: rgb(206, 206, 212);\n    color:\"grey\";\n    border-radius: 50%;\n}\n.ant-tabs-tab-active .labelCircle{\n   \n    background: #22A5F7;\n    \n    color:#ffffff;\n   \n}\n.imgCard .ant-card-body{\n    padding:0\n}\n@font-face {\n    font-family: \"Nutanix Soft\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");\n    font-weight: normal;\n    font-style: normal;\n  }", "",{"version":3,"sources":["webpack://./src/Components/NextGenETL/NextGenEtl.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,kBAAkB;AACtB;AACA;;IAEI,mBAAmB;;IAEnB,aAAa;;AAEjB;AACA;IACI;AACJ;AACA;IACI,2BAA2B;IAC3B,+DAAkE;IAClE,mBAAmB;IACnB,kBAAkB;EACpB","sourcesContent":[".labelCircle{\n    margin: 0px;\n    font-size: 11px;\n    width: 16px;\n    height: 16px;\n    display: grid;\n    place-items: center;\n    background: rgb(206, 206, 212);\n    color:\"grey\";\n    border-radius: 50%;\n}\n.ant-tabs-tab-active .labelCircle{\n   \n    background: #22A5F7;\n    \n    color:#ffffff;\n   \n}\n.imgCard .ant-card-body{\n    padding:0\n}\n@font-face {\n    font-family: \"Nutanix Soft\";\n    src: url(\"../../fonts/NutanixSoft-Regular.otf\") format(\"opentype\");\n    font-weight: normal;\n    font-style: normal;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
