import React, { useContext, useEffect, useState } from "react";
import "./dashboardHeader.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTeam,
  setCurrentViewBy,
} from "../../../features/JiraAnalysis/teamSlice";
import DropdownButton from "./DropdownButton";

import {
  setSelectedAssignee,
  setSelectedComponent,
  setSelectedIssueType,
  setSelectedLabels,
} from "../../../features/JiraAnalysis/filterSlice";
import { Button, Skeleton } from "antd";
// import EnggDashboardTab from "../EngineeringDashboard/Components/EnggDashboardTab";
import { trasformTeams } from "../utils";
import { useTeams } from "../apiUtils";
import UserContext from "../../../contexts/UserContext/UserContext";
import moment from "moment";
import { useLastRefreshed } from "../../EngineeringDashboard/EngineeringUtils";
import Logger from "../../../config/logger";

// DashboardHeader Component:
// This component represents the header of a dashboard that allows the user to select a team and a view option.
// It displays the current team and view options in dropdown buttons, and provides functionality to change them.
const DashboardHeader = ({ onRefresh }) => {
  const dispatch = useDispatch();
  const { data: lastRefreshed, isLoading: lastRefreshedLoading, isError:isLastRefreshedError,  error:lastRefreshedError} = useLastRefreshed();
  if (isLastRefreshedError) {
    throw lastRefreshedError; // This will be caught by the ErrorBoundary
  }
  const [lastUpdate, setLastUpdate] = useState(null);
  const { user } = useContext(UserContext);
  const [teams, setTeams] = useState([]);
  const { data, isLoading: teamLoading, isError: isTeamError,  error: teamError } = useTeams(user);
  if (isTeamError) {
    throw teamError; // This will be caught by the ErrorBoundary
  }
  const { currentTeam, currentViewBy } = useSelector((state) => state.team);

  const handleDropdownChange = (selectedItemKey, isTeam) => {
    if (isTeam) {
      const selectedItem = teams.find(
        (item) => item.key === selectedItemKey.key
      );
      dispatch(setCurrentTeam(selectedItem));
      dispatch(setSelectedAssignee(["All"]));
      dispatch(setSelectedComponent(["All"]));
      dispatch(setSelectedIssueType(["All"]));
      dispatch(setSelectedLabels(["All"]));
      dispatch(setCurrentViewBy(""));
    } else {
      const viewByItems =
        currentTeam.subitems &&
        currentTeam.subitems.find((item) => item.key === selectedItemKey.key);
      dispatch(setCurrentViewBy(viewByItems));
    }
  };

  useEffect(() => {
    if (!teamLoading) {
      const teams = trasformTeams(data?.teams || []); // Make sure to define transformTeams
      dispatch(setCurrentTeam(teams?.[0]));
      setTeams(teams);
    }
  }, [data, dispatch, teamLoading, setTeams]);

  useEffect(() => {
    if (!lastRefreshedLoading && lastRefreshed) {
      const lastUpdate = moment(lastRefreshed?.last_refreshed).format("DD MMM YYYY, hh:mm A");
      setLastUpdate(lastUpdate);
    }
  }, [lastRefreshed, lastRefreshedLoading]);

  return (
    <div className="dashboard-container">
      <div className="dashboard-left-container">
        {teamLoading ? (
          <Skeleton.Input active={false} size={"small"} />
        ) : (
          <h3 className="dashboard-label">{currentTeam?.project_key_desc}</h3>
        )}
      </div>

      <div className="dashboard-right-container">
        <span className="dashboard-recent-update">
          Last Update: {/* <Skeleton.Input active={true} size={'small'} /> */}
          {lastRefreshedLoading ? "Fetching..." : lastUpdate}
        </span>
        <Button type="link" onClick={onRefresh}>
          Refresh
        </Button>

        <div className="vertical-border"></div>
        <DropdownButton
          teamLoading={teamLoading}
          items={teams}
          current={currentTeam && currentTeam?.project_key_desc}
          disabled={currentTeam && currentTeam?.project_key_desc === "No Team Assigned"}
          onClick={(selectedItemKey) => {
            Logger.info(
              `user changed team to ${selectedItemKey}`,
              "jira_ssot_client",
              window.location.href
            );
            handleDropdownChange(selectedItemKey, true);
          }}
        />
        {Object.keys(currentTeam).length !== 0 && currentTeam.subitems && (
          <DropdownButton
            teamLoading={teamLoading}
            items={currentTeam?.subitems}
            current={currentViewBy ? currentViewBy.label : "View By"}
            onClick={(selectedItemKey) => {
              Logger.info(
                `user changed view by to ${selectedItemKey}`,
                "jira_ssot_client",
                window.location.href
              );
              handleDropdownChange(selectedItemKey, false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardHeader;
