import { Line, LineChart, ResponsiveContainer, XAxis, Tooltip } from '@nutanix-ui/recharts'
import React from 'react'
import CustomTooltip from "./CustomTooltip";
const ProductivityLineChart = ({data, height, width, margin}) => {
  return (
    <ResponsiveContainer width={width} height={height}
    >
      <LineChart
        data={data}
        margin={margin}
      >
        <XAxis dataKey="name" interval="preserveStartEnd" />
        <Line
          key="value"
          activeDot={true}
          // dot={true}
          type="linear"
          dataKey="value"
          stroke="#82ccfa"
        />
        <Tooltip content={<CustomTooltip />} />
      </LineChart>
    </ResponsiveContainer>
  )
}
export default ProductivityLineChart