import moment from "moment";
import MonthlyDetails from "../MonthlyView/MonthlyDetails";
import DayViewDetail from "../DayView/DayViewDetail";

/**
 * Generates table columns based on an array of dates, excluding Saturdays and Sundays.
 *
 * @param {moment[]} datesForCurrentPage - An array of moment objects representing dates.
 * @returns {Object[]} An array of column objects with title and dataIndex properties.
 */

/**
 * Calculates the dates for the current page based on selectedDate, currentPage, and itemsPerPage.
 *
 * @param {moment} selectedDate - The selected date representing the month.
 * @param {number} currentPage - The current page number.
 * @param {number} itemsPerPage - The number of items (dates) per page.
 * @returns {moment[]} An array of moment objects representing dates for the current page.
 */

export const statusClassMap = {
  "ETA Missed - Open": "missed",
  "ETA Missed - Completed": "missed-completed",
  Current: "current",
  Upcoming: "upcoming",
  Assigned: "assigned",
  Completed: "completed",
};

export function getCurrentMonthData(month) {
  const monthData = [];
  const startDate = moment(month).startOf("month");
  const endDate = moment(month).endOf("month");
  let currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(endDate, "day")) {
    const row = {
      key: currentDate.format("D"),
      Date: currentDate.format("DD MMM, ddd"), // Format date as "DD MMM, ddd" (e.g., "01 Oct, Tue")
      DayOfWeek: currentDate.format("dddd"), // Get the full weekday name (e.g., "Tuesday")
    };

    monthData.push(row);

    // Move to the next day
    currentDate.add(1, "day");
  }
  return monthData;
}
export function formatDate(inputDate) {
  const date = moment(inputDate, 'DD MMM, ddd');
  return date.format('YYYY-MM-DD');
}

// Function to format the data as desired
export function formatDataSource(data, filteredEta) {
  const formattedData = [];
  let currentWeek = null;
  let currentMonth = null;

  for (const item of data) {
    // Check if it's a new month
    const month = item.Date.split(" ")[1]; // Extract the month part
    if (month !== currentMonth) {
      // If it's a new month, push the current week into the formatted data
      if (currentWeek) {
        formattedData.push(currentWeek);
      }
      currentWeek = null;
      currentMonth = month;
    }

    // Start a new week when currentWeek is null
    if (!currentWeek) {
      currentWeek = { key: item.key };
    }

    // Assign data to the corresponding day
    const dayAbbreviation = item.DayOfWeek.substring(0, 3).toLowerCase();
    currentWeek[dayAbbreviation] = <MonthlyDetails date={item.Date} filteredEta={filteredEta} />;

    // If it's Friday, push the current week into the formatted data
    if (item.DayOfWeek === "Friday") {
      formattedData.push(currentWeek);
      currentWeek = null;
    }

    // Special case: If it's Monday and the week started in the previous month,
    // fill the missing days from the previous month
    if (item.DayOfWeek === "Monday" && currentMonth !== month) {
      const lastDate = moment(item.Date, "DD MMM, ddd").subtract(1, "days");
      const daysOfWeek = ["mon", "tue", "wed", "thur", "fri"];
      let lastDay = lastDate.format("DD MMM, ddd");
      for (const dayOfWeek of daysOfWeek) {
        lastDay = moment(lastDay, "DD MMM, ddd")
          .subtract(1, "days")
          .format("DD MMM, ddd");
        currentWeek[dayOfWeek] = <MonthlyDetails date={lastDay} filteredEta={filteredEta} />;
      }
    }
  }

  // Push the last week if it exists
  if (currentWeek) {
    formattedData.push(currentWeek);
  }
   // Return the formatted data
   return formattedData;
}