import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import React from "react";

const DropdownButton = ({ teamLoading, items, current, onClick, disabled=false }) => {
  return (
    <Dropdown
      menu={{
        items,
        selectable: true,
        onClick,
        disabled
      }}
    >
      <Button style={{display:'flex', alignItems:'center'}} loading={teamLoading}>
        {teamLoading ? "Loading..."  : current}
        <div style={{display:'flex', flexDirection:'column', marginLeft:'5px'}}>
        <CaretUpOutlined style={{fontSize:'8px'}} />
        <CaretDownOutlined style={{fontSize:'8px'}} />
        </div>
      </Button>
    </Dropdown>
  )
}

export default DropdownButton;
