import { useState, useEffect } from "react";
import React from "react";
import { Input, Button, notification, Card } from "antd";
import "./NextGenEtl.css";
import postgress from "../../images/postgres-logo.png";
import snowflake_logo from "../../images/Snowflake_logo.png";
import Mysqllogo_log from "../../images/Mysqllogo.png";
import AmazonS3_logo from "../../images/s3-logo.png";
import MongoDB_logo from "../../images/mongoDb.png";
import Dremio_logo from "../../images/dremio-logo.png";
import axios from "axios";
import { SERVER_URL } from "../../config/config";
import SourceTable from "./SourceTable";
import { useNextGenCreateProject } from "../../hooks";
import { apiResponseError } from "../../config/helper";
import { Alert } from "@nutanix-ui/prism-reactjs";
import { FormItemSelect } from "@nutanix-ui/prism-reactjs";

const Source = (props) => {
  const {  onCancel, onNext } = { ...props };
  const [newDataSourceModalEnable, setNewDataSourceModalEnable] =
    useState(false);
  const [sourceTableEnable, setSourceTableEnable] = useState(false);
  const [saveButtonEnable, setSaveButtonEnable] = useState(false);
  const [sourceName, setSourceName] = useState("");
  const [hostName, setHostName] = useState("");
  const [port, setPort] = useState("");
  const [snowflake, setSnowflake] = useState("");
  const [postgres, setPostgres] = useState("");
  const [mysql, setMySql] = useState("");
  const [mongo, setMongo] = useState("");
  const [dremio, setDremio] = useState("");
  const [databaseName, setDatabaseName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [amaZonS3, setAmazonS3] = useState("");
  const [sslMode, setSslMode] = useState({ key: "disable", label: "disable" });
  const [schema, setSchema] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [bucketNmae, setBucketName] = useState("");
  const [accessKey, setAccessKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [editForm, setEditForm] = useState(false);// eslint-disable-next-line 
  const [databaseProperty, setDatabaseProperty] = useState("");
  const [editValue, setEditValue] = useState({});
  const [apiProgress, setApiProgress] = useState({
    loading: false,
    ready: false,
    error: false,
  });
  const [supportedSourcesApiProgress, setSupportedSourcesApiProgress] =
    useState({ loading: false, ready: false, error: false });
  const [addSourceApiProgress, setAddSourceApiProgress] = useState({
    loading: false,
    ready: false,
    error: false,
  });
  const [message, setMessage] = useState("");
  const [nextButtonEnable, setNextButtonEnable] = useState(false);
  const { projectDetails, setProjectDetails } = useNextGenCreateProject();
  const newDataSourceModal = (databaseType) => {
    setApiProgress({ loading: false, ready: false, error: false });
    setSupportedSourcesApiProgress({
      loading: false,
      ready: false,
      error: false,
    });
    setAddSourceApiProgress({ loading: false, ready: false, error: false });
    setNewDataSourceModalEnable(true);
    if(databaseType === 'mongo' || databaseType === 'dremio'){ 
      setSaveButtonEnable(true);
    }
    setDatabaseProperty("");
    setPassword("");
    setUserName("");
    setWarehouse("");
    setSchema("");
    setDatabaseName("");
    setPort("");
    setHostName("");
    setSourceName("");
    setMessage("");
    setBucketName("");
    setAccessKey("");
    setSecretKey("");
    setProjectDetails((current) => ({
      ...current,
      selectedDatabase: databaseType,
    }));
  };
  const sslmodeData = [
    { key: "require", label: "require" },
    { key: "disable", label: "disable" },
  ];
  const getSupportedSources = async () => {
    try {
      setSupportedSourcesApiProgress({
        loading: true,
        ready: false,
        error: false,
      });
      setMessage("");
      const data = await axios.get(`${SERVER_URL}/getSupportedSourcesModel`);
      if (data?.data[200].length>0 &&data?.data[200][0].length>0&& data?.data[200][0][1] === "Snowflake") {
        setSnowflake("snowflake");
      }
      if (data?.data[200].length>0 &&data?.data[200][1].length>0&& data?.data[200][1][1] === "Postgres") {
        setPostgres("Postgres");
      }
      if (data?.data[200].length>0 &&data?.data[200][2].length>0&& data?.data[200][2][1] === "Amazon S3") {
        setAmazonS3("s3");
      }
      if (data?.data[200].length>0 &&data?.data[200][3].length>0&& data?.data[200][3][1] === "MySQL") {
        setMySql("mysql");
      }
      if (data?.data[200].length>0 &&data?.data[200][3].length>0&& data?.data[200][4][1] === "mongo") {
        setMongo("mongo");
      }
      if (data?.data[200].length>0 &&data?.data[200][3].length>0&& data?.data[200][5][1] === "Dremio") {
        setDremio("dremio");
      }
      
      setSupportedSourcesApiProgress({
        loading: false,
        ready: true,
        error: false,
      });
    } catch (e) {
      setSupportedSourcesApiProgress({
        loading: false,
        ready: false,
        error: apiResponseError(e),
      });
    }
  };
  const fetchDataSourceModal = async () => {
    let source_input = { project_id: projectDetails?.project_id };
    const source_data = await axios.post(
      `${SERVER_URL}/listDataSourceModel`,
      source_input
    );
    setEditForm(false);
    setNewDataSourceModalEnable(false);
    setSourceTableEnable(true);
    setSaveButtonEnable(false);

    let source_details = source_data?.data?.data?.map((e, index) => {
      return {
        id: index,
        sourceName: e[0] || '',
        hostName: e[3] ? e[3]?.host :'',
        port:  e[3] ? e[3]?.port : '',
        databaseName: e[3]? e[3]?.database : '',
        userName: userName,
        password: password,
        databaseProperty: e[1] ?e[1] :'',
      };
    });
    if (source_details?.length > 0) {
      setNextButtonEnable(true);
    }
    setProjectDetails((current) => ({
      ...current,
      sourceDetails: source_details,
    }));
  };
  useEffect(() => {
    fetchDataSourceModal();
    getSupportedSources();// eslint-disable-next-line
  }, []);
  const handleModalCancel = () => {
    setNewDataSourceModalEnable(false);
  };
  const handleEditCancel = () => {
    setEditForm(false);
  };
  const editTestConnection = () => {
    setSaveButtonEnable(true);
    notification.success({
      message: "Connection successful.",
      duration: 10,
    });
  };
  const testConnection = async (e) => {
    try {
      setApiProgress({
        loading: true,
        ready: false,
        error: false,
        info: true,
        msg: "Test Source Request has been Submitted",
      });
      setSupportedSourcesApiProgress({
        loading: false,
        ready: false,
        error: false,
      });
      setAddSourceApiProgress({ loading: false, ready: false, error: false });
      setMessage("");
      if (projectDetails?.selectedDatabase === "snowflake") {
        let test_source_details_input = {
          project_id: projectDetails?.project_id,
          conn_name: sourceName,
          src_type: projectDetails?.selectedDatabase,
          conn_details: JSON.stringify({
            system_id: projectDetails?.selectedDatabase,
            host: hostName,
            port: "",
            schema: schema,
            database: databaseName,
            user: userName,
            password: password,
            role: "",
            warehouse: warehouse,
          }),
          label: "snowflake text conn",
        };
        const data = await axios.post(
          `${SERVER_URL}/testDataSourceModel`,
          test_source_details_input
        );
        if (data?.data?.is_active === true) {
          notification.success({
            message: "Connection Successful.",
            placement: "top",
            duration: 10,
          });
          setSaveButtonEnable(true);
        } else if (data?.data?.is_active === false) {
          if (data?.data?.jira_no === "") {
            notification.error({
              message: "Invalid Credtentials",
              placement: "top",
              duration: 10,
            });
            // setSaveButtonEnable(true);
          } else {
            notification.error({
              message: (
                <p>
                  Connectivity Issue ! Please Contact PaaS Admin :{" "}
                  <a rel="noreferrer"
                    target="_blank"
                    href={`https://jira.nutanix.com/browse/${data?.data?.jira_no}`}
                  >
                    {data?.data?.jira_no}
                  </a>
                </p>
              ),
              placement: "top",
              duration: 60,
            });
            // setSaveButtonEnable(true);
          }
        } else {
          setMessage(data?.data?.msg);
        }
      } else if (projectDetails?.selectedDatabase === "s3") {
        let test_s3_source_details_input = {
          project_id: projectDetails?.project_id,
          conn_name: sourceName,
          src_type: projectDetails?.selectedDatabase,
          conn_details: JSON.stringify({
            system_id: projectDetails?.selectedDatabase,
            bucket: bucketNmae,
            access_key: accessKey,
            secret_key: secretKey,
          }),
          label: "s3 test connection",
        };
        const data = await axios.post(
          `${SERVER_URL}/testDataSourceModel`,
          test_s3_source_details_input
        );
        if (data?.data?.is_active === false) {
          if (data?.data?.jira_no === "") {
            notification.error({
              message: "Invalid Credtentials",
              placement: "top",
              duration: 10,
            });
            // setSaveButtonEnable(true);
          } else {
            notification.error({
              message: (
                <p>
                  Connectivity Issue ! Please Contact PaaS Admin :{" "}
                  <a rel="noreferrer"
                    target="_blank"
                    href={`https://jira.nutanix.com/browse/${data.data.jira_no}`}
                  >
                    {data?.data?.jira_no}
                  </a>
                </p>
              ),
              placement: "top",
              duration: 60,
            });
            // setSaveButtonEnable(true);
          }
        } else if (data?.data?.is_active === true) {
          notification.success({
            message: "Connection Successful.",
            placement: "top",
            duration: 10,
          });
          setSaveButtonEnable(true);
        }
      } else {
        let test_source_details_input;
        if (projectDetails?.selectedDatabase === "postgres") {
          test_source_details_input = {
            project_id: projectDetails?.project_id,
            conn_name: sourceName,
            src_type: projectDetails?.selectedDatabase,
            sslmode: sslMode.label,
            conn_details: JSON.stringify({
              system_id: projectDetails?.selectedDatabase,
              host: hostName,
              port: port,
              database: databaseName,
              user: userName,
              password: password,
              role: "",
              sslmode: sslMode.label
            }),
            label: "PG test conn",
          };
        } else {
          test_source_details_input = {
            project_id: projectDetails?.project_id,
            conn_name: sourceName,
            src_type: projectDetails?.selectedDatabase,
            conn_details: JSON.stringify({
              system_id: projectDetails?.selectedDatabase,
              host: hostName,
              port: port || '',
              database: databaseName,
              user: userName,
              password: password,
              role: "",
            }),
            label: projectDetails?.selectedDatabase === "mysql"
                ? "mysql text conn"
                : "mongo text conn",
          };
        }
        const data = await axios.post(
          `${SERVER_URL}/testDataSourceModel`,
          test_source_details_input
        );
        if (data?.data?.is_active === false) {
          if (data?.data?.jira_no === "") {
            notification.error({
              message: "Invalid Credtentials",
              placement: "top",
              duration: 10,
            });
            // setSaveButtonEnable(true);
          } else {
            notification.error({
              message: (
                <p>
                  Connectivity Issue ! Please Contact PaaS Admin :{" "}
                  <a rel="noreferrer"
                    target="_blank"
                    href={`https://jira.nutanix.com/browse/${data?.data?.jira_no}`}
                  >
                    {data?.data?.jira_no}
                  </a>
                </p>
              ),
              placement: "top",
              duration: 60,
            });
            // setSaveButtonEnable(true);
          }
        } else if (data?.data?.is_active === true) {
          notification.success({
            message: "Connection Successful.",
            placement: "top",
            duration: 10,
          });
          setSaveButtonEnable(true);
        } else {
          setMessage(data?.data?.msg);
        }
      }
      setApiProgress({ loading: false, ready: true, error: false });
    } catch (e) {
      setApiProgress({
        loading: false,
        ready: false,
        error: apiResponseError(e),
      });
    }
  };
  const handleEditSave = () => {
    setEditForm(false);
    setSourceTableEnable(true);
    setNewDataSourceModalEnable(false);
    setSaveButtonEnable(false);
    setProjectDetails((current) => ({
      ...current,
      sourceDetails: current.sourceDetails.map((e) => {
        if (e.id === editValue.id) {
          return editValue;
        }
        return e;
      }),
    }));
  };
  const addSourceDetails = async (e) => {
    try {
      setApiProgress({
        loading: false,
        ready: false,
        error: false,
        info: true,
        msg: "Add Source Request has been Submitted",
      });
      setSupportedSourcesApiProgress({
        loading: false,
        ready: false,
        error: false,
      });
      setAddSourceApiProgress({ loading: true, ready: false, error: false });
      setMessage("");
      if (projectDetails?.selectedDatabase === "snowflake") {
        let source_details_input = {
          project_id: projectDetails?.project_id,
          conn_name: sourceName,
          src_type: projectDetails?.selectedDatabase,
          conn_details: JSON.stringify({
            system_id: projectDetails?.selectedDatabase,
            host: hostName,
            schema: schema,
            port: "",
            database: databaseName,
            user: userName,
            password: password,
            role: "",
            warehouse: warehouse,
          }),
          label: "snowflake text conn",
        };
        const data = await axios.post(
          `${SERVER_URL}/addDataSourceModel`,
          source_details_input
        );
        setMessage(data?.data?.msg);
      } else if (projectDetails?.selectedDatabase === "s3") {
        let test_s3_source_details_input = {
          project_id: projectDetails?.project_id,
          conn_name: sourceName,
          src_type: projectDetails?.selectedDatabase,
          conn_details: JSON.stringify({
            system_id: projectDetails?.selectedDatabase,
            bucket: bucketNmae,
            access_key: accessKey,
            secret_key: secretKey,
          }),
          label: "s3 test connection",
        };
        const data = await axios.post(
          `${SERVER_URL}/addDataSourceModel`,
          test_s3_source_details_input
        );
        if (data.data.is_active === false) {
          if (data.data.jira_no === "") {
            notification.error({
              message: "Invalid Credtentials",
              placement: "top",
              duration: 10,
            });
            setSaveButtonEnable(true);
          } else {
            notification.error({
              message: (
                <p>
                  Connectivity Issue ! Please Contact PaaS Admin :{" "}
                  <a rel="noreferrer"
                    target="_blank"
                    href={`https://jira.nutanix.com/browse/${data?.data?.jira_no}`}
                  >
                    {data?.data?.jira_no}
                  </a>
                </p>
              ),
              placement: "top",
              duration: 60,
            });
            setSaveButtonEnable(true);
          }
        } else if (data?.data?.is_active === true) {
          notification.success({
            message: "Connection Successful.",
            placement: "top",
            duration: 10,
          });
          setSaveButtonEnable(true);
        }
      } else {
        let source_details_input;
        if (projectDetails?.selectedDatabase === "postgres") {
          source_details_input = {
            project_id: projectDetails?.project_id,
            conn_name: sourceName,
            src_type: projectDetails?.selectedDatabase,
            sslmode: sslMode.label,
            conn_details: JSON.stringify({
              system_id: projectDetails?.selectedDatabase,
              host: hostName,
              port: port,
              database: databaseName,
              user: userName,
              password: password,
              role: "",
              sslmode: sslMode.label,
            }),
            label: "PG text conn"
          };
        } else {
          source_details_input = {
            project_id: projectDetails?.project_id,
            conn_name: sourceName || '',
            src_type: projectDetails?.selectedDatabase,
            conn_details: JSON.stringify({
              system_id: projectDetails?.selectedDatabase,
              host: hostName,
              port: port || '',
              database: databaseName,
              user: userName,
              password: password,
              role: "",
            }),
            label: projectDetails?.selectedDatabase === "mysql"
                ? "mysql text conn"
                : "mongo text conn",
          };
        }

        const data = await axios.post(
          `${SERVER_URL}/addDataSourceModel`,
          source_details_input
        );
        setMessage(data?.data?.msg);
      }
      setAddSourceApiProgress({ loading: false, ready: true, error: false });
      setNextButtonEnable(true);
    } catch (e) {
      setAddSourceApiProgress({ loading: false, ready: false, error: true });
      const jiraNumber = apiResponseError(e, true).jira_no;
      if (jiraNumber) {
        let jiraMessage = (
          <span>
            <span>Test Connection Failed. Jira </span>

            <a rel="noreferrer"
              href={`https://jira.nutanix.com/browse/${jiraNumber}`}
              target="_blank"
            >
              {/* {key} */}
              {jiraNumber}
            </a>
            <span> created to resolve the connectivity issue.</span>
          </span>
        );
        setMessage(jiraMessage);
      } else {
        setMessage(apiResponseError(e));
      }
      throw e;
    }
  };

  const handleSave = async (e) => {
    try {
      await addSourceDetails();
      let datasource_input = { project_id: projectDetails?.project_id };
      const data = await axios.post(
        `${SERVER_URL}/listDataSourceModel`,
        datasource_input
      );
      let sourceDataApiResponse = data?.data?.data;
      setEditForm(false);
      setNewDataSourceModalEnable(false);
      setSourceTableEnable(true);
      setSaveButtonEnable(false);
      let source_details = sourceDataApiResponse.map((e, index) => {
        return {
          id: index,
          sourceName: e[0] || '',
          hostName: e[3] ? e[3]?.host : '',
          port: e[3] ? e[3]?.port : '',
          databaseName: e[3] ? e[3]?.database : '',
          userName: userName,
          password: password,
          databaseProperty: e[1] || '',
        };
      });
      setProjectDetails((current) => ({
        ...current,
        sourceDetails: source_details,
      }));
    } catch (e) {}
  };
  const handleEditValueChange = (event, value) => {
    setEditValue((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const handleValueChange = (event, value) => {
    if (event.target.name === "sourceName") {
      setSourceName(event.target.value);
    }
    if (event.target.name === "hostName") {
      setHostName(event.target.value);
    }
    if (event.target.name === "port") {
      setPort(event.target.value);
    }
    if (event.target.name === "databaseName") {
      setDatabaseName(event.target.value);
    }
    if (event.target.name === "userName") {
      setUserName(event.target.value);
    }
    if (event.target.name === "password") {
      setPassword(event.target.value);
    }
    if (event.target.name === "warehouse") {
      setWarehouse(event.target.value);
    }
    if (event.target.name === "schema") {
      setSchema(event.target.value);
    }
    if (event.target.name === "databaseProperty") {
      setDatabaseProperty(event.target.value);
    }
    if (event.target.name === "bucketName") {
      setBucketName(event.target.value);
    }
    if (event.target.name === "accessKey") {
      setAccessKey(event.target.value);
    }
    if (event.target.name === "secretKey") {
      setSecretKey(event.target.value);
    }
  };
  const handleEdit = (record) => {
    setEditValue(record);
    setEditForm(true);
  };
  return (
    <div style={{ width: "80%", margin: "auto" }}>
      {addSourceApiProgress?.ready && message && (
        <>
          <Alert message={message} type="success" closable />
          <br />
        </>
      )}
      {supportedSourcesApiProgress?.error && (
        <>
          <Alert
            message={supportedSourcesApiProgress?.error}
            type="error"
            closable
          />
          <br />
        </>
      )}
      {apiProgress?.info && (
        <>
          <Alert message={apiProgress?.msg} type="info" closable />
          <br />
        </>
      )}
      {sourceTableEnable && !newDataSourceModalEnable && !editForm && (
        <>
          {" "}
          <SourceTable onEdit={handleEdit} />{" "}
        </>
      )}
      {!newDataSourceModalEnable && !editForm && (
        <>
          {" "}
          <Alert
            message="Add your sources here. You can update them any time later!"
            type="info"
            showIcon
          />
          <br />
          <p
            style={{
              margin: 0,
              marginTop: "12px",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            Cloud Databases (1)
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 16,
              marginTop: "13px",
            }}
          >
            {snowflake && (
              <Card data-testid = 'paas-createProject-addSourceModal-snowflake-img'
                style={{
                  display: "block",
                  width: "119px",
                  minHeight: "18px",
                }}
                className={"imgCard"}
              >
                <img alt=''
                  src={snowflake_logo}
                  style={{ width: "117px", height: "77px" }}
                  onClick={() => newDataSourceModal("snowflake")}
                />
              </Card>
            )}
          </div>
          <p
            style={{
              margin: 0,
              marginTop: "12px",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            Databases (2)
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 16,
              marginTop: "13px",
            }}
          >
            {postgres && (
              <Card data-testid = 'paas-createProject-addSourceModal-postgres-img'
                style={{
                  display: "block",
                  width: "119px",
                  minHeight: "18px",
                }}
                className={"imgCard"}
              >
                <img alt=''
                  src={postgress}
                  style={{ width: "117px", height: "77px" }}
                  onClick={() => newDataSourceModal("postgres")}
                />
              </Card>
            )}
            {mysql && (
              <Card data-testid = 'paas-createProject-addSourceModal-mysql-img'
                style={{
                  display: "block",
                  width: "119px",
                  minHeight: "18px",
                }}
                className={"imgCard"}
              >
                <img alt=''
                  src={Mysqllogo_log}
                  style={{ width: "117px", height: "77px" }}
                  onClick={() => newDataSourceModal("mysql")}
                />
              </Card>
            )}
          </div>
          <p
            style={{
              margin: 0,
              marginTop: "12px",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            Cloud Storage (1)
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 16,
              marginTop: "13px",
            }}
          >
            {amaZonS3 && (
              <Card data-testid = 'paas-createProject-addSourceModal-s3-img'
                style={{
                  display: "block",
                  width: "119px",
                  minHeight: "18px",
                }}
                className={"imgCard"}
              >
                <img alt=''
                  src={AmazonS3_logo}
                  style={{ width: "117px", height: "77px" }}
                  onClick={() => newDataSourceModal("s3")}
                />
              </Card>
            )}
          </div>
          <p
            style={{
              margin: 0,
              marginTop: "12px",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            Nosql Database (1)
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 16,
              marginTop: "13px",
            }}
          >
            {mongo && (
              <Card data-testid = 'paas-createProject-addSourceModal-mongo-img'
                style={{
                  display: "block",
                  width: "119px",
                  minHeight: "18px",
                }}
                className={"imgCard"}
              >
                <img alt=''
                  src={MongoDB_logo}
                  style={{ width: "117px", height: "77px" }}
                  onClick={() => newDataSourceModal("mongo")}
                />
              </Card>
            )}
          </div>
          <p
            style={{
              margin: 0,
              marginTop: "12px",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            Lake House (1)
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 16,
              marginTop: "13px",
            }}
          >
            {dremio && (
              <Card data-testid = 'paas-createProject-addSourceModal-mongo-img'
                style={{
                  display: "block",
                  width: "119px",
                  minHeight: "18px",
                }}
                className={"imgCard"}
              >
                <img alt=''
                  src={Dremio_logo}
                  style={{ width: "117px", height: "77px" }}
                  onClick={() => newDataSourceModal("dremio")}
                />
              </Card>
            )}
          </div>
          <div
            style={{
              height: "1px",
              width: "803px",
              background: "#e4dddd",
              marginLeft: "-100px",
              marginTop: "52px",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              float: 'right',
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
                gap: "16px",
                marginRight: "-54px",
                marginTop: "-12px",
              }}
            >
              <Button data-testid = 'paas-createProject-addSourceModal-cancel-btn' onClick={onCancel}> Cancel</Button>
              <Button data-testid = 'paas-createProject-addSourceModal-next-btn' 
                type="primary"
                key="modalDismissButton"
                disabled={!nextButtonEnable}
                onClick={onNext}
              >
                {" "}
                Next
              </Button>
            </div>
          </div>
        </>
      )}
      {newDataSourceModalEnable && (
        <div>
          {apiProgress?.error && (
            <>
              <Alert message={apiProgress?.error} type="error" closable />
              <br />
            </>
          )}
          {addSourceApiProgress?.error && message && (
            <>
              <Alert message={message} type="error" closable />
              <br />
            </>
          )}
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
           {` Add ${projectDetails?.selectedDatabase} Source Details`}
          </h3>
          <br />
          <form className="about-feedback">
            <div className="about-form-content">
              <p style={{ color: "grey", fontSize: "13px" }}>Name:</p>
              <Input data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-sourceName`}
                name="sourceName"
                value={sourceName}
                style={{ width: "100%" }}
                onChange={handleValueChange}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
              {projectDetails?.selectedDatabase !== "s3" && (
                <div className="about-form-content" style={{ flex: 1 }}>
                  <p style={{ color: "grey", fontSize: "13px" }}>Host Name:</p>
                  <Input data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-sourceName`}
                    name="hostName"
                    value={hostName}
                    style={{ width: "100%" }}
                    onChange={handleValueChange}
                  />
                </div>
              )}
              {projectDetails?.selectedDatabase === "s3" && (
                <div className="about-form-content" style={{ flex: 1 }}>
                  <p style={{ color: "grey", fontSize: "13px" }}>
                    Bucket Name:
                  </p>
                  <Input
                   data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-bucketName`}
                    name="bucketName"
                    value={bucketNmae}
                    style={{ width: "100%" }}
                    onChange={handleValueChange}
                  />
                </div>
              )}
              {projectDetails?.selectedDatabase !== "snowflake" &&
                projectDetails?.selectedDatabase !== "s3" && (
                  <div className="about-form-content" style={{ flex: 1 }}>
                    <p style={{ color: "grey", fontSize: "13px" }}>Port:</p>
                    <Input
                     data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-port`}
                      name="port"
                      value={port}
                      style={{ width: "100%" }}
                      onChange={handleValueChange}
                    />
                  </div>
                )}
            </div>
            {projectDetails?.selectedDatabase !== "s3" && (
              <div className="about-form-content">
                <p style={{ color: "grey", fontSize: "13px" }}>
                  Database Name:
                </p>
                <Input
                  data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-databaseName`}
                  name="databaseName"
                  value={databaseName}
                  style={{ width: "100%" }}
                  onChange={handleValueChange}
                />
              </div>
            )}
            {projectDetails?.selectedDatabase === "s3" && (
              <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                <div className="about-form-content" style={{ flex: 1 }}>
                  <p style={{ color: "grey", fontSize: "13px" }}>Access Key:</p>
                  <Input
                    data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-accessKey`}
                    name="accessKey"
                    value={accessKey}
                    style={{ width: "100%" }}
                    onChange={handleValueChange}
                  />
                </div>
                <div className="about-form-content" style={{ flex: 1 }}>
                  <p style={{ color: "grey", fontSize: "13px" }}>Secret Key:</p>
                  <Input
                    data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-secretKey`}
                    type="password"
                    name="secretKey"
                    value={secretKey}
                    style={{ width: "100%" }}
                    onChange={handleValueChange}
                  />
                </div>
              </div>
            )}
            {projectDetails?.selectedDatabase !== "s3" && (
              <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                <div className="about-form-content" style={{ flex: 1 }}>
                  <p style={{ color: "grey", fontSize: "13px" }}>User Name:</p>
                  <Input
                    data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-userName`}
                    name="userName"
                    value={userName}
                    style={{ width: "100%" }}
                    onChange={handleValueChange}
                  />
                </div>
                <div className="about-form-content" style={{ flex: 1 }}>
                  <p style={{ color: "grey", fontSize: "13px" }}>Password:</p>
                  <Input
                    data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-password`}
                    type="password"
                    name="password"
                    value={password}
                    style={{ width: "100%" }}
                    onChange={handleValueChange}
                  />
                </div>
              </div>
            )}
            {(projectDetails?.selectedDatabase === "postgres" || projectDetails?.selectedDatabase === "dremio" )&& (
              <>
                <div>
                  <FormItemSelect
                    data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-sslmode`}
                    id="sslmode"
                    selectedRow={sslMode}
                    rowsData={sslmodeData}
                    onSelectedChange={(item) => setSslMode(item)}
                    label={"Ssl Mode"}
                  />
                </div>
              </>
            )}
            {projectDetails?.selectedDatabase === "snowflake" && (
              <>
                <div className="about-form-content">
                  <p style={{ color: "grey", fontSize: "13px" }}>Schema:</p>
                  <Input
                    data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-schema`}
                    name="schema"
                    value={schema}
                    style={{ width: "100%" }}
                    onChange={handleValueChange}
                  />
                </div>
                <div className="about-form-content">
                  <p style={{ color: "grey", fontSize: "13px" }}>Warehouse:</p>
                  <Input
                    data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-warehouse`}
                    name="warehouse"
                    value={warehouse}
                    style={{ width: "100%" }}
                    onChange={handleValueChange}
                  />
                </div>
              </>
            )}
            <br />
            <div
              style={{
                height: "1px",
                width: "803px",
                background: "#e4dddd",
                marginLeft: "-100px",
                marginTop: "-30px",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
                gap: 16,
                marginRight: "-54px",
                marginTop: "-12px",
              }}
            >
              <Button onClick={handleModalCancel} data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-cancel-btn`}> Cancel</Button>
              <Button key="modalOkButton" onClick={testConnection} data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-testConnection-btn`}>
                {" "}
                Test Connection
              </Button>
              <Button
              data-testid = {` paas-etlCanvas- ${projectDetails?.selectedDatabase}-sourceForm-saveConnection-btn`}
                type="primary"
                key="modalDismissButton"
                disabled={!saveButtonEnable}
                onClick={handleSave}
              >
                {" "}
                Save
              </Button>
            </div>
          </form>
        </div>
      )}

      {editForm && (
        <div>
          <h3 style={{ fontSize: "16px", fontWeight: 500 }}>
            Edit Source Details
          </h3>
          <br />
          <form className="about-feedback">
            <div className="about-form-content">
              <p style={{ color: "grey", fontSize: "13px" }}>Name:</p>
              <Input
                name="sourceName"
                style={{ width: "100%" }}
                value={editValue?.sourceName}
                onChange={handleEditValueChange}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
              <div className="about-form-content" style={{ flex: 1 }}>
                <p style={{ color: "grey", fontSize: "13px" }}>Host Name:</p>
                <Input
                  name="hostName"
                  style={{ width: "100%" }}
                  value={editValue?.hostName}
                  onChange={handleEditValueChange}
                />
              </div>
              <div className="about-form-content" style={{ flex: 1 }}>
                <p style={{ color: "grey", fontSize: "13px" }}>Port:</p>
                <Input
                  name="port"
                  style={{ width: "100%" }}
                  value={editValue?.port}
                  onChange={handleEditValueChange}
                />
              </div>
            </div>
            <div className="about-form-content">
              <p style={{ color: "grey", fontSize: "13px" }}>Database Name:</p>
              <Input
                name="databaseName"
                style={{ width: "100%" }}
                value={editValue?.databaseName}
                onChange={handleEditValueChange}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
              <div className="about-form-content" style={{ flex: 1 }}>
                <p style={{ color: "grey", fontSize: "13px" }}>User Name:</p>
                <Input
                  name="userName"
                  style={{ width: "100%" }}
                  value={editValue?.userName}
                  onChange={handleEditValueChange}
                />
              </div>
              <div className="about-form-content" style={{ flex: 1 }}>
                <p style={{ color: "grey", fontSize: "13px" }}>Password:</p>
                <Input
                  type="password"
                  name="password"
                  style={{ width: "100%" }}
                  value={editValue?.password}
                  onChange={handleEditValueChange}
                />
              </div>
            </div>
            <br />

            <div
              style={{
                height: "1px",
                width: "803px",
                background: "#e4dddd",
                marginLeft: "-100px",
                marginTop: "-30px",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
                gap: 16,
                marginRight: "-54px",
                marginTop: "-12px",
              }}
            >
              <Button onClick={handleEditCancel}> Cancel</Button>
              <Button key="modalOkButton" onClick={editTestConnection}>
                {" "}
                Test Connection
              </Button>
              <Button
                type="primary"
                key="modalDismissButton"
                disabled={!saveButtonEnable}
                onClick={handleEditSave}
              >
                {" "}
                Update
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Source;
