import moment from "moment";
import { setSelectedSprint } from "../../features/JiraAnalysis/filterSlice";
import WeeklyDataComponent from "./DashboardCalendar/CalendarView/WeeklyView/WeeklyDataComponent";
import DayDataComponent from "./DashboardCalendar/CalendarView/DayView/DayDataComponent";
import { Badge, Tooltip, Typography } from "antd";
import { ColorManager } from "@nutanix-ui/recharts";

// Define a filtering function
export const filterDataByProjectId = (data, project_id) => {
  return data.filter((item) => item.project_id === project_id);
};

export const getUniqueValuesForSprint = (data, key) => {
  let uniqueValues = [];
  // eslint-disable-next-line array-callback-return
  data.map((item) => {
    uniqueValues.push(
      item[key.name] === null ? `No ${key.nullLabel} Assigned` : item[key.name]
    );
  });
  return [...new Set(uniqueValues)];
};

export const filterDataBySprint = (data, sprint) => {
  return data.filter((item) => {
    if (sprint.includes("All")) {
      return true; // Include all items
    } else if (sprint.includes("No Sprint Assigned")) {
      return item.sprint === null;
    } else {
      return item.sprint && item.sprint.includes(sprint);
    }
  });
};

export const getUniqueValuesByKey = (currentDataBySelectedSprint, key) => {
  let uniqueValues = [];
  currentDataBySelectedSprint.map((item) => {
    uniqueValues.push(
      item[key.name] === null || ""
        ? `No ${key.nullLabel} Assigned`
        : item[key.name]
    );
  });
  return [...new Set(uniqueValues)];
};

export const getUniqueValuesByArray = (currentDataBySelectedSprint, key) => {
  // filterData by currentSprint
  let uniqueValues = [];
  // get currentDataBySelectedSprint and generate unique out of it
  currentDataBySelectedSprint.map((sprintItems) => {
    if (sprintItems[key.name] && sprintItems[key.name].length === 0) {
      uniqueValues.push(`No ${key.nullLabel} Assigned`);
    } else {
      sprintItems[key.name]?.map((item) => {
        uniqueValues.push(item);
      });
    }
  });
  return [...new Set(uniqueValues)];
};

export const filterByDropdownSelection = (
  data,
  selectedSprints,
  selectedAssignees,
  selectedComponents,
  selectedIssueTypes,
  selectedLabels
) => {
  const filterData = data.filter((item) => {
    const isSprintMatch =
      selectedSprints === null ||
      (selectedSprints.includes("null") && item.sprint === null) ||
      (Array.isArray(selectedSprints) &&
        (selectedSprints.includes("All") ||
          selectedSprints.includes(item.sprint)));
    
    const isComponentMatch =
      !selectedComponents ||
      selectedComponents.includes("All") ||
      !item.component ||
      item.component.length === 0 ||
      selectedComponents.some((component) =>
        item.component.includes(component)
      );

    const isLabelsMatch =
      !selectedLabels ||
      selectedLabels.includes("All") ||
      !item.labels ||
      item.labels.length === 0 ||
      selectedLabels.some((labels) => item.labels.includes(labels));

    const isIssueTypeMatch =
      !selectedIssueTypes ||
      selectedIssueTypes.includes("All") ||
      !item.issue_type ||
      item.issue_type.length === 0 ||
      selectedIssueTypes.some((issue_type) => item.issue_type.includes(issue_type));

    const isAssigneeMatch =
      (selectedAssignees &&
        selectedAssignees.includes("null") &&
        item.assignee_name === null) ||
      (Array.isArray(selectedAssignees) &&
        (selectedAssignees.includes("All") ||
          selectedAssignees.includes(item.assignee_name)));

    return (
      isSprintMatch &&
      isIssueTypeMatch &&
      isAssigneeMatch &&
      isComponentMatch &&
      isLabelsMatch
    );
  });
  return filterData;
};

export const findCurrentSprintAndSetSprint = (data, dispatch) => {
  const currentSprint = data.find((item) => item.is_cur_sprint_yn_flag === "Y");
  if (currentSprint) {
    // Case1: is_cur_sprint_yn_flag === "Y" exists
    dispatch(setSelectedSprint([currentSprint.sprint]));
  } else {
    // Case2: is_cur_sprint_yn_flag === "Y" doesn't exist in database
    dispatch(setSelectedSprint(["No Sprint Assigned"]));
  }
};

export const findAndModifyNullValue = (array, condition, modifier) => {
  const foundItem = array.find(condition);
  if (foundItem) {
    modifier(foundItem); // This is where the modifier function is called.
  }
};

export const updateArrayWithAllOption = (arr) => {
  const hasOtherItems = arr.some((item) => item !== "All");
  if (hasOtherItems) {
    // If other items exist, remove 'All'
    arr = arr.filter((item) => item !== "All");
  } else if (arr.length === 0) {
    // If the array is empty, add 'All'
    arr.push("All");
  }
  return arr;
};

export const createArrayOfObjectsFromUniqueKeys = (
  dataArray,
  uniqueKeys,
  criteria
) => {
  const result = {};
  for (const key of uniqueKeys) {
    const dataForKey = dataArray.filter((obj) => {
      if(obj[criteria] !== null){
        return obj[criteria].includes(key)
      }else{
        // 'No Assignee Assigned' || 'No Components Assigned'
        return obj
      }
    });
    result[key] = dataForKey;
  }
  return result;
};

export const filterDataByDate = (data, date) => {
  return data.filter((item) => {
    const etaDueLatest = item.dt_eta_due_latest;
    if (etaDueLatest && moment(etaDueLatest, moment.ISO_8601, true).isValid()) {
      const etaDueDate = moment(etaDueLatest);
      const targetDate = moment(date);
      return etaDueDate.isSame(targetDate, "day");
    }
    return false;
  });
};

export const indicatorItems = [
  {
    key: "etaMissed",
    status: "missed",
    title: "ETA Missed - Open",
  },
  {
    key: "etaMissedCompleted",
    status: "missed-completed",
    title: "ETA Missed - Completed",
  },
  {
    key: "current",
    status: "current",
    title: "Current",
  },
  {
    key: "completed",
    status: "completed",
    title: "Completed",
  },
  {
    key: "assigned",
    status: "assigned",
    title: "Assigned",
  },
];

export const teamStatusColumnProperties = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    width: 150,
    ellipsis: true,
    render: (text) => (
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </div>
    ),
  },
  {
    title: "None",
    dataIndex: "none",
    sorter: (a, b) => a.age - b.age,
    align: "center",
  },
  {
    title: "XS=1",
    dataIndex: "xs",
    sorter: (a, b) => a.xs - b.xs,
    align: "center",
  },
  {
    title: "S=2",
    dataIndex: "s",
    sorter: (a, b) => a.s - b.s,
    align: "center",
  },
  {
    title: "M=3",
    dataIndex: "m",
    sorter: (a, b) => a.m - b.m,
    align: "center",
  },
  {
    title: "L=5",
    dataIndex: "l",
    sorter: (a, b) => a.l - b.l,
    align: "center",
  },
  {
    title: "XL=8",
    dataIndex: "xl",
    sorter: (a, b) => a.xl - b.xl,
    align: "center",
  },
  {
    title: "XXL=13",
    dataIndex: "xxl",
    sorter: (a, b) => a.xxl - b.xxl,
    align: "center",
  },
  {
    title: "XXXL=21",
    dataIndex: "xxxl",
    sorter: (a, b) => a.xxxl - b.xxxl,
    align: "center",
  },
  {
    title: "Total Jira",
    dataIndex: "total",
    sorter: (a, b) => a.total - b.total,
    align: "center",
  },
  {
    title: "Total SP",
    dataIndex: "totalSP",
    sorter: (a, b) => a.total - b.total,
    align: "center",
  },
  // Add more properties as needed
];

const textMappings = {
  1: "XS=1",
  2: "S=2",
  3: "M=3",
  5: "L=5",
  8: "XL=8",
  13: "XXL=13",
  21: "XXXL=21",
  // Add more mappings as needed
};

export const ViewDetailsColumns = [
  {
    title: "Jira Number",
    dataIndex: "jiraNumber",
    ellipsis: true,
    width: 110,
    render: (text, record) => {
      const jiraLink = `https://jira.nutanix.com/browse/${text}`;
      return (
        <Typography.Link href={jiraLink} target="_blank">
          {text}
        </Typography.Link>
      );
    },
  },
  {
    title: "Summary",
    dataIndex: "summary",
    ellipsis: true,
    width: 250,
  },
  {
    title: "Age Days",
    dataIndex: "ageDay",
    ellipsis: true,
    width: 82,
    align: "center",
  },
  {
    title: "Business Name",
    dataIndex: "businessName",
    ellipsis: true,
    width: 183,
  },
  {
    title: "Component",
    dataIndex: "component",
    ellipsis: true,
    width: 190,
  },
  {
    title: "Issue Type",
    dataIndex: "issueType",
    ellipsis: true,
    width: 143,
  },
  {
    title: "Labels",
    dataIndex: "label",
    ellipsis: true,
    width: 200,
  },
  {
    title: "Assignee",
    dataIndex: "assignee",
    ellipsis: true,
    width: 200,
  },
  {
    title: "Created Date",
    dataIndex: "createdDate",
    width: 170,
    render: (text) => {
      const inputDate = moment(text);
      const formattedDate = inputDate.tz("UTC").format("MMM DD YYYY, h:mm A");
      return formattedDate;
    },
    ellipsis: true,
  },
  {
    title: "Updated Date",
    dataIndex: "updatedDate",
    width: 170,
    render: (text) => {
      const inputDate = moment(text);
      const formattedDate = inputDate.tz("UTC").format("MMM DD YYYY, h:mm A");
      return formattedDate;
    },
    ellipsis: true,
  },
  {
    title: "Initial ETA",
    dataIndex: "initialEta",
    width: 110,
    render: (text) => {
      if (text !== null) {
        const inputDate = moment(text);
        const formattedDate = inputDate.format("MMM DD YYYY");
        return formattedDate;
      } else {
        return "No ETA Assigned";
      }
    },
    ellipsis: true,
  },
  {
    title: "Latest ETA",
    dataIndex: "lastestEta",
    width: 110,
    render: (text) => {
      if (text !== null) {
        const inputDate = moment(text);
        const formattedDate = inputDate.format("MMM DD YYYY");
        return formattedDate;
      } else {
        return "No ETA Assigned";
      }
    },
    ellipsis: true,
  },
  {
    title: "Days In Status",
    dataIndex: "daysInStatus",
    ellipsis: true,
    width: 69,
    align: "center",
  },
  {
    title: "Initial Story Point",
    dataIndex: "initialStoryPoint",
    width: 88,
    render: (text) => {
      const mappedText = textMappings[text];
      return mappedText !== undefined ? mappedText : text;
    },
    ellipsis: true,
    align: "center",
  },
  {
    title: "Latest Story Point",
    dataIndex: "lastestStoryPoint",
    width: 88,
    render: (text) => {
      const mappedText = textMappings[text];
      return mappedText !== undefined ? mappedText : text;
    },
    ellipsis: true,
    align: "center",
  },
  {
    title: "Count Sprint",
    dataIndex: "countSprint",
    width: 63,
    ellipsis: true,
    align: "center",
  },
  {
    title: "Count Story Point Changes",
    dataIndex: "countStoryPoints",
    ellipsis: true,
    width: 125,
    align: "center",
  },
  {
    title: "Jira Status",
    dataIndex: "jiraStatus",
    ellipsis: true,
    width: 175,
    render: (text) => {
      return (
        <div id="jiraStatusCell">
          {/* <span id="indicators" className={`${text}`}></span> */}
          <span>{text}</span>
        </div>
      );
    },
  },
];

export const getViewDetailDataSource = (data) => {
  return data.map((item, index) => {
    const dataSourceItem = {
      key: index + 1,
      jiraNumber: item.issuenum || "-",
      summary: item.summary || "-",
      ageDay: item.issue_age_days || "-",
      businessName: item.businessname || "-",
      component: item.component || "-",
      issueType: item.issue_type || "-",
      label: Array.isArray(item.labels) ? item.labels.join(", ") : "-",
      assignee: item.assignee_name || "-",
      createdDate: item.dt_created,
      updatedDate: item.dt_updated,
      initialEta: item.dt_eta_initial,
      lastestEta: item.dt_eta_latest,
      daysInStatus: item.days_jira_status || "-",
      initialStoryPoint: item.story_point_initial || "-",
      lastestStoryPoint: item.story_point_latest || "-",
      countSprint: item.cnt_sprint || "-",
      countStoryPoints: item.cnt_story_point || "-",
      jiraStatus: item.jira_status || "-",
    };
    return dataSourceItem;
  });
};

export const generateDateArray = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const dateArray = [];
  let currentDate = start.clone();

  while (currentDate.isSameOrBefore(end, "day")) {
    dateArray.push(currentDate.format("YYYY-MM-DD"));
    currentDate.add(1, "day");
  }

  return dateArray;
};

export const generateWeeksArray = (datesArray) => {
  const weeksArray = [];
  const weeksCount = 7; // Number of days in a week
  const targetWeeks = 5; // Total weeks to generate

  for (let i = 0; i < targetWeeks; i++) {
    const startIdx = i * weeksCount;
    const endIdx = startIdx + weeksCount;
    const weekDates = datesArray.slice(startIdx, endIdx);

    // If there are not enough dates for a full week, fill with the next available dates
    while (weekDates.length < weeksCount && endIdx < datesArray.length) {
      weekDates.push(datesArray[endIdx]);
      endIdx++;
    }

    weeksArray.push({
      week: `Week ${i + 1}`,
      dates: weekDates,
    });
  }

  return weeksArray;
};

const getTitle = (projectId, viewBy) => {
  if (projectId === 12530) {
    switch (viewBy) {
      case 'component':
        return 'Teams';
      case 'assignee name':
        return 'Assignee Name';
      default:
        return 'Business Name';
    }
  }
  return 'Team';
};

export const generateColumnsForWeeklyView = (weeksInArray, viewBy, currentTeam) => {
  const columns = [
    {
      title: getTitle(currentTeam, viewBy),
      dataIndex: "component",
      key: "component",
      width: "16.67%",
      sorter: (a, b) => a.component.localeCompare(b.component),
    },
  ];
  weeksInArray.map((weeks) => {
    const weekNumber = weeks.week.trim().replace(/\D/g, "");
    const formattedWeekNumber = weekNumber.padStart(2, "");
    const currentDate = moment().format("YYYY-MM-DD");
    const isCurrentWeek = weeks.dates.includes(currentDate);
    columns.push({
      title: `W${formattedWeekNumber}`,
      dataIndex: formattedWeekNumber,
      key: formattedWeekNumber,
      dates: weeks.dates,
      width: "16.67%",
      align: "center",
      className: isCurrentWeek ? "current-week weekHeader" : "weekHeader",
    });
  });
  return columns;
};

export const generateColumnsForDayView = (currentWeekDates, viewBy, currentTeam) => {
  const columns = [
    {
      title: getTitle(currentTeam, viewBy),
      dataIndex: "component",
      key: "component",
      width: "16.67%",
      sorter: (a, b) => a.component.localeCompare(b.component),
    },
  ];
  currentWeekDates.dates.map((date) => {
    const today = moment().format("YYYY-MM-DD");
    const isToday = date === today;

    columns.push({
      title: moment(date).format("DD MMM, ddd"),
      dataIndex: date,
      key: date,
      date: date,
      width: "16.67%",
      align: "center",
      className: isToday ? "currentDate dayHeader" : "",
    });
  });
  return columns;
};

export const generateDataSourceForDayView = (
  columns,
  uniqueComponent,
  generatedComponentData
) => {
  const dataSource = uniqueComponent.map((component, index) => {
    const dataSourceItem = {
      key: index + 1,
      component: component,
    };
    columns.forEach((column) => {
      if (column.dataIndex !== "component") {
        const componentData = generatedComponentData[component];
        dataSourceItem[column.dataIndex] = (
          <DayDataComponent data={componentData} date={column.date} />
        );
      }
    });

    return dataSourceItem;
  });
  return dataSource;
};

export const generateDataSourceForWeeklyView = (
  uniqueData,
  columns,
  generatedComponentData
) => {
  const dataSource = uniqueData.map((component, index) => {
    const dataSourceItem = {
      key: index + 1,
      component: component,
    };
    columns.forEach((column) => {
      // Check if the column dataIndex is not "component" before adding it to the dataSourceItem
      if (column.dataIndex !== "component") {
        const componentData = generatedComponentData[component];
        const filteredDataByEta = filterDataByDates(
          componentData,
          column.dates,
          "dt_eta_due_latest"
        );
        dataSourceItem[column.dataIndex] = (
          <WeeklyDataComponent filteredDataByEta={filteredDataByEta} />
        );
      }
    });
    return dataSourceItem;
  });
  return dataSource;
};

export const filterDataByDates = (data, datesArray, dateField) => {
  return data.filter((item) => {
    const fieldValue = item[dateField];
    return datesArray.includes(fieldValue);
  });
};

export const findEarliestDate = (dataArray, startDateKey) => {
  const allSprintStartDate = dataArray.map((item) => item[startDateKey]);
  // commented for future checkup
  // const uniqueDate = [...new Set(allSprintStartDate)]
  const dates = allSprintStartDate.map((dateString) => new Date(dateString));
  const earliestDate = new Date(Math.min(...dates));
  const earliestDateFormatted = moment(earliestDate).format("YYYY-MM-DD");
  return earliestDateFormatted;
};

export const findLatestDate = (dataArray, endDateKey) => {
  const allSprintStartDate = dataArray.map((item) => item[endDateKey]);
  // commented for future checkup
  // const uniqueDate = [...new Set(allSprintStartDate)]
  // Convert date strings to Date objects
  const dates = allSprintStartDate.map((dateString) => new Date(dateString));
  const latestDate = new Date(Math.max(...dates));
  const latestDateFormatted = moment(latestDate).format("YYYY-MM-DD");
  return latestDateFormatted;
};

export const extractAndDispatchDates = (
  dataArray,
  startDateKey,
  endDateKey,
  startAction,
  endAction,
  dispatch
) => {
  // const startSprintDate = dataArray.find((item) => item[startDateKey]);
  // const endSprintDate = dataArray.find((item) => item[endDateKey]);
  const startSprintEarliestDate = findEarliestDate(dataArray, startDateKey);
  const endSprintLatestDate = findLatestDate(dataArray, endDateKey);
  if (startSprintEarliestDate && endSprintLatestDate) {
    dispatch(startAction(startSprintEarliestDate));
    dispatch(endAction(endSprintLatestDate));
  }
};

export const completeDatesToCount = (datesArray, desiredDayCount) => {
  if (datesArray.length >= desiredDayCount) {
    return datesArray; // No need to add more dates, we have enough
  }
  const lastDate = moment(datesArray[datesArray.length - 1]);
  const completedDates = [...datesArray]; // Create a copy of the input array
  while (completedDates.length < desiredDayCount) {
    lastDate.add(1, "day");
    completedDates.push(lastDate.format("YYYY-MM-DD"));
  }
  return completedDates;
};

export const filterWeekdays = (datesArray) => {
  return datesArray.filter((date) => {
    const dayOfWeek = moment(date).format("dddd"); // Get the day of the week
    return dayOfWeek !== "Saturday" && dayOfWeek !== "Sunday"; // Filter out Saturdays and Sundays
  });
};

export const filterDataByCheckboxes = (
  filteredEta,
  isCompletedSelected,
  isOpenSelected
) => {
  if (!filteredEta) {
    return [];
  }
  return filteredEta.filter((eta) => {
    if (isCompletedSelected && isOpenSelected) {
      // Show all data when both checkboxes are selected
      return true;
    } else if (isCompletedSelected) {
      // Show data with eta_status === "Completed" when isCompletedSelected is true
      return eta.standard_jira_status === "7.Closed";
    } else if (isOpenSelected) {
      // Show data with ["ETA Missed", "Current", "Upcoming", "Assigned"] when isOpenSelected is true
      return eta.standard_jira_status !== "7.Closed";
    }
    return false;
  });
};

function calculateTotal(data, criteria) {
  let total = 0;
  data.forEach((item) => {
    total += item[criteria] === null ? 0 : item[criteria];
  });
  return total;
}

export const generateTrendsData = (items, trends) => {
  const menuItems = items.map((item, index) => {
    const filteredSprint = trends.filter((trend) => trend.sprint === item);
    return {
      key: `${index + 1}`,
      sprint: item,
      "Total Tickets": calculateTotal(filteredSprint, "total_tickets"),
      "Story Points": calculateTotal(filteredSprint, "total_story_points"),
      "New Tickets": calculateTotal(filteredSprint, "total_new_tickets"),
      "Carry Over": calculateTotal(filteredSprint, "total_carryover_tickets"),
      "Storypoint Missing": calculateTotal(
        filteredSprint,
        "total_sp_missing_tickets"
      ),
      "Storypoint Assigned": calculateTotal(
        filteredSprint,
        "total_sp_assigned_tickets"
      ),
      "ETA Not Assigned": calculateTotal(
        filteredSprint,
        "total_eta_notassigned_tickets"
      ),
      "ETA Assigned": calculateTotal(
        filteredSprint,
        "total_eta_assigned_tickets"
      ),
      "Closed Tickets": calculateTotal(filteredSprint, "total_closed_tickets"),
      "Inprogress Tickets": calculateTotal(
        filteredSprint,
        "total_inprogress_tickets"
      ),
    };
  });
  return menuItems.reverse();
};

export const generateCarryOverData = (data) => {
  if (!data || data.length === 0) {
    return [];
  }

  const sprints = [
    "2 Sprints",
    "3 Sprints",
    ">4 Sprints",
  ];
  const menuItems = sprints.map((item, index) => {
    let filteredData;
    if (item === ">4 Sprints") {
      filteredData = data.filter((carryover) => carryover.sprints_spanning === '4 Sprints' || carryover.sprints_spanning === '5 Sprints');
    } else {
      filteredData = data.filter((carryover) => carryover.sprints_spanning === item);
    }

    return {
      name: item,
      value: calculateTotal(filteredData, "jira_count"),
    };
  });

  return menuItems;
};

export const generateSelectMenu = (items, key) => {
  const menuItems = items.map((item, index) => ({
    label: item,
    value: `${item}`,
  }));
  if (key !== "sprint") {
    menuItems.unshift({
      label: "All",
      value: `All`,
    });
  }
  if (key !== "sprint") {
    // Sort the menu items alphabetically while keeping "All" on top
    return menuItems.sort((a, b) => {
      if (a.label === "All") return -1;
      if (b.label === "All") return 1;
      return a.label.localeCompare(b.label);
    });
  } else {
    // Return menu items without sorting
    return menuItems;
  }
};

export const headerItems = [
  {
    label: "Days",
    key: "1",
  },
  {
    label: "Weekly",
    key: "2",
  },
];

export const headerItemsViewBy = [
  {
    label: "Component",
    key: "1",
  },
  {
    label: "Bussiness Name",
    key: "2",
  },
  {
    label: "Assignee Name",
    key: "3",
  },
];

export const linePalette = {
  "Total Tickets": "blue",
  "Story Points": "vistaBlue",
  "New Tickets": "sanMarino",
  "Carry Over": "froly",
  "Storypoint Assigned": "allports",
  "Storypoint Missing": "mediumSlateBlue",
  "ETA Not Assigned": "buff",
  "All": "sandyBrown",
};

export const trendMenuItem = [
  {
    label: "All",
    value: "All",
    emoji: <Badge color={ColorManager.getColor(linePalette["All"])} />,
  },
  {
    label: "Total Tickets",
    value: "Total Tickets",
    emoji: (
      <Badge color={ColorManager.getColor(linePalette["Total Tickets"])} />
    ),
  },
  {
    label: "Story Points",
    value: "Story Points",
    emoji: <Badge color={ColorManager.getColor(linePalette["Story Points"])} />,
  },
  {
    label: "New Tickets",
    value: "New Tickets",
    emoji: <Badge color={ColorManager.getColor(linePalette["New Tickets"])} />,
  },
  {
    label: "Carry Over",
    value: "Carry Over",
    emoji: <Badge color={ColorManager.getColor(linePalette["Carry Over"])} />,
  },
  {
    label: "Storypoint Assigned",
    value: "Storypoint Assigned",
    emoji: (
      <Badge
        color={ColorManager.getColor(linePalette["Storypoint Assigned"])}
      />
    ),
  },
  {
    label: "Storypoint Missing",
    value: "Storypoint Missing",
    emoji: (
      <Badge color={ColorManager.getColor(linePalette["Storypoint Missing"])} />
    ),
  },
  {
    label: "ETA Not Assigned",
    value: "ETA Not Assigned",
    emoji: (
      <Badge color={ColorManager.getColor(linePalette["ETA Not Assigned"])} />
    ),
  },
];

export const capitalizeText = (text) => {
  const words = text.split(" ");
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  const capitalizedText = capitalizedWords.join(" ");
  return capitalizedText;
};

export const getLines = (trend) =>
  trend.includes("All")
    ? [
        "Total Tickets",
        "Story Points",
        "New Tickets",
        "Carry Over",
        "Storypoint Assigned",
        "Storypoint Missing",
        "ETA Not Assigned",
      ]
    : trend;

export const getLine = (label) => {
  if (label === "Total Jira") {
    return "Total Tickets";
  } else if (label === "Missing Story Points") {
    return "Storypoint Missing";
  } else if (label === "Closed") {
    return "Closed Tickets";
  } else if (label === "New") {
    return "New Tickets";
  } else if (label === "Story Points Assigned") {
    return "Storypoint Assigned";
  } else if (label === "In Progress") {
    return "Inprogress Tickets";
  } else {
    return label;
  }
};

export const transformProductivity = (input) => {
  const output = [];
  for (const key in input) {
    const name = key;
    const value = Math.round(input[key]);
    output.push({ name, value });
  }
  return output;
}

// New performance api code here 

export const trasformTeams = (teams) => {
  if (teams && teams.length > 0) {
    return teams
      .map((team, index) => ({
        key: `${index + 1}`,
        label: team?.project_key_desc,
        ...team,
      }))
      .sort((a, b) => a?.project_key_desc.localeCompare(b?.project_key_desc));
  } else {
    return [
      {
        key: 1,
        label: "No Team Assigned",
        project_key_desc: "No Team Assigned",
        project_id: null,
      },
    ];
  }
};

export const filterDataCycleTime = (data, filters) => {
  return data.map((item) => {
    const filteredItem = { name: item.name };
    for (const [key, value] of Object.entries(item)) {
      if (key !== "name" && filters[key]) {
        filteredItem[key] = value;
      }
    }
    return filteredItem;
  });
};

export const generateMenuForDropdown = (data, id) => {
  const tooltips = {
    chooseVelocity: {
      Production: "Includes Jira's moved to Closed / Done during the time frame.",
      UAT: "Includes Jira's moved to 'Business UAT' or 'Ready for Stage' Stage during the time frame."
    },
    cycleTicketProgress: {
      All: "Includes all Jira's if development started during the time frame.",
      'Only Closed': "Includes only Closed Jira's for which development started during the time frame."
    },
  };

  const items = data.map((item) => {
    let tooltipTitle = tooltips[id]?.[item]; // Get tooltip title based on ID and item
    const label = tooltipTitle ? (
      <Tooltip title={tooltipTitle} key={item}>
        {item}
      </Tooltip>
    ) : item;
    return {
      label,
      value: item
    };
  });
  return items;
}

export const findObjectByKey = (array, key, value) => {
  for (let obj of array) {
    if (obj[key] === value) {
      return obj;
    }
  }
  return null; // If object not found
}

export const findObjectByTwoKey = (array, key1, key2, value1, value2) => {
  for (let obj of array) {
    if (obj[key1] === value1 && obj[key2] === value2) {
      return obj;
    }
  }
  return null; // If object not found
}

export const getDataByPage = (data, page, pageSize) => {
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  return data?.slice(startIndex, endIndex);
};

const getMonthIndex = (monthStr) => {
  const monthIndexMap = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };
  const [month, year] = monthStr.split(",");
  return parseInt(year) * 12 + monthIndexMap[month];
};

function sortQuarters(quarters) {
  const quarterOrder = { 'Q1': 1, 'Q2': 2, 'Q3': 3, 'Q4': 4 };
  quarters.sort((a, b) => {
    const [yearA, quarterA] = a.split('-');
    const [yearB, quarterB] = b.split('-');
    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }
    return quarterOrder[quarterA] - quarterOrder[quarterB];
  });
  return quarters;
}

export const getPeriodSelection = (developerVelocityData, viewBy) => {
  let color = [
    "#BDE4FD",
    "#91D2FB",
    "#22A5F7",
    "#1B6DC6",
    "#4682B4",
    "#6495ED",
  ];
  let periodSelection = [];
  const velocityData = developerVelocityData.map((item) => item.data).flat();
  let uniquePeriods = [
    ...new Set(velocityData.map((item) => item.period_selection)),
  ];
  uniquePeriods = viewBy === "Quarterly" ?  sortQuarters(uniquePeriods) : uniquePeriods
  uniquePeriods.forEach((item, index) => {
    periodSelection.push({ name: item, color: color[index] });
  });
  // Sort the data array
  const sortedPeriodSelection = periodSelection.sort((a, b) => {
    const monthIndexA = getMonthIndex(a.name);
    const monthIndexB = getMonthIndex(b.name);
    return monthIndexA - monthIndexB;
  });
  return viewBy === "Quarterly"
    ? periodSelection
    : sortedPeriodSelection;
};

export const getPeriodSelectionCustomer = (developerVelocityData, viewBy) => {
  let color = [
    "#BDE4FD",
    "#91D2FB",
    "#22A5F7",
    "#1B6DC6",
    "#4682B4",
    "#6495ED",
  ];
  let periodSelection = [];
  const velocityData = [
    ...new Set(
      developerVelocityData
        .map((item) => item.data)
        .flat()
        .map((item) => item.period_selection)
    ),
  ];
  velocityData?.forEach((item, index) => {
    periodSelection.push({ name: item, color: color[index] });
  });
  // Sort the data array
  const sortedPeriodSelection = periodSelection.sort((a, b) => {
    const monthIndexA = getMonthIndex(a.name);
    const monthIndexB = getMonthIndex(b.name);
    return monthIndexA - monthIndexB;
  });
  return viewBy === "Quarterly"
    ? periodSelection.reverse()
    : sortedPeriodSelection;
};

const removeFirstTwoDigits = (year) => {
  return year % 100; // Extracting the last two digits
};

export const formatQuarter = (text) => {
  // Extract the year and quarter from the text
  const year = text.substring(2, 4); // Extract the last two digits of the year
  const quarter = text.substring(6);

  // Format the text as "FYYY Q#"
  return `FY${year} Q${quarter}`;
};

export const formatMonthly = (input) => {
  // Split the input into parts
  const parts = input.split("-");
  const year = parts[0];
  const quarterMonth = parts[1];

  // Map month number to its corresponding name
  const monthMap = {
    Q1M1: "Aug",
    Q1M2: "Sep",
    Q1M3: "Oct",
    Q2M1: "Nov",
    Q2M2: "Dec",
    Q2M3: "Jan",
    Q3M1: "Feb",
    Q3M2: "Mar",
    Q3M3: "Apr",
    Q4M1: "May",
    Q4M2: "Jun",
    Q4M3: "Jul",
  };
  return `${monthMap[quarterMonth]}, ${removeFirstTwoDigits(year)}`;
}

export const transformAndCalculatePercentages = (quarterlyData, filters) => {
  const allStatuses = [
    "Blocked",
    "Business UAT",
    "Code Review",
    "Deployment",
    "Development",
    "QA Testing",
    "QA/UAT Done",
  ];

  const result = quarterlyData.map((quarter) => {
    const quarterName =
      quarter[filters.viewBy === "Monthly" ? "monthly" : "quarterly"];
    const quarterObj = { name: quarterName };

    let sum = 0;
    allStatuses.forEach((status) => {
      const stage = quarter.data.find(
        (data) => data.jira_status_derived === status
      );
      const key = {
        "Total Day Spent": "total_days_spent",
        Average: "avg_days_spent",
        Median: "median_days_spent",
        "Percentile 90th Day Spent": "percentile_90th_days_spent",
      }[filters.selectedCycleTime];
      const value = stage ? (stage[key]) : 0;
      sum += value;
      quarterObj[status] = Number(value?.toFixed(1));
    });
    quarterObj.sum = sum;
    allStatuses.forEach((status) => {
      const percentageKey = status + "PERCENTAGE";
      if (sum !== 0) {
        quarterObj[percentageKey] = ((quarterObj[status] / sum) * 100).toFixed();
      } else {
        // Handling the case where sum is 0
        quarterObj[percentageKey] = 0;
      }
    });

    return quarterObj;
  });

  return result.reverse();
};


export const transformAndCalculatePercentagesJira = (quarterlyData, filters) => {
  const allStatuses = [
    "Blocked",
    "Business UAT",
    "Code Review",
    "Deployment",
    "Development",
    "QA Testing",
    "QA/UAT Done",
  ];

  const result = quarterlyData.map((quarter) => {
    const quarterName = quarter.issuenum;
    const quarterObj = { name: quarterName };

    let sum = 0;
    allStatuses.forEach((status) => {
      const stage = quarter.data.find(
        (data) => data.jira_status_derived === status
      );
      const key = {
        "Total Day Spent": "total_days_spent",
        Average: "avg_days_spent",
        Median: "median_days_spent",
        "Percentile 90th Day Spent": "percentile_90th_days_spent",
      }[filters.selectedCycleTime];
      const value = stage ? stage[key] : 0;
      sum += value;
      quarterObj[status] = Number(value?.toFixed(1));
    });
    quarterObj.sum = sum;

    allStatuses.forEach((status) => {
      const percentageKey = status + "PERCENTAGE";
      if (sum !== 0) {
        quarterObj[percentageKey] = Math.round((quarterObj[status] / sum) * 100);
      } else {
        // Handling the case where sum is 0
        quarterObj[percentageKey] = 0;
      }
    });

    return quarterObj;
  });

  return result;
};

export const labelStyle = {
  fontSize: "12px",
  fill: "#9AA5B5",
  display: "flex",
  width: "100px",
};

export const ApplicationComponent = {
  CFD: "CFD",
  CYCLE_TIME: "CYCLE_TIME",
  VELOCITY: "VELOCITY",
  CODECOVERAGE: "CODECOVERAGE"
};

export const filterDataByDropdown = (data, selectedSprint, selectedAssignee, selectedComponent, selectedIssueType, selectedLabels) => {
  return filterByDropdownSelection(
    data,
    selectedSprint,
    selectedAssignee,
    selectedComponent,
    selectedIssueType,
    selectedLabels
  );
};



