import React from "react";
import "./FilterDropdown.css";
import { Button, Divider, Select, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { toggleLoadingCfd, toggleLoadingCycle, toggleLoadingVelocity } from "../../../../features/JiraAnalysis/enggDashboardSlice";
import { ApplicationComponent } from "../../EngineeringUtils";

const FilterDropdown = ({ ...dropdown }) => {
  const dispatch = useDispatch();
  const {
    items,
    label,
    mode,
    onChangeItem,
    selectedItem,
    handleClick,
    loading,
    id,
    component,
    open,
    handleClose,
  } = dropdown;

  const selectProps = {
    mode: mode,
    style: {
      width: "100%",
    },
    showArrow: true,
    value: selectedItem,
    options: items,
    onChange: (newValue) => onChangeItem(newValue),
    maxTagCount: "responsive",
    onClick: handleClick,
    loading: loading,
  };

  const handleApply = (component) => {
    if(component === ApplicationComponent.CFD && id !=='chooseViewByCustomer'){
      dispatch(toggleLoadingCfd(true))
    }else if(component === ApplicationComponent.CYCLE_TIME && id !== 'cycleTime' && id !== 'cycleTicketProgress'){
      dispatch(toggleLoadingCycle(true));
    }else if(component === ApplicationComponent.VELOCITY && id !== 'chooseViewByVelocity' && id !== 'chooseVelocity'){
      dispatch(toggleLoadingVelocity(true))
    }
  }

  return (
    <div className="filter-container" data-testid="filter-dropdown">
      {label && <p data-testid={`filter-label-${id}`}>{label}</p>}
      <Select
        {...selectProps}
        maxTagPlaceholder={(omittedValues) => (
          <Tooltip title={omittedValues.map(({ label }) => label).join(", ")}>
            <span>+{omittedValues.length}</span>
          </Tooltip>
        )}
        open={open}
        loading={loading}
        className="filterSelect"
        data-testid={`filter-select-${id}`}
        aria-label={`filter-select-${id}`}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              {(id !== 'customerViewBy' && id !== 'cycleTime' && id !== 'cycleTicketProgress' && id !== 'chooseVelocity' && id !== 'chooseViewByVelocity' && id !== 'codeCoverageProducts' && id !== 'codeCoverageViewBy') && (
                <>
                  <Divider style={{ margin: "8px 0" }} />
                  <div className="filterDropdownFooter">
                    <Button 
                      type="danger" 
                      size="small" 
                      onClick={(e)=>{
                        e.stopPropagation()
                        handleClose(id)}
                      }
                      // style={{width: '100%'}}
                    >
                      Cancel
                    </Button>
                    <Button 
                      type="primary" 
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleClose(id)
                        handleApply(component)
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </>
              )}
            </>
          )
        }}
        onDropdownVisibleChange={(value) => id === 'cycleTime' || id === 'cycleTicketProgress' || id === 'customerViewBy' || id === 'codeCoverageProducts' || id === 'codeCoverageViewBy' || id === 'chooseVelocity' || id === 'chooseViewByVelocity' ?   null : handleClose(id)}
      />
    </div>
  );
};

export default FilterDropdown;
