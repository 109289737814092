import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { ETLCanvasContext, ETLCanvasContextDispatcher } from "../../config/contexts";

import postgress_logo from "../../images/postgresql-logo-png-transparent.png";
import snowflake_logo from "../../images/Snowflake_logo.png";
import Mysqllogo_logo from "../../images/Mysqllogo.png";
import AmazonS3_logo from "../../images/s3-logo.png";
import MongoDB_logo from "../../images/mongoDb.png";
import Dremio_logo from "../../images/dremio-logo.png";
import {
  Button,
  EditIcon,
  LocalTable,
  RemoveIcon,
} from "@nutanix-ui/prism-reactjs";
import "./styles.css";
import AamzonS3EditModal from './AmazonS3EditModal'
import MongoDBModal from './MongoDBModal'
import PostGresModalEdit from './PostGresEditModal'
import SnowFlakeModalEdit from './SnowFlakeEditModal'
export function SourceInputList(props) {
  const pipelineData = useContext(ETLCanvasContext);
  const setPipelineData = useContext(ETLCanvasContextDispatcher);
  const [mainPipeLineData, setMainPipelineData] = useState([]);
  const [sourceTableData, setSourceTableData] = useState([]);// eslint-disable-next-line 
  const [data, setData] = useState([]);
  const [modalContent, setModalContent] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const renderSchemaIcon = (imgID) => {
    if (imgID === "postgres") {
      return (
        <div>
          <img
            src={postgress_logo}
            style={{ height: "17px" }}
            alt="schemaIcon"
          />
        </div>
      );
    } else if (imgID === "snowflake") {
      return (
        <img src={snowflake_logo} style={{ height: "17px" }} alt="schemaIcon" />
      );
    } else if(imgID === "s3"){
      return (
        <img src={AmazonS3_logo} style={{ height: "17px" }} alt="schemaIcon" />
      );
    }else if(imgID === "mongo"){
      return (
        <img src={MongoDB_logo} style={{ height: "17px" }} alt="schemaIcon" />
      );
    }else if(imgID === 'mysql') {
      return (
        <img src={Mysqllogo_logo} style={{ height: "17px" }} alt="schemaIcon" />
      );
    } else if(imgID === 'dremio'){
      return (
        <img src ={Dremio_logo}  style={{ height: "17px" }} alt="schemaIcon"/>
      )
    }
  };
  useEffect(() => {
    setMainPipelineData((prevState) => pipelineData);
  }, [pipelineData]);

  useEffect(() => {
    const sourceSchemaList = _.cloneDeep(mainPipeLineData?.SourceSchema || {});
    const tempData = [];// eslint-disable-next-line 
    for (const [key, value] of Object.entries(sourceSchemaList)) {
      value.forEach((obj) => {
        tempData.push(obj);
      });
    }
    constructData(tempData);
    setData((prevState) => tempData);
  }, [mainPipeLineData]);

  const deleteInput = (value) => {
    const tempData = [];
    for (let i = 0; i < sourceTableData.length; i++) {
      if (sourceTableData[i].key !== value.key) {
        tempData.push(sourceTableData[i]);
      }
    }
    setSourceTableData((prevState) => tempData);
    removeItemSourceSchema(value);
  };

  const editInput = (object, index, value) => {
    setModalContent((prevState) => {
      const res = findItemSourceSchema(object);
      res.formData.schema = res?.label || '';
      res.formData.id = object?.key || '';
      res.formData.warehouse = res?.warehouse || '';
      res.formData.db = res?.db || '';
      res.formData.schemaValue = res?.value || '';
      res.formData.index = index > -1 ? index : '';
      return res.formData;
    });
    setModalVisible((prevState) => true);
  };

  const findItemSourceSchema = (item) => {
    const { connectionName, key } = item;
    const list = connectionName?.name ? pipelineData?.SourceSchema[connectionName?.name] : [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === key) {
        return list[i];
      }
    }
  };

  const removeItemSourceSchema = (item) => {
    const { connectionName, key } = item;
    const list =connectionName?.name ? pipelineData?.SourceSchema[connectionName?.name] : [];
    let tempList = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].id !== key) {
        tempList.push(list[i]);
      }
    }
    pipelineData.SourceSchema[connectionName.name] = tempList;
    setPipelineData({ ...pipelineData });
  };

  const columns = [
    {
      title: "Connection",
      key: "connection",
      render: (value, object, index) => {
        return (
          <div
            className="inputSchemaContentContainer"
            data-testid="inputSchemaContentContainer"
          >
            <div
              className="inputSchemaContent"
              data-testid="inputSchemaContent"
            >
              <div
                className="pipeLineSchemaSource"
                data-testid="pipeLineSchemaSource"
              >
                {renderSchemaIcon(value?.name)}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Alias",
      key: "alias",
      render: (value, object, index) => {
        return <p>{value}</p>;
      },
    },
    {
      title: "Connection Name",
      key: "connectionName",
      render: (value, object, index) => {
        return <p>{value?.name}</p>;
      },
    },
    {
      title: "Edit",
      key: "detail",
      render: (value, object, index) => {
        return (
          <div>
            <Button onClick={(e) => editInput(object, index, value)} data-testid = 'paas-etlCanvas-editSource-tableRow'>
              <p style={{ fontSize: "10px", padding: "4px" }}>
                <EditIcon />
              </p>
            </Button>
          </div>
        );
      },
    },
    {
      title: "Delete",
      key: "delete",
      render: (value, object, index) => {
        return (
          <div>
            <Button onClick={(e) => deleteInput(object)}>
              <RemoveIcon />
            </Button>
          </div>
        );
      },
    },
  ];

  const constructData = (tempData) => {
    setSourceTableData([]);
    tempData.forEach((obj, idx) => {
      setSourceTableData((prevState) => [
        ...prevState,
        {
          connection: { name: obj?.imgID || '' },
          connectionName: { name: obj?.value || '' },
          alias: obj?.formData?.alias || '',
          key: obj?.id || '',
          index: obj.formData.index,
        },
      ]);
    });
  };

  const handleOnClose = () => {
    setModalVisible(false);
  };

  return (
    <div className="InputListContainer">
      <h2>{props?.heading} Object List</h2>
      <LocalTable columns={columns} dataSource={sourceTableData} />
      <FormModal
        origin="Source"
        visible={modalVisible}
        handleOnClose={handleOnClose}
        data={modalContent}
      />
    </div>
  );
}

export function TargetInputList(props) {
  const pipelineData = useContext(ETLCanvasContext);
  const setPipelineData = useContext(ETLCanvasContextDispatcher);
  const [mainPipeLineData, setMainPipelineData] = useState([]);
  const [targetTableData, setTargetTableData] = useState([]);// eslint-disable-next-line 
  const [data, setData] = useState([]);
  const [modalContent, setModalContent] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const renderSchemaIcon = (imgID) => {
    if (imgID === "postgres") {
      return (
        <div>
          <img
            src={postgress_logo}
            style={{ height: "17px" }}
            alt="schemaIcon"
          />
        </div>
      );
    } else if (imgID === "snowflake") {
      return (
        <img src={snowflake_logo} style={{ height: "17px" }} alt="schemaIcon" />
      );
    } else if(imgID === "s3"){
      return (
        <img src={AmazonS3_logo} style={{ height: "17px" }} alt="schemaIcon" />
      );
    } else {
      return (
        <img src={Mysqllogo_logo} style={{ height: "17px" }} alt="schemaIcon" />
      );
    }
  };

  useEffect(() => {
    setMainPipelineData((prevState) => pipelineData);
  }, [pipelineData]);

  useEffect(() => {
    const targetSchemaList = _.cloneDeep(mainPipeLineData?.TargetSchema || {});
    const tempData = [];// eslint-disable-next-line 
    for (const [key, value] of Object.entries(targetSchemaList)) {
      value.forEach((obj) => {
        tempData.push(obj);
      });
      constructData(tempData);
      setData((prevState) => tempData);
    }
  }, [mainPipeLineData]);

  const deleteInput = (value) => {
    const tempData = [];
    for (let i = 0; i < targetTableData.length; i++) {
      if (targetTableData[i].key !== value.key) {
        tempData.push(targetTableData[i]);
      }
    }
    setTargetTableData((prevState) => tempData);
    removeItemTargetSchema(value);
  };

  const editInput = (object,index,value) => {
    setModalContent((prevState) => {
      const res = findItemTargetSchema(object);
      res.formData.schema = res?.label || '';
      res.formData.id = object?.key || '';
      res.formData.db = res?.db|| '';
      res.formData.schemaValue = res?.value || '';
      res.formData.warehouse = res?.warehouse || '';
      res.formData.index = index > -1 ? index : '';
      return res.formData;
    });
    setModalVisible((prevState) => true);
  };

  const findItemTargetSchema = (item) => {
    const { connectionName, key } = item;
    const list = pipelineData?.TargetSchema[connectionName?.name];
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === key) {
        return list[i];
      }
    }
  };

  const removeItemTargetSchema = (item) => {
    const { connectionName, key } = item;
    const list = connectionName?.name
      ? pipelineData?.TargetSchema[connectionName?.name]
      : [];
    let tempList = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].id !== key) {
        tempList.push(list[i]);
      }
    }
    pipelineData.TargetSchema[connectionName.name] = tempList;
    setPipelineData({ ...pipelineData });
  };

  const columns = [
    {
      title: "Connection",
      key: "connection",
      render: (value, object, index) => {
        return (
          <div
            className="inputSchemaContentContainer"
            data-testid="inputSchemaContentContainer"
          >
            <div
              className="inputSchemaContent"
              data-testid="inputSchemaContent"
            >
              <div
                className="pipeLineSchemaSource"
                data-testid="pipeLineSchemaSource"
              >
                {renderSchemaIcon(value?.name)}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Table Name",
      key: "alias",
      render: (value, object, index) => {
        return (
          <p>
            {object?.tableName?.label || "N/A"}
          </p>
        );
      },
    },
    {
      title: "Connection Name",
      key: "connectionName",
      render: (value, object, index) => {
        return <p>{value?.name}</p>;
      },
    },
    {
      title: "Edit",
      key: "detail",
      render: (value, object, index) => {
        return (
          <div>
            <Button onClick={(e) => editInput(object, index, value)}>
              <p style={{ fontSize: "10px", padding: "4px" }}>
                <EditIcon />
              </p>
            </Button>
          </div>
        );
      },
    },
    {
      title: "Delete",
      key: "delete",
      render: (value, object, index) => {
        return (
          <div>
            <Button onClick={(e) => deleteInput(object)}>
              <RemoveIcon />
            </Button>
          </div>
        );
      },
    },
  ];

  const constructData = (tempData) => {
    setTargetTableData([]);
    tempData.forEach((obj, idx) => {
      setTargetTableData((prevState) => [
        ...prevState,
        {
          connection: { name: obj?.imgID || "" },
          connectionName: { name: obj?.value || "" },
          alias: obj?.formData?.alias || "",
          key: obj?.id || "",
          index: obj.formData.index,
          tableName: obj.formData.tableName || ''
        },
      ]);
    });
  };

  const handleOnClose = () => {
    setModalVisible(false);
  };

  return (
    <div className="InputListContainer">
      <h2>{props?.heading} Object List</h2>
      <LocalTable columns={columns} dataSource={targetTableData} />
      <FormModal
        origin="Target"
        visible={modalVisible}
        handleOnClose={handleOnClose}
        data={modalContent}
      />
    </div>
  );
}

export default function FormModal(props) {
  const { data, origin } = props;
  const { schema, schemaValue } = data;
  const pipelineData = useContext(ETLCanvasContext);
  const setPipelineData = useContext(ETLCanvasContextDispatcher);

  const handleOnSave = (
    formData,
    schemaRowsData,
    noSQLSchemaRows,
    selectedColumns
  ) => {
    const queryParameters = new URLSearchParams(window.location.search);
    if (origin === "Source") {
      let schemaData = JSON.stringify(schemaRowsData)

      const sourceSchemaList = _.cloneDeep(pipelineData?.SourceSchema || null);
      if (sourceSchemaList[schemaValue]) {
        let connectionObj = {};
        let selectedIndex = '';
        sourceSchemaList[schemaValue].forEach((obj,i) => {
          if (obj.formData.index === formData.index) {
            connectionObj = obj;
            selectedIndex = i
          }
        });
        const connectionObjIndex = selectedIndex
        let parseSchema = JSON.parse(schemaData) || [];
        if (parseSchema.length > 0) {
          formData.schema_mapping = schemaData;
        }
        let srcQuery = btoa(formData?.query);
        formData.query = {
          projectID: queryParameters.get("projectID"),
          pipeLine: {
            pipeID: queryParameters.get("projectID"),
            queryData: { value: srcQuery },
          },
        };
        formData.dbType = data.dbType;
        connectionObj.formData = formData;
        const columnsData = selectedColumns
          ?.map((item) => item.label)
          .join(",");
        connectionObj.formData.columns = columnsData;
        connectionObj.formData.columnsData = selectedColumns;
        sourceSchemaList[schemaValue][connectionObjIndex] = connectionObj;
        setPipelineData({ ...pipelineData, SourceSchema: sourceSchemaList });
      }
    } else if (origin === "Target") {
      const targetSchemaList = _.cloneDeep(pipelineData?.TargetSchema || null);
      if (schemaValue && targetSchemaList[schemaValue]) {
        let connectionObj = {};
        targetSchemaList[schemaValue].forEach((obj) => {
          if (obj.formData.index === formData.index) {
            connectionObj = obj;
          }
        });
        const connectionObjIndex = formData.index;
        let tgtQuery = btoa(formData?.query);
        formData.query = {
          projectID: queryParameters.get("projectID"),
          pipeLine: {
            pipeID: queryParameters.get("projectID"),
            queryData: { value: tgtQuery },
          },
        };
        connectionObj.formData = formData;
        const columnsData = selectedColumns
          ?.map((item) => item.label)
          .join(",");
        connectionObj.formData.columns = columnsData;
        connectionObj.formData.columnsData = selectedColumns;
        targetSchemaList[schemaValue][connectionObjIndex] = connectionObj;
        setPipelineData({ ...pipelineData, TargetSchema: targetSchemaList });
      }
    }
    props.handleOnClose();
  };
  const handleS3OnSave = (formData, schemaMapping) => {
    if (origin === "Source") {
      if (schemaValue && pipelineData?.SourceSchema) {
        const sourceSchemaList = _.cloneDeep(
          pipelineData?.SourceSchema || null
        );
        if (sourceSchemaList[schemaValue]) {
          let connectionObj = {};
        let selectedIndex = -1;
        sourceSchemaList[schemaValue].forEach((obj, i) => {
          if (obj.id === formData.id) {
            connectionObj = obj;
            selectedIndex = i;
          }
        });
        formData.dbType = data?.dbType || "RDBMS";
        formData.schema_mapping = schemaMapping && schemaMapping?.file_schema.length>0 ? JSON.stringify(schemaMapping) : '[]';
        connectionObj.formData = formData;
        sourceSchemaList[schemaValue][selectedIndex] = connectionObj;
          setPipelineData({
            ...pipelineData,
            SourceSchema: sourceSchemaList,
          });
        } else {
          setPipelineData({ ...pipelineData });
        }
      }
    }
    props.handleOnClose();
  };
  const handleMongoOnSave = (formData, schemaMapping) => {
    if (origin === "Source") {
      if (schemaValue && pipelineData?.SourceSchema) {
        const sourceSchemaList = _.cloneDeep(
          pipelineData?.SourceSchema || null
        );
        if (sourceSchemaList[schemaValue]) {
          let connectionObj = {};
        let selectedIndex = -1;
        sourceSchemaList[schemaValue].forEach((obj, i) => {
          if (obj.id === formData.id) {
            connectionObj = obj;
            selectedIndex = i;
          }
        });
        formData.dbType = data?.dbType || "No Sql";
        formData.schema_mapping = schemaMapping && schemaMapping?.file_schema?.length>0 ? JSON.stringify(schemaMapping) : '[]';
        connectionObj.formData = formData;
        if( selectedIndex >-1){
          sourceSchemaList[schemaValue][selectedIndex] = connectionObj;
        }else {
          sourceSchemaList[schemaValue].push( connectionObj);
        }
          setPipelineData({
            ...pipelineData,
            SourceSchema: sourceSchemaList,
          });
        } else {
          setPipelineData({ ...pipelineData });
        }
      }
    }
    props.handleOnClose();
  };
  if (schema === "snowflake") {
    return (
      <SnowFlakeModalEdit
        visible={props.visible}
        handleOnClose={props.handleOnClose}
        schemaTitle={schema}
        data={data}
        origin={origin}
        handleOnSave={handleOnSave}
      />
    );
  }

  if (schema === "postgres" || schema === "mysql"  || schema === "dremio") {
    return (
      <PostGresModalEdit
        visible={props.visible}
        handleOnClose={props.handleOnClose}
        schema={schema}
        data={data}
        origin={origin}
        handleOnSave={handleOnSave}
      />
    );
  }
  if (schema === "s3") {
    return (
      <AamzonS3EditModal
        visible={props.visible}
        handleOnClose={props.handleOnClose}
        schema={schema}
        data={data}
        origin={origin}
        handleS3OnSave={handleS3OnSave}
      />
    );
  }
  if (schema === "mongo") {
    return (
      <MongoDBModal
        visible={props.visible}
        handleOnClose={props.handleOnClose}
        schema={schema}
        origin={origin}
        data={data}
        handleMongoOnSave={handleMongoOnSave}
      />
    );
  }
}



