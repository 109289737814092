import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
import axios from "axios";
import logger from "../../config/logger";
import { SERVER_URL } from "../../config/config";
const ColumnPlot = ({ periodValue, selectedCardValue, environment }) => {
    const [dbHealthData, setDBHealthData] = useState([]);
    const [healthDataLoading, setHealthDataLoading] = useState(true);
    useEffect(() => {
        getdbHealthData();
    }, [periodValue, selectedCardValue]);

    const getdbHealthData = async () => {
        try{
        logger.info(`PeriodValue : ${periodValue}`)
        var v_period_value = 1;
        var v_platform = "db";
        if (periodValue === "Last 24 hours") {
            
            v_period_value = 1;
        } else if (periodValue === "This Week") {
        
            v_period_value = 7;
        } else if (periodValue === "This Month") {
            
            v_period_value = 30;
        } else if (periodValue === "This Quater") {
         
            v_period_value = 90;
        }

        if (selectedCardValue[2] === "DATABASE (POSTGRES)") {
           
            v_platform = "db";
        } else if (selectedCardValue[2] === "ETL (INFORMATICA)") {
          
            v_platform = "etl";
        } else if (selectedCardValue[2] === "DAAS (DREMIO)") {
         
            v_platform = "dremio";
        } else if (selectedCardValue[2] === "TABLEAU") {
         
            v_platform = "tableau";
        }

        var v_hr_day=''
        if(v_period_value === 1)
        {
            v_hr_day=' hr(s) ago'
        }
        else
        {
            v_hr_day=' day(s) ago'
        }
    
        let params = {
            no_of_days: v_period_value,
            platform: v_platform
        }
        let endPoint =''
     
        logger.info(`Environment:${environment}`)
        if(environment.toLowerCase() === 'production'){
            
            endPoint = `${SERVER_URL}/dbhealthchart`
        }
        if(environment === 'Stage'){
            endPoint = `${SERVER_URL}/dbhealthchartstg`
            params = {...params,stage:true}
        }
        logger.info(`Getting health chart`)
        const { data } = await axios.post(endPoint, {params});
        logger.info(`Health chart fetched successfully`)
        let formattedData = [];
        data.forEach((healthValue) => {
            formattedData.push({
                name: "Availability",
                dateTime: healthValue.Date_Time + v_hr_day,
                value: parseInt(healthValue.Availability),
            });
            formattedData.push({
                name: "Up_Time",
                dateTime: healthValue.Date_Time + v_hr_day,
                value: parseInt(healthValue.Up_Time),
            });
        });
       
        setDBHealthData(formattedData);
        setHealthDataLoading(false);
        }catch(e){
            logger.error(e)
        }
    };

    const config = {
        data: dbHealthData,
        isGroup: true,
        xField: "dateTime",
        yField: "value",
        seriesField: "name",
        maxColumnWidth:20,
        // intervalPadding: 10,
        dodgePadding: 2,
    };
 

    return <Column {...config} loading={healthDataLoading} style={{width:"100%",height:"100%"}}/>;




};

export default ColumnPlot;