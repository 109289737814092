import React from "react";
import "./FilterDropdown.css";
import { Select, Tooltip } from "antd";
import Logger from "../../../config/logger";
const FilterDropdown = ({ ...dropdown }) => {
  const { items, label, mode, onChangeItem, selectedItem, handleClick, loading } = dropdown;
  const selectProps = {
    mode: mode,
    style: {
      width: "100%",
    },
    showArrow: true,
    value: selectedItem,
    options: items,
    onChange: (newValue) => {
      Logger.info(`${label} got changed to ${newValue}`, "jira_ssot_client", window.location.href);
      onChangeItem(newValue);
    },
    maxTagCount: "responsive",
    onClick: handleClick,
    loading: loading,
  };

  return (
    <div className="filter-container" data-testid="filter-dropdown">
      {label && <p data-testid="filter-label">{label}</p>}
      <Select 
        {...selectProps} 
        maxTagPlaceholder={(omittedValues) => (
          <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
            <span>+{omittedValues.length}</span>
          </Tooltip>
        )} 
        className="filterSelect" data-testid="filter-select" 
      />
    </div>
  );
};

export default FilterDropdown;