import React, { useState, useEffect } from "react";
import {
  Button,
  ChevronLeftIcon,
  Pagination,
  Sorter,
  Table,
} from "@nutanix-ui/prism-reactjs";
import { useLocation, useHistory } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  createDataSourceAndHighlightOnSearch,
  fetchTableData,
} from "./utility";

// Helper function to validate and sanitize query params
const validateParam = (value, defaultValue, minValue = 1) => {
  const parsedValue = parseInt(value, 10);
  if (!Number.isNaN(parsedValue) && parsedValue >= minValue) {
    return parsedValue;
  }
  return defaultValue;
};

const DataTable = ({
  title,
  columns,
  fetchUrl,
  pageSizeOptions = [5, 10, 15, 20],
  defaultPageSize = 15,
}) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [sort, setSort] = useState({
    order: Sorter.SORT_ORDER_CONST.DESCEND,
    column: "ship_date",
    sortable: [
      "ship_date",
      "sfdc_count",
      "factory_eod_file",
      "factory_eod_count",
      "pg_db_count",
      "mongo_db_count",
      "match_result",
      "missing_assets",
    ],
  });
  // Apply validation on query params
  const [currentPage, setCurrentPage] = useState(
    validateParam(queryParams.get("page"), 1)
  );
  const [pageSize, setPageSize] = useState(
    validateParam(queryParams.get("limit"), defaultPageSize)
  );
  const [searchValue, setSearchValue] = useState(
    queryParams.get("search") || ""
  );
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(100);
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: [
      "fetchData",
      {
        page: currentPage,
        search: searchValue,
        column: sort.column,
        order: sort.order === 'ascend' ? 'asc' : 'desc',
        pageSize,
        fetchUrl,
      },
    ],
    queryFn: fetchTableData,
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    retry: 1,
  });

  if (error) {
    throw error;
  }
  // useEffect(() => {
  //   const params = new URLSearchParams();
  //   params.set("page", currentPage);
  //   params.set("limit", pageSize);
  //   if (searchValue) {
  //     params.set("search", searchValue);
  //   }
  //   history.replace({ search: params.toString() });
  // }, [currentPage, pageSize, searchValue, history]);

  useEffect(() => {
    if (data && !isLoading) {
      const processedData = data?.data || []; // Fallback to an empty array if data is invalid
      setDataSource(
        createDataSourceAndHighlightOnSearch(processedData, searchValue)
      );
      setTotal(data?.total || 1); // Fallback to a default value if total is invalid
    }
  }, [data, isLoading, searchValue]);

  const handleChangeSearch = (ev) => {
    const value = ev.currentTarget.value;
    setSearchValue(value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const searchProps = {
    inputProps: {
      placeholder: "Type to search...",
      onChange: handleChangeSearch,
      value: searchValue,
    },
  };

  const onChangePagination = (newPage, newPageSize) => {
    setCurrentPage(validateParam(newPage, currentPage));
    setPageSize(validateParam(newPageSize, defaultPageSize));
  };

  const handleRefreshData = () => {
    refetch();
  };

  const handleChangeSort = (newSort) => {
    setSort({
      ...sort,
      column: newSort.column,
      order: newSort.order,
    });
  };

  // Trigger refetch after sort changes
  useEffect(() => {
    refetch(); // This ensures refetch happens after the state is updated
  }, [sort]); // This effect runs when `sort` changes

  return (
    <div>
      <div className="widgetHeader">
        <Button
          type={Button.ButtonTypes.NAVIGATION_PRIMARY}
          aria-label="Go back to previous page"
          onClick={() => history.push("/datasync")}
        >
          <ChevronLeftIcon aria-hidden="true" /> Data Sync
        </Button>
        <h2>{title}</h2>
        <Pagination
          currentPage={currentPage}
          onChange={onChangePagination}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          pageSizeOptionLabel="items"
          total={total}
        />
      </div>
      <Table
        showCustomScrollbar={true}
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        loadingError={!!error}
        onRefreshData={handleRefreshData}
        sort={sort}
        onChangeSort={handleChangeSort}
        wrapperProps={{
          "data-test-id": "factoryData",
        }}
      />
    </div>
  );
};

export default DataTable;