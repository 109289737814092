import React from "react";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const value = payload[0].value;
    let name;
    if (payload[0].payload.sprint) {
      name = payload[0].payload.sprint;
    } else if (payload[0].payload.name) {
      name = payload[0].payload.name;
    }
    return (
      <span>
        <small>{name}</small>
         <strong> {`${ value}`} </strong>
      </span>
    );
  }
  return null;
};

export default CustomTooltip;
