import React, { useEffect } from "react";
import JiraTooltipView from "./JiraTooltipView";
import { Tooltip } from "antd";
import { statusClassMap } from "../Helper/Helper";

const JiraView = ({ item, date }) => {

  useEffect(() => {
    document.documentElement.scrollTop = document.documentElement.clientHeight;
    document.documentElement.scrollLeft = document.documentElement.clientWidth;
  }, []);
  const statusClass = item ? statusClassMap[item.eta_status] : "no-eta";
  return (
    <Tooltip
      placement="right"
      trigger="click"
      title={<JiraTooltipView item={item} date={date} />}
      color={"white"}
      overlayClassName="tooltip-container"
      // autoAdjustOverflow={true}
      // arrow={{ pointAtCenter: true }}
    >
      <span className={`monthly-jira-container ${statusClass}`}></span>
    </Tooltip>
  );
};

export default JiraView;
