import {createContext} from "react";


export const ETLCanvasContext = createContext({
    pipelineName: '',
    selectedSchedule: 'manually',
    intervalType: 'mins',
    intervalValue: 1,
    projectID: {
        key: 0,
        label: 'Select Project',
        value: 'select'
    },
    sourceConnect: {
        key: 0,
        label: 'Select Connection',
        value: 'select'
    },
    sourceSchmea: {
        key: 0,
        label: 'Select Schema',
        value: 'select'
    },
    sourceTable: {
        key: 0,
        label: 'Select Table',
        value: 'select'
    },
    targetConnect: {
        key: 0,
        label: 'Select Connection',
        value: 'select'
    },
    targetSchmea: {
        key: 0,
        label: 'Select Schema',
        value: 'select'
    },
    targetTable: {
        key: 0,
        label: 'Select Table',
        value: 'select'
    },
    sourceSchema: {
        key: 0,
        label: 'Select Table',
        value: 'select'
    },
    targetSchema: {
        key: 0,
        label: 'Select Table',
        value: 'select'
    },
    project_id:''
});

export const ETLCanvasContextDispatcher = createContext();